/* eslint-disable */
import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import { Modal, ModalHeader, ModalBody, Button, CardBody, Card, ListGroup, ListGroupItem, CardTitle, Row, Col, Form, FormGroup, InputGroup, Input, Label, CardHeader, Table, Dropdown,DropdownMenu, DropdownItem, DropdownToggle, Nav, NavLink } from 'reactstrap';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {getCurrentUser} from '../../../factories/auth';
import ConfirmDeleteDialog from '../../Asset/components/ConfirmDeleteDialog';
import PasswordStrength from'react-password-secure-indicator'
import { addNvrUser, fetchSingleNvrUsers, deleteNvrUser, editNvrUser, addUserMultipleNvrs } from '../../../redux/actions/nvrMgmtAction';

const NVRUserModal = ({nvr, isEdit, user, userModalOpen, toggleUserModal, permissionGroups, addNvrUser, fetchSingleNvrUsers, deleteNvrUser, editNvrUser, addUserMultipleNvrs}) =>{
    const [userState, setUserState] = useState({
        userName: "",
        password: "",
        email: "",
        authGroupId: null,
        closePermissionControl: false,
        allowModifyPassword: false,
        // enabled: true
    })
    const [viewOnlyMode, setViewOnlyMode] = useState(false)
    const [modifyPassword, setModifyPassword] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [selectedPermission, setSelectedPermission] = useState(null)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [addAllNvrs, setAddAllNvrs] = useState(false)

    useEffect(() => {
        if(isEdit && user && permissionGroups){
            // console.log("editing user", user)
            let selectedPermission = permissionGroups && permissionGroups.find(permission => permission.name === user.authGroupName)
            setSelectedPermission(selectedPermission)
            setUserState(user)
        }
        if(!isEdit){
            let newUserState = {
                userName: "",
                password: "",
                email: "",
                closePermissionControl: false,
                allowModifyPassword: false,
                // enabled: true
                authGroupId: permissionGroups[0].id
            }
            setUserState(newUserState)
            setSelectedPermission(permissionGroups[0])
        }
    }, [user, isEdit, permissionGroups])

    useEffect(() => {
        console.log("modifyPassword", modifyPassword)
    }, [modifyPassword])

    const handleSelectPermission = (e) => {
        let selectedPermission = JSON.parse(e.target.value)
        // console.log("selected permission", selectedPermission)
        setSelectedPermission(selectedPermission)
        let newUserState = {...userState, authGroupId: selectedPermission.id}
        setUserState(newUserState)
    }

    const handleDeleteUser = async() => {
        // console.log("delete user", user)
        //delete user
        await deleteNvrUser(nvr?.id, user?.id)
        fetchSingleNvrUsers(nvr?.id)
        setConfirmDialogOpen(false)
        toggleUserModal()
    }

    const onSubmit = async() => {
        // let userToSubmit = {
        //     username: userState.username,
        //     password: 
        //     email: userState.email,

        // }
        //add user
        if(!isEdit){
            if (newPassword === confirmPassword) {
            let userToSubmit = {
                ...userState,
                password: confirmPassword,
                bindMacSwitch: false,
                mac: "00:00:00:00:00:00",
                enabled: true,
            }
            // console.log("add user", userToSubmit)
            toggleUserModal()
            if(addAllNvrs){
                await addUserMultipleNvrs("all", userToSubmit, selectedPermission?.name)
            }else{
                await addNvrUser(nvr?.id, userToSubmit)
            }    
            fetchSingleNvrUsers(nvr?.id)
            }
        }else{
            //edit user
            let userToSubmit = {
                userName: userState.userName,
                email: userState.email,
                allowModifyPassword: userState.allowModifyPassword,
                authGroupId: userState.authGroupId,
                // password: newPassword,
                bindMacSwitch: false,
                mac: "00:00:00:00:00:00",
                enabled: userState.enabled,
                closePermissionControl: userState.closePermissionControl
            }
            await editNvrUser(nvr?.id, user?.id, userToSubmit)
            fetchSingleNvrUsers(nvr?.id)
            toggleUserModal()
        }



    }
    return (
        <Modal isOpen={userModalOpen} toggle={toggleUserModal}>
            <ModalHeader toggle={toggleUserModal}>{isEdit ? "Edit User" : "Add User"}</ModalHeader>
            <ModalBody>
                <Form>
                    { isEdit && <FormGroup row>
                        <Label for="enabled" sm="2">Enabled</Label>
                        <Col sm="10">
                            <Input type="checkbox" name="enabled" id="enabled" checked={userState?.enabled} onChange={(e) => setUserState({...userState, enabled: e.target.checked})}/>
                        </Col>
                    </FormGroup>}
                    <FormGroup row>
                        <Label for="name" sm="2">Username</Label>
                        <Col sm="10">
                            <Input type="text" name="name" id="name" disabled={isEdit} value={userState ? userState.userName : ""} onChange={(e) => setUserState({...userState, userName: e.target.value})}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                            <Label for="email" sm="2">Email</Label>
                            <Col sm="10">
                                <Input type="email" name="email" id="email" value={userState ? userState.email : ""} onChange={(e) => setUserState({...userState, email: e.target.value})}/>
                            </Col>
                    </FormGroup>
                   {isEdit && <FormGroup row>
                        <Label for="closePermissionControl" sm="4">Close Permission Control</Label>
                        <Col sm="8">
                            <Input type="checkbox" name="closePermissionControl" id="closePermissionControl" checked={userState?.closePermissionControl} onChange={(e) => setUserState({...userState, closePermissionControl: e.target.checked})}/>
                        </Col>
                    </FormGroup>}
                   {/* {isEdit && <FormGroup row>
                        <Label for="modifyPassword" sm="4">Modify Password</Label>
                        <Col sm="8">
                            <Input type="checkbox" name="modifyPassword" id="modifyPassword" checked={modifyPassword} onChange={(e) => setModifyPassword(e.target.checked)}/>
                        </Col>
                    </FormGroup>} */}
                   { (modifyPassword || !isEdit) &&
                        <>
                            <FormGroup row>
                                <Label for="password" sm="2">Password</Label>
                                <Col sm="10">
                                    <Input type="password" name="password" id="password" value={newPassword} onChange={(e) => {setNewPassword(e.target.value)}}/>
                                </Col>
                                {/* <PasswordStrength 
                                    password={newPassword}
                                    errorMsg="Password is too weak"
                                    isCustomValidations={
                                        {
                                            toShort: {
                                                regex: /^.{0,7}$/,
                                                errorMessage: "Password is too short"
                                                },
                                                toShort: {
                                                    regex: /^.{0,7}$/,
                                                    errorMessage: "Password is too short"
                                                    },
                                                    }
                                                    } 
                                                    /> */}
                            </FormGroup>

                            <FormGroup row>
                                <Label for="confirmPassword" sm="2">Confirm Password</Label>
                                <Col sm="10">
                                    <Input type="password" name="confirmPassword" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} invalid={confirmPassword!==newPassword}/>
                                </Col>
                            </FormGroup>
                            </>
                    }
                    {!isEdit && <FormGroup row>
                        <Label for="allowModifyPassword" sm="4">Allow Modify Password</Label>
                        <Col sm="8">
                            <Input type="checkbox" name="allowModifyPassword" id="allowModifyPassword" checked={userState?.allowModifyPassword} onChange={(e) => setUserState({...userState, allowModifyPassword: e.target.checked})}/>
                        </Col>
                    </FormGroup>}
                    <FormGroup row>
                            <Label for="permission" sm="2">Permission Group</Label>
                            <Col sm="10">
                                <Input type="select" name="permission" id="permission" onChange={handleSelectPermission}>
                                    {permissionGroups && permissionGroups.map((permission, index) => {
                                        return <option key={index} selected={permission.name === selectedPermission?.name} value={JSON.stringify(permission)}>{permission.name}</option>
                                    })}
                                </Input>
                            </Col>
                    </FormGroup>
                    {/* <Row>
                        <Col md="6">
                        <FormGroup>
                        <Label for="password">Password</Label>
                        <Input type="password" name="password" id="password" value={userState ? userState.password : ""} onChange={(e) => setUserState({...userState, password: e.target.value})}/>
                        </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label for="confirmPassword">Confirm Password</Label>
                                <Input type="password" name="confirmPassword" id="confirmPassword" value={userState ? userState.confirmPassword : ""} onChange={(e) => setUserState({...userState, confirmPassword: e.target.value})}/>
                            </FormGroup>
                        </Col>
                    </Row> */}
                    {!isEdit && <FormGroup row>
                        <Label for="addAllNvrs" sm="4">Applied to All NVRs</Label>
                        <Col sm="8">
                            <Input type="checkbox" name="addAllNvrs" id="addAllNvrs" checked={addAllNvrs} onChange={(e) => setAddAllNvrs(e.target.checked)}/>
                        </Col> 
                    </FormGroup>}
                    </Form>
                    <Row className="button-row">
                        <Col className="text-right" md="12">
                            <IconButton style={{marginBottom:"15px"}} disabled={viewOnlyMode} onClick={()=>setConfirmDialogOpen(true)}>
                                <DeleteIcon/>
                            </IconButton>

                            <ConfirmDeleteDialog 
                                modalVisible={confirmDialogOpen} 
                                toggleOff={()=>setConfirmDialogOpen(false)} 
                                onConfirm={handleDeleteUser} 
                                msg="Are you sure you want to delete this user?"/>
                            {!viewOnlyMode && <Button onClick={onSubmit}>{isEdit ? 'Save' : 'Add'}</Button>}
                            <Button color="info" onClick={toggleUserModal}>Close</Button>
                        </Col>
                    </Row>
            </ModalBody>


        </Modal>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNvrUser: (nvrId, user) => dispatch(addNvrUser(nvrId, user)),
        fetchSingleNvrUsers: (nvrId) => dispatch(fetchSingleNvrUsers(nvrId)),
        editNvrUser: (nvrId, userId, userInfo) => dispatch(editNvrUser(nvrId, userId, userInfo)),
        deleteNvrUser: (nvrId, userId) => dispatch(deleteNvrUser(nvrId, userId)),
        addUserMultipleNvrs: (nvrIds, user, permissionGroupName) => dispatch(addUserMultipleNvrs(nvrIds, user, permissionGroupName))
    }
}

export default connect (null,mapDispatchToProps)(NVRUserModal)