/* eslint-disable */
import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { filter, isEqual } from 'lodash';
import {getCurrentUser} from "../../../../factories/auth";
import useDeepCompareEffect from 'use-deep-compare-effect'


const userObj = getCurrentUser();
export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color:  "black",
        '&:active': {backgroundColor: userObj ? userObj.detail.appButtonColorHover : ''},
        backgroundColor:  state.isFocused ? userObj ? userObj.detail.appButtonColorHover: "" : 'white',
    }),
    control: (base,selectState) => ({
        ...base,
        height: 'auto',
        minHeight: 35,
        minWidth: 120,
        borderColor: selectState.isFocused ? userObj ? userObj.detail.appButtonColorHover: '' : base.borderColor,
        // This line disable the blue border
        boxShadow: selectState.isFocused ? 0 : 0,
        '&:hover': {borderColor: selectState.isFocused ? userObj ? userObj.detail.appButtonColorHover: '' : base.borderColor}
    }),
    indicatorsContainer:  (base,selectState)  => ({
        ...base,
        padding: "0",
    }),
}
//use memo to avoid unexpected re-render
const CameraAttributeFilter = React.memo(({filterArr, camList, setFilters, filters, camName, setCamName}) => {
    const [FilterState, setFilterState] = useState(null);
    const [CamNameFilter, setCamNameFilter] = useState(null);
    useDeepCompareEffect(()=>{
        if(filterArr && filterArr.length > 0 && camList && camList.length > 0){
            let filterState = []
            filterArr.forEach(filter => {
                let filterOpts = []
                if(camList && camList.length > 0) {
                    camList.forEach(cam=>{
                        let camAttributesByFilter = cam.assetattributes.find(attr => attr.attmetaname == filter.value)
                        if(camAttributesByFilter !== undefined){
                            let valToAdd = camAttributesByFilter.value
                            if(filterOpts.length < 1 || !filterOpts.find((opt)=>opt.value == valToAdd)){
                                filterOpts.push({value: valToAdd, label: valToAdd})
                            }
                        }
                    })
                }
                //saved value from parent component
                let savedFilter = null
                if(filters){
                    savedFilter = filters.find(sFilter=> sFilter.filterName == filter.value)
                }
                filterState.push({
                    filterName: filter.value,
                    filterOptions: filterOpts,
                    selectedVal : savedFilter ? savedFilter.selectedVal : null,
                })

            });
            // console.log('FILTER STATE')
            setFilterState(filterState)
        }
    }, [filterArr, camList])


    const onFilterChange = (selectedOptions, index)=>{
        let filterState = FilterState.slice()
        if(selectedOptions){
            filterState[index].selectedVal = selectedOptions
        }else{
            filterState[index].selectedVal = null
        }
        setFilters(filterState)
        setFilterState(filterState)
    }
    return (
        <Row>
            <Col md={10}>
                <Row>
                    {FilterState && FilterState.length > 0 && FilterState.map((f, index)=>(
                        <Col md={Math.floor(10 / FilterState.length)}>
                            <FormGroup row >
                                <Col md={12}>
                                    <Select
                                        isClearable
                                        isSearchable
                                        options = {f.filterOptions}
                                        value={f.selectedVal}
                                        onChange = {(selectOptions)=> onFilterChange(selectOptions, index)}
                                        styles={customStyles}
                                        // isMulti
                                        />
                                    <Label for="subgroups" style={{display: 'inline', width: "100%"}}><p style={{fontSize:'12px'}}>{`Filter By ${f.filterName}`}</p></Label>
                                </Col>
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col md={2}>
                <FormGroup row>
                    <Col md={12}>
                        <Input type="text" id="camName" onChange={setCamName} value={camName} placeholder="Search By Name"/>    
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    )
}, (prevProps, nextProps)=>{
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
})


export default CameraAttributeFilter