/* eslint-disable */
import React, { Component, useEffect } from "react";
import { Col, Container, Row, Card, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  loadDevice,
  loadDeviceInfo,
} from "../../../../redux/actions/mapActions";
import {
  loadCameraList,
  loadSensorList,
} from "../../../../redux/actions/assetActions";
import { loadNVRs } from "../../../../redux/actions/surveilenceActions";
import { getCurrentUser, hasRole } from "../../../../factories/auth";
import RenderDeviceList from "./RenderDeviceList";

class DevicesManageMain extends Component {
  constructor(props) {
    super(props);
    let index = localStorage.getItem("activeTab");
    this.state = {
      //activeTab: '2',
      activeTab: index ? index : "2",
    };
    this.userObj = getCurrentUser();
    this.handleBackClick = this.handleBackClick.bind(this);

  }

  componentDidMount() {
    console.log('DevicesManageMain componentDidMount');
    this.props.loadNVRs();
    this.props.loadCameraList();
    this.props.loadSensorList();
    this.toggleTab("1");
  }

  toggleTab = (tab) => {
    localStorage.setItem("activeTab", tab);
    if (this.state.activeTab !== tab) {
      this.setState(
          {
            activeTab: tab,
            // mapShow: tab == "2",
          }
      );
    }
  };

  handleBackClick = ()=>{
    // do not use ‘this.props.history.goBack();’， because if use
    // withRouter to  wrap current component, the search function
    // of the table is not usable when input in search area.
    window.history.go(-1);
  }
  render() {
    let assetList = this.props.assetList;
    return (

      <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Devices</h3>
        </Col>
      </Row>
      <Row>
      <Col md={12}>
          {/* <RenderTestingButtons props={this.props} state={this.state} /> */}
          <NavTabs activeTab={this.state.activeTab} toggleTab={this.toggleTab} handleBackClick={this.handleBackClick}/>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Card>
                <div className='card-pg'>
                  <RenderDeviceList deviceList={this.props.cameraList} type="camera"/>
                </div>
            </Card>
            </TabPane>


            <TabPane tabId="2">
              <Card>
                <div className='card-pg'>
                  <RenderDeviceList deviceList={this.props.sensorList} type="sensor"/>
                </div>
              </Card>
            </TabPane>

            <TabPane tabId="3">
              <Card>
                <div className='card-pg'>
                  <RenderDeviceList deviceList={this.props.nvrList} type="nvr"/>
                </div>
              </Card>
            </TabPane>

          </TabContent>
        </Col>
      </Row>
    </Container>

    );
  }
}

function NavTabs({ activeTab, toggleTab, handleBackClick }) {
  return (
      <Nav
          tabs
          style={{
            border: "none",
          }}
      >
        {handleBackClick && <Button variant="primary" color="secondary" onClick={handleBackClick} > {'< Back'} </Button> }
        <NavItem style={{ cursor: "pointer" }}>
          <NavLink /* style={{
               border: 'none'
            }} */
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTab("1");
              }}
          >
            <h4>
              <b>Cameras</b>
            </h4>
          </NavLink>
        </NavItem>
        <NavItem style={{ cursor: "pointer" }}>
         <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTab("2");
              }}
          >
            <h4>
              <b>Sensors</b>
            </h4>
          </NavLink>
        </NavItem>
        <NavItem style={{ cursor: "pointer" }}>
         <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggleTab("3");
              }}
          >
            <h4>
              <b>NVRs</b>
            </h4>
          </NavLink>
        </NavItem>
      </Nav>
  );
}

// export default AssetBoard;

function mapStateToProps(state) {

  return ({
     cameraList: state.asset.cameraList2,
     sensorList: state.asset.sensorList2,
     nvrList: state.surveillance.nvrList,
  });
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
      {
        loadDevice,
        loadDeviceInfo,
        loadNVRs,
        loadCameraList,
        loadSensorList,
      },
      dispatch
  );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DevicesManageMain);
