/* eslint-disable */
import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { filter, isEqual } from 'lodash';
import {getCurrentUser} from "../../../../factories/auth";
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Filter } from '@material-ui/icons';


const userObj = getCurrentUser();
export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color:  "black",
        '&:active': {backgroundColor: userObj ? userObj.detail.appButtonColorHover : ''},
        backgroundColor:  state.isFocused ? userObj ? userObj.detail.appButtonColorHover: "" : 'white',
    }),
    control: (base,selectState) => ({
        ...base,
        height: 'auto',
        minHeight: 35,
        minWidth: 120,
        borderColor: selectState.isFocused ? userObj ? userObj.detail.appButtonColorHover: '' : base.borderColor,
        // This line disable the blue border
        boxShadow: selectState.isFocused ? 0 : 0,
        '&:hover': {borderColor: selectState.isFocused ? userObj ? userObj.detail.appButtonColorHover: '' : base.borderColor}
    }),
    indicatorsContainer:  (base,selectState)  => ({
        ...base,
        padding: "0",
    }),
}
//use memo to avoid unexpected re-render
const CameraGroupFilter =  ({cameraGroups, handleCameraGroupSelect, camName, setCamName}) => {
    const [FilterOptions, setFilterOptions] = useState([])
    const [selectedValue, setSelectedValue] = useState(null)


    useEffect(()=>{
        // console.log('cameraGroups', cameraGroups)
        let options = []
        if(cameraGroups && cameraGroups.length > 0){
            cameraGroups.map((group)=>{
                options.push({value: group, label: group.name})
            })
        }
        // console.log('options', options)
        setSelectedValue(null)
        handleCameraGroupSelect(null)
        setFilterOptions(options)
    }, [cameraGroups])

    const onGroupChange = (selectedOptions)=>{
        handleCameraGroupSelect(selectedOptions)
        setSelectedValue(selectedOptions)
    }
    return (
        <Row>
            <Col md={8}>
                <FormGroup row>
                        {/* <Label sm={2} style={{display: 'inline', width: "100%"}}><p style={{fontSize:'14px'}}>{`Show Camera Group`}</p></Label> */}
                        <Col sm={4}>
                            {(cameraGroups && cameraGroups.length > 0) ? <Select
                                isClearable
                                isSearchable
                                options = {FilterOptions}
                                value={selectedValue}
                                onChange = {(selectOptions)=> onGroupChange(selectOptions)}
                                styles={customStyles}
                                placeholder="Select Group..."
                                // isMulti
                            /> : <p>Loading Camera Group...</p>}
                        </Col>
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup row>
                    <Col md={12}>
                        <Input type="text" id="camName" onChange={setCamName} value={camName} placeholder="Search By Name"/>    
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    )
}


export default CameraGroupFilter