/* eslint-disable */
import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, Button, CardBody, Card, ListGroup, ListGroupItem, CardTitle, Row, Col, Form, FormGroup, InputGroup, Input, Label, CardHeader, Table, Dropdown,DropdownMenu, DropdownItem, DropdownToggle, Nav, NavLink } from 'reactstrap';
import ExpandMore from '@material-ui/icons/ExpandMore';

const PermissionGroupFunctionAccessConfig = ({configData, handleAccessConfigChange, viewOnlyMode, isEdit}) => {

    const handleSelectAll = (e) => {
        let newConfigData = {...configData}
        //loop through all keys in configData and set them to e.target.checked
        for (let key in newConfigData) {
            newConfigData[key] = true
        }
        handleAccessConfigChange(newConfigData)
    }

    const handleDeselectAll = (e) => {
        let newConfigData = {...configData}
        //loop through all keys in configData and set them to e.target.checked
        for (let key in newConfigData) {
            newConfigData[key] = false
        }
        handleAccessConfigChange(newConfigData)
    }
    return (
        <Form>
            <Row>
                <Col md="6">
                    <Row>
                        <Col md="6">
                            <Button className="select-all" onClick={handleSelectAll}>Select All</Button>
                        </Col>
                        <Col md="6">
                            <Button className="select-all" onClick={handleDeselectAll}>Deselect All</Button>
                        </Col>
                    </Row>
                </Col>

            </Row>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.localCamera} 
                            onChange={(e) => handleAccessConfigChange({...configData, localCamera: e.target.checked})}
                        />
                        <Label>Local Camera</Label>
                            
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.remoteCamera} 
                            onChange={(e) => handleAccessConfigChange({...configData, remoteCamera: e.target.checked})}
                        />
                        <Label>Remote Camera</Label>

                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.disk} 
                            onChange={(e) => handleAccessConfigChange({...configData, disk: e.target.checked})}
                        />
                        <Label>Disk</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.aiEvent} 
                            onChange={(e) => handleAccessConfigChange({...configData, aiEvent: e.target.checked})}
                        />
                        <Label>AI Event</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.network} 
                            onChange={(e) => handleAccessConfigChange({...configData, network: e.target.checked})}
                        />
                        <Label>Network</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.schedule} 
                            onChange={(e) => handleAccessConfigChange({...configData, schedule: e.target.checked})}
                        />
                        <Label>Schedule</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.record} 
                            onChange={(e) => handleAccessConfigChange({...configData, record: e.target.checked})}
                        />
                        <Label>Record</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.remoteLogin} 
                            onChange={(e) => handleAccessConfigChange({...configData, remoteLogin: e.target.checked})}
                        />
                        <Label>Remote Login</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.audio} 
                            onChange={(e) => handleAccessConfigChange({...configData, audio: e.target.checked})}
                        />
                        <Label>Audio</Label>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.localSystem} 
                            onChange={(e) => handleAccessConfigChange({...configData, localSystem: e.target.checked})}
                        />
                        <Label>Local System</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.sampleDatabase} 
                            onChange={(e) => handleAccessConfigChange({...configData, sampleDatabase: e.target.checked})}
                        />
                        <Label>Sample Database</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.remoteSystem} 
                            onChange={(e) => handleAccessConfigChange({...configData, remoteSystem: e.target.checked})}
                        />
                        <Label>Remote System</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.accountAndAuthority} 
                            onChange={(e) => handleAccessConfigChange({...configData, accountAndAuthority: e.target.checked})}
                        />
                        <Label>Account and Authority</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.parkingLotManagement} 
                            onChange={(e) => handleAccessConfigChange({...configData, parkingLotManagement: e.target.checked})}
                        />
                        <Label>Parking Lot Management</Label>
                    </FormGroup>
                    <FormGroup>
                        <Input 
                            type="checkbox" 
                            disabled={viewOnlyMode} 
                            checked={configData.accessControlManagement}                                             
                            onChange={(e) => handleAccessConfigChange({...configData, accessControlManagement: e.target.checked})}
                        />
                        <Label>Access Control Management</Label>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

export default PermissionGroupFunctionAccessConfig