/* eslint-disable */
import { 
    FETCH_NVR_PERMISSION_GROUPS_REQUEST, 
    FETCH_NVR_PERMISSION_GROUPS_SUCCESS,
    FETCH_NVR_PERMISSION_GROUPS_FAILURE,
    FETCH_NVR_USERS_REQUEST,
    FETCH_NVR_USERS_SUCCESS,
    FETCH_NVR_USERS_FAILURE,
    FETCH_NVRS_DEVICES_REQUEST,
    FETCH_NVRS_DEVICES_FAILURE,
    FETCH_NVRS_DEVICES_SUCCESS,
    ADD_NVRS_PERMISSION_GROUPS_FAILURE,
    ADD_NVRS_PERMISSION_GROUPS_REQUEST,
    ADD_NVRS_PERMISSION_GROUPS_SUCCESS,
    EDIT_NVR_PERMISSION_GROUP_REQUEST,
    EDIT_NVR_PERMISSION_GROUP_SUCCESS,
    EDIT_NVR_PERMISSION_GROUP_FAILURE,
    DELETE_NVR_PERMISSION_GROUP_REQUEST,
    DELETE_NVR_PERMISSION_GROUP_SUCCESS,
    DELETE_NVR_PERMISSION_GROUP_FAILURE,
    ADD_NVR_USER_REQUEST,
    ADD_NVR_USER_SUCCESS,
    ADD_NVR_USER_FAILURE,
    ADD_NVRS_USER_REQUEST,
    ADD_NVRS_USER_SUCCESS,
    ADD_NVRS_USER_FAILURE,
    EDIT_NVR_USER_REQUEST,
    EDIT_NVR_USER_SUCCESS,
    EDIT_NVR_USER_FAILURE,
    DELETE_NVR_USERS_REQUEST,
    DELETE_NVR_USERS_SUCCESS,
    DELETE_NVR_USERS_FAILURE, 

} from "../actions/nvrMgmtAction";

const initialState = {
    devices: [],
    nvrPermissions: [],
    users: [],
}

const nvrMgmtReducer = (state = initialState, action) => {
    switch(action.type) {

        case FETCH_NVRS_DEVICES_REQUEST:
            return {
                ...state,
            }

        case FETCH_NVRS_DEVICES_SUCCESS:
            // console.log("reducer - devices:", action.payload)
            return {
                ...state,
                devices: action.payload,
            }

        case FETCH_NVRS_DEVICES_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        case FETCH_NVR_PERMISSION_GROUPS_REQUEST:
            return {
                ...state,
            }

        case FETCH_NVR_PERMISSION_GROUPS_SUCCESS:
            let existingNvrPermissionGroups = state.nvrPermissions.findIndex(p=>p.nvr.id === action.payload.nvr.id)
            let tempNvrPermissions = [...state.nvrPermissions]
            if (existingNvrPermissionGroups > -1) {
                tempNvrPermissions[existingNvrPermissionGroups] = action.payload
                return {
                    ...state,
                    nvrPermissions: tempNvrPermissions
                }
            }else{
                return {
                    ...state,
                    nvrPermissions: [action.payload, ...tempNvrPermissions]
                }
            }

        case FETCH_NVR_PERMISSION_GROUPS_FAILURE:
            return {
                ...state,
                error: action.payload
            }


        case FETCH_NVR_USERS_REQUEST:
            // console.log("reducer - users request")
            return {
                ...state,
            }

        case FETCH_NVR_USERS_SUCCESS:
            console.log("reducer - users:", action.payload)
            let existingNvrUsers = state.users.findIndex(u=>u.nvr.id === action.payload.nvr.id)
            let tempNvrUsers = [...state.users]
            if (existingNvrUsers > -1) {
                tempNvrUsers[existingNvrUsers] = action.payload
                return {
                    ...state,
                    users: tempNvrUsers
                }
            }else{
                return {
                    ...state,
                    users: [action.payload, ...tempNvrUsers]
                }
            }

        case FETCH_NVR_USERS_FAILURE:
            // console.log("reducer - users failure")
            return {
                ...state,
                error: action.payload
            }

        case EDIT_NVR_PERMISSION_GROUP_REQUEST:
            return {
                ...state,
            }
        case EDIT_NVR_PERMISSION_GROUP_SUCCESS:
            return {
                ...state,
            }
        case EDIT_NVR_PERMISSION_GROUP_FAILURE:
            return {
                ...state,
            }
            
        case DELETE_NVR_PERMISSION_GROUP_REQUEST:
            return {
                ...state,
            }
            
        case DELETE_NVR_PERMISSION_GROUP_SUCCESS:
            return {
                ...state,
            }
            
        case DELETE_NVR_PERMISSION_GROUP_FAILURE:
            return {
                ...state,
            }

        case ADD_NVRS_PERMISSION_GROUPS_REQUEST:
            return {
                ...state,
            }
        case ADD_NVRS_PERMISSION_GROUPS_SUCCESS:
            return {
                ...state,
            }
        case ADD_NVRS_PERMISSION_GROUPS_FAILURE:
            return {
                ...state,
            }
        
        case ADD_NVR_USER_REQUEST:
        case ADD_NVR_USER_SUCCESS:
        case ADD_NVR_USER_FAILURE:
            return {
                ...state,                  
            }
        
        case ADD_NVRS_USER_REQUEST:
        case ADD_NVRS_USER_SUCCESS:
        case ADD_NVRS_USER_FAILURE:
            return {
                ...state,                  
            }
        
        case DELETE_NVR_USERS_REQUEST:
        case DELETE_NVR_USERS_SUCCESS:
        case DELETE_NVR_USERS_FAILURE:
            return {
                ...state,                  
            }
        case EDIT_NVR_USER_REQUEST:
        case EDIT_NVR_USER_SUCCESS:
        case EDIT_NVR_USER_FAILURE:
            return {
                ...state,                  
            }
        default:
            return state;
    }
}

export default nvrMgmtReducer;