/* eslint-disable */
export function getCurrentUser(){
    try{
        if (sessionStorage.getItem('currentUserObj')){
            let currentUser = JSON.parse(sessionStorage.getItem('currentUserObj'));
            // sort user roles.
            let sortRoles = [];
            let {role} = currentUser;
            if (role.includes('SUPERADMIN')) {
                sortRoles.push('SUPERADMIN');
            }else if (role.includes('ADMIN')) {
                sortRoles.push('ADMIN');
            }else if (role.includes('USER')) {
                sortRoles.push('USER');
            }else if (role.includes('CLIENTADMIN')) {
                sortRoles.push('CLIENTADMIN');
            }else if (role.includes('CLIENTUSER')) {
                sortRoles.push('CLIENTUSER');
            }else if (role.includes('EXEC')) {
                sortRoles.push('EXEC');
            }else if (role.includes('LP')) {
                sortRoles.push('LP');
            }else if (role.includes('RGM')) {
                sortRoles.push('RGM');
            }else if (role.includes('GM')) {
                sortRoles.push('GM');
            }else if (role.includes('AGM')) {
                sortRoles.push('AGM');
            }
            currentUser = {...currentUser, role: sortRoles};
            return currentUser;
        }else{
            throw new Error("Failed to get current user, Please re-login");
        }
    }
    catch(err) {
        console.error(err.message);
        return null;
    }
}

// export const isAllowed = (user, rights) => rights.some(right => user.rights.includes(right));

export const hasRole = (userObj, roles) => userObj && userObj.role && roles.some(role => userObj.role.includes(role));