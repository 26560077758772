/* eslint-disable */

import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Col, Row, Card, CardBody, Label, Button, Input, CardTitle, Container, Modal, ModalHeader, ModalBody, Form, FormGroup} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import { customStyles } from '../Surveillance/components/filters/CameraAttributeFilter';
import { loadCameraRestrictionsOnroute, updateCameraRestrictions } from '../../redux/actions/surveilenceActions';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { theme } from 'antd';
import { isEqual, set } from 'lodash';
import { loadAssets } from '../../redux/actions/assetActions';
import { CameraAssetFilters } from '../Surveillance/utils/cameraUtils';

const useStyles = makeStyles((theme)=>({
    formControl: {
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 650
    }
}))
export const AccessRestriction = ({loadCameraRestrictionsOnroute, cameraAccessRestrictions, cameraAssets, loadAssets, updateCameraRestrictions}) => {
    const classes = useStyles();
    const [CameraRestrictionsToUpdate, setCameraRestrictionsToUpdate] = useState(null);
    const [ReducedCameraRestrictions, setReducedCameraRestrictions] = useState(null);
    const [OpenUpdateModal, setOpenUpdateModal] = useState(false);
    const [Roles, setRoles] = useState([]);
    const [SelectedRole, setSelectedRole] = useState(null);
    const [CamAttributes, setCamAttributes] = useState([]);
    const [SelectedCamAttribute, setSelectedCamAttribute] = useState(null);
    const [SelectedCamValues, setSelectedCamValues] = useState([]);
    const [CamOptions, setCamOptions] = useState([]);
    const [SubmitDisabled, setSubmitDisabled] = useState(true);
    useEffect(()=>{
        loadCameraRestrictionsOnroute()
        loadAssets()
    }, [])
    useEffect(() => {
        // console.log('LOAD DATA', cameraAccessRestrictions)
        let cameraRestrictions = []
        let roles = []
        if(cameraAccessRestrictions && cameraAccessRestrictions.length > 0){
            cameraAccessRestrictions.forEach((role, roleIndx) => {
                roles.push({value: role.roleName, label: role.roleDesc, index: roleIndx})
                if(role.restrictions && role.restrictions.length > 0){
                    let restrictionsByRole = {}
                    let mergedRestrictedVals = []
                    role.restrictions.forEach(re=>{
                        let vals = re.values
                        vals.forEach (v=>{
                            mergedRestrictedVals.push({attribute: re.attributeName, value: v, roleIndex: roleIndx})
                        })
                    })
                    restrictionsByRole.roleDesc = role.roleDesc
                    restrictionsByRole.roleName = role.roleName
                    restrictionsByRole.restrictedCams = mergedRestrictedVals
                    restrictionsByRole.restrictedCamsString = mergedRestrictedVals.map(m=>m.value).join(", ")
                    cameraRestrictions.push(restrictionsByRole)
                }
            });
        }
        setCameraRestrictionsToUpdate([...cameraAccessRestrictions])
        setReducedCameraRestrictions(cameraRestrictions)
        setRoles(roles)
    }, [cameraAccessRestrictions]);

    useEffect(() => {
        //set cam attributes
        if(cameraAssets && cameraAssets.length > 0){
             let attributeList = cameraAssets.reduce((acc, cur)=>{
                if (cur.assetattributes && cur.assetattributes.length > 0){
                    cur.assetattributes.forEach(at=>{
                        if (!CameraAssetFilters.some(f=>f.value == at.attmetaname)){
                            return
                        };
                        let atIndex = acc.findIndex(el=>el.label == at.attmetaname)
                        if(acc.length == 0 || (acc.length > 0 && atIndex == -1)){
                            acc.push({label: at.attmetaname, value: at.attmetaname, camValues : [{label:at.value, value: at.value, attribute: at.attmetaname, roleIndex: Roles.index}]})
                        }else{
                            if(!acc[atIndex].camValues.some(val=>val.value == at.value)){
                                acc[atIndex].camValues.push({label: at.value, value: at.value, attribute: at.attmetaname, roleIndex: Roles.index })
                            }
                        }
                    })
                }
                return acc
             }, [])
             setCamAttributes(attributeList)
        }
    }, [cameraAssets]);


    const handleBackClick = () =>{
        window.history.go(-1);
    }

    const toggleUpdateModal = () =>{
        setOpenUpdateModal(!OpenUpdateModal)
    }

    const handleUserRoleChange = (selectOptions) =>{
        let roleName = selectOptions.value
        setSelectedRole(selectOptions)
        let camRestrictionByRole = ReducedCameraRestrictions.find(r=> r.roleName == roleName)
        if(camRestrictionByRole){
            let valueOptions = camRestrictionByRole.restrictedCams.map(c=>{
                return {value: c.value, label: c.value, attribute: c.attribute, roleIndex: Roles.index}
            })
            setSelectedCamValues(valueOptions)
        }else{
            setSelectedCamValues([])
        }
    }

    const onSelectCamsChange = (selectOptions)=>{
        //find user role to update
        let roleIndex = SelectedRole.index
        let newUpdate = CameraRestrictionsToUpdate.slice();
        let newRestrictionsArr
        if(selectOptions && selectOptions.length > 0 && SelectedRole){
            newRestrictionsArr = selectOptions.reduce((acc, cur)=>{
                if(acc.length > 0){
                    let updateIndex = acc.findIndex(a=>a.attributeName == cur.attribute)
                    if(updateIndex > -1){
                        acc[updateIndex].values.push(cur.value)
                    }else{
                        acc.push({attributeName: cur.attribute, values: [cur.value]})
                    }
                }else{
                    acc.push({attributeName: cur.attribute, values: [cur.value]})
                }
                return acc
            }, [])
            newUpdate[roleIndex].restrictions = newRestrictionsArr
            setSelectedCamValues(selectOptions);
        }else{
            newUpdate[roleIndex].restrictions = [];
            setSelectedCamValues([])
        }
        setCameraRestrictionsToUpdate(newUpdate)
    }

    const handleFilterChange = (selectOptions) => {
        setSelectedCamAttribute(selectOptions)
        setCamOptions(selectOptions.camValues)
    }

    const onUpdateSubmit = () =>{
        updateCameraRestrictions(CameraRestrictionsToUpdate)
        toggleUpdateModal()
    }


    return (
    <Container>
        <Row>
            <Col md={12}>
                <h3 className="page-title">Camera Restriction</h3>
            </Col>
        </Row>
        <Button variant="primary" color="secondary" onClick={handleBackClick} > {'< Back'} </Button>
        <Card>
            <CardBody>
                    <Row>
                        <Button onClick = {toggleUpdateModal}>
                            Edit
                        </Button>
                    </Row>
                    <Row>
                        <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="camera restriction">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='left'>Role</TableCell>
                                            <TableCell align='left'>Restricted Cams</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ReducedCameraRestrictions && ReducedCameraRestrictions.map((row)=> (
                                            <TableRow key={row.rowName}>
                                                <TableCell scope='row'>
                                                    {row.roleDesc}
                                                </TableCell>
                                                <TableCell align='left'>{row.restrictedCamsString}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                        </TableContainer>    
                    </Row>
                    <Modal
                        animationType="slide"
                        transparent="false"
                        isOpen={OpenUpdateModal}
                        toggle={toggleUpdateModal}
                        size = {'lg'}>
                        <ModalHeader toggle={toggleUpdateModal}>
                            <span style={{fontSize: "20px"}}>Update Restrictions</span>
                        </ModalHeader>
                        <ModalBody style={{margin: '0 20px'}}>
                                {Roles && Roles.length > 0 &&
                                <>
                                    <FormGroup row>
                                        <Col md={6}>
                                            <Label><h5>Select Roles</h5></Label>
                                            <Select
                                                id="user-role-select"
                                                options={Roles}
                                                value = {SelectedRole}
                                                onChange={handleUserRoleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={6}>
                                            <Label>Select Camera Filter Attribute</Label>
                                            <Select
                                                id="user-role-select"
                                                options={CamAttributes}
                                                value = {SelectedCamAttribute}
                                                onChange={handleFilterChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={12}>
                                            <Label>Select Camera Values</Label>
                                            <Select
                                                isDisabled={!SelectedRole}
                                                closeMenuOnSelect={false}
                                                value={SelectedCamValues}
                                                isMulti
                                                name="select-values"
                                                options={CamOptions}
                                                onChange={onSelectCamsChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col md={10}></Col>
                                        <Col md={2}>
                                            <Button onClick={onUpdateSubmit}>Submit</Button>
                                        </Col>
                                    </FormGroup>
                                </> 
                                }         
                        </ModalBody>
                    </Modal>      
            </CardBody>
        </Card>
    </Container>
    )
}

const mapStateToProps = (state) => ({
    cameraAccessRestrictions: state.surveillance.cameraRestrictions,
    cameraAssets: state.asset.assetList.filter(asset=> asset.assetclassname.includes('Camera_Base'))
})

const mapDispatchToProps = (dispatch) =>{
    return bindActionCreators({
        loadCameraRestrictionsOnroute,
        loadAssets,
        updateCameraRestrictions
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessRestriction)