/* eslint-disable */
import React, { Component, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, withRouter } from "react-router-dom";
import BootstrapTableNext from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar, ClearSearchButton } = Search;
import WebAssetIcon from '@material-ui/icons/WebAsset';
import Setting from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';

import Select from 'react-select';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import moment from 'moment';
import { Col, Row, Label, Input, Button, Table } from 'reactstrap';

import {
   sortCaret,
   // AdjustableDiv
} from '../../../../shared/components/Table/tableElements';
import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { LIGHT_GREY_COLOR } from '../../../../constants/dataConstants';
import { after, filter, set } from 'lodash';
import {getCurrentUser} from "../../../../factories/auth";
import AddDeviceModal from '../../../Device/components/AddDeviceModal';

//rowClasses
const smartBuildingDeviceRowClass = (row, rowIndex) => {
   return 'device-table-row';
}



const RenderDeviceList = ({ deviceList, devmaStatus, devmaNvr, type }) => {
   const [Columns, setColumns] = useState(null);
   const [Filter, setFilter] = useState({});
   const [TableData, setTableData] = useState(null);
   const [onlineFilter, setonlineFilter] = useState(false);
   const [offlineFilter, setofflineFilter] = useState(false);
   const [selectedLocation, setSelectedLocation] = useState(null);
   const [locationOpt, setlocationOpt] = useState([]);
   const [onlineDevicesCount, setonlineDevicesCount] = useState(null);
   const [offlineDevicesCount, setofflineDevicesCount] = useState(null);
   const [addModalVisible, setaddModalVisible] = useState(false);
   const userObj = getCurrentUser();
   const customStyles ={
         option: (provided, state) => ({
             ...provided,
             color:  "black",
             '&:active': {backgroundColor: userObj.detail?.appButtonColorHover},
             backgroundColor:  state.isFocused ? userObj.detail?.appButtonColorHover: "white",
         }),
         control: (base,selectState) => ({
             ...base,
             height: 35,
             minHeight: 35,
             borderColor: selectState.isFocused ? userObj.detail?.appButtonColorHover: base.borderColor,
             // This line disable the blue border
             boxShadow: selectState.isFocused ? 0 : 0,
             '&:hover': {borderColor: selectState.isFocused ? userObj.detail?.appButtonColorHover: base.borderColor}
         }),
         indicatorsContainer:  (base,selectState)  => ({
             ...base,
             padding: "0",
         }),
     }
   const statusFormatter = (cell) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
         <div style={{ width: '8px', height: '8px', borderRadius: '100%', background: cell && cell.includes("online")? 'Chartreuse' : 'red', marginRight: '5px' }}/>
      </div>
   )

   const nvrSettingsFormatter = (cell, row) => {
      return (
         <IconButton title="Go to NVR Web Client" onClick={()=> window.open(cell, '_blank')}>
            <Setting style={{ color: 'gray' }} />
         </IconButton>  
      )
   }

   useEffect(() => {
      let columns
      if (type == "camera") {
         columns = [
            { dataField: 'location', text: 'Location', caretRender: sortCaret },
            { dataField: 'assetId', text: 'WAIV Asset ID', hidden: true, searchable: false, caretRender: sortCaret },
            { dataField: 'assetName', text: 'WAIV Asset', caretRender: sortCaret },
            { dataField: 'cameraName', text: 'Camera Name', caretRender: sortCaret },
            // { dataField: 'nvrCode', text: 'NVR', caretRender: sortCaret },
            { dataField: 'cameraIp', text: 'IP', caretRender: sortCaret },
            {
               dataField: 'channelNum', text: 'Channel',
            },
            {
               dataField: 'status',
               text: 'Status',
               formatter: statusFormatter
            },
            { dataField: 'updateDate', text: 'Last Upated On', caretRender: sortCaret },
            // { dataField: 'model', text: 'Model', caretRender: sortCaret },

         ]
      } else if (type == "sensor") {
         columns = [
            // { dataField: 'location', text: 'Location', caretRender: sortCaret },
            { dataField: 'assetId', text: 'Camera Id', hidden: true, searchable: false, caretRender: sortCaret },
            { dataField: 'assetName', text: 'Asset Name', caretRender: sortCaret },
            { dataField: 'location', text: 'Location', caretRender: sortCaret },
            { dataField: 'sensorId', text: 'Sensor ID', caretRender: sortCaret },
            // {
            //    dataField: 'channel', text: 'Channel',
            // },
            {
               dataField: 'status',
               text: 'Status',
               formatter: statusFormatter
            },
            // { dataField: 'nvr', text: 'NVR', caretRender: sortCaret },
            { dataField: 'creationDate', text: 'Creation Date', caretRender: sortCaret },
            { dataField: 'lastMessageTime', text: 'Last Message Time', caretRender: sortCaret },
            // { dataField: 'model', text: 'Model', caretRender: sortCaret },
         ]
      }else{ //nvr
         columns = [
            { dataField: 'nvrName', text: 'Name', headerStyle:{width: '200px'}, caretRender: sortCaret },
            { dataField: 'nvrCode', text: 'Code', caretRender: sortCaret, headerStyle:{width: '75px'} },
            { dataField: 'nvrType', text: 'Type', caretRender: sortCaret, headerStyle:{width: '100px'}},
            { 
               dataField: 'status', 
               text: 'Status',
               formatter: statusFormatter, 
               caretRender: sortCaret,
               headerStyle:{width: '60px'}
            },
            { dataField: 'nvrIp', text: 'IP', caretRender: sortCaret , headerStyle:{width: '110px'}},
            { dataField: 'httpPort', text: 'Port', caretRender: sortCaret, headerStyle:{width: '50px'} },
            // { dataField: 'sdkPort', text: 'SDK Port', caretRender: sortCaret },
            { dataField: 'nvrIpPublic', text: 'Public IP', caretRender: sortCaret, headerStyle:{width: '110px'} },
            { dataField: 'httpPortPublic', text: 'Public Port', caretRender: sortCaret, headerStyle:{width: '75px'} },
            { dataField: 'publicUrl', text: 'Settings', headerStyle:{width: '100px'}, formatter: nvrSettingsFormatter },
            { dataField: 'rtspPort', text: 'RTSP Port', caretRender: sortCaret, headerStyle:{width: '75px'} },
            { dataField: 'createdDate', text: 'Created At', caretRender: sortCaret },
            { dataField: 'updatedDate', text: 'Last Upated', caretRender: sortCaret },
            // { dataField: 'drEnabled', text: 'DR', caretRender: sortCaret },
         ]
      }   
      for (let i = 0; i < columns.length; i++) {
         columns[i].headerClasses = 'header_white'
         columns[i].sort = true
      }
      //retrieve the list of locations from the device list
      let locationOpt = []
      if (type === "camera") {
         deviceList.forEach(c => {
            if (c.location && !locationOpt.find(a=>a.value === c.location)) {
               locationOpt.push({ value: c.location, label: c.location })
            }
         })
      //sensor
      }else if(type === "sensor"){
         deviceList.forEach(c => {
            if (c.location && !locationOpt.find(a=>a.value === c.location)) {
               locationOpt.push({ value: c.location, label: c.location })
            }
         })
      //nvr
      }else{ 
         deviceList.forEach(c => {
            if (c.center && !locationOpt.find(a=>a.value === c.center)) {
               locationOpt.push({ value: c.center, label: c.center})
            }
         })
      }

      //set the online and offline devices count
      const {onlineCount, offlineCount} = getStatusCount(deviceList);
      setonlineDevicesCount(onlineCount);
      setofflineDevicesCount(offlineCount);
      setTableData(deviceList)
      setlocationOpt(locationOpt)
      setColumns(columns)

   }, [type, deviceList])

   
   useEffect(() => {
      //filter TableData based on onlineFilter and offlineFilter and selectedLocation
      if(deviceList){
         let filteredData = [...deviceList];
         if(onlineFilter){
            filteredData = filter(filteredData, {status: 'online'})
         }
         if(offlineFilter){
            filteredData = filter(filteredData, {status: 'offline'})
         }
         if(selectedLocation){
            if(type === "camera"){
               filteredData = filter(filteredData, {location: selectedLocation.value})
            }else if(type === "sensor"){
               filteredData = filter(filteredData, {location: selectedLocation.value})
            }else{
               filteredData = filter(filteredData, {center: selectedLocation.value})
            }
         }
         setTableData(filteredData)
      }

   }, [onlineFilter, offlineFilter, selectedLocation, deviceList])




   const hanldeLocationChange = (filter) => {
      setSelectedLocation(filter)
   }


   const renderSpinner = () => {
      return (
         <Fragment >
            {/* {this.state.loading && */}
               <div style={{
                  position: 'Absolute ',
                  height: '100%',
                  width: '100%',
                  top: '0px',
                  left: '0px',
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                  backgroundColor: LIGHT_GREY_COLOR,
                  opacity: '1.0',
               }}
               >
                  <Spinner
                     className="spinner"
                     as="span"
                     animation="border"
                     variant="custom"
                     // size="sm"
                     // role="status"
                     // aria-hidden="true"
                     style={{ display: 'inline-block', margin: 'auto' }}
                  />
               </div>
            {/* } */}
         </Fragment>
      );
   }

   // const handelAfterSearch = (newResult) => {
   //    const statusCount = getStatusCount(newResult);
   //    for (let i=0;Columns.length > i; i++) {
   //       if (Columns[i].dataField === 'status') {
   //          Columns[i].text = `Status (Online: ${statusCount.onlineCount} / Offline: ${statusCount.offlineCount})`;
   //          break;
   //       }
   //    }
   //    setColumns(Columns);
   // }

   const toggleOnlineFilter = () => {
      if(offlineFilter && !onlineFilter){
         setofflineFilter(false)
      }
      setonlineFilter(!onlineFilter)
   }
   const toggleOfflineFilter = () => {
      if(onlineFilter && !offlineFilter){
         setonlineFilter(false)
      }
      setofflineFilter(!offlineFilter)
   }


   const getStatusCount = (dataList) => {
      let onlineCount = 0;
      let offlineCount = 0;
      if (dataList?.length > 0) {
         dataList.forEach(c => {
            if (c.status === 'online') {
               onlineCount++;
            } else {
               offlineCount++;
            }
         })
      }
      return {onlineCount, offlineCount};
   }

   const updateStatusCount = (newResult) => {
      const {onlineCount, offlineCount} = getStatusCount(newResult);
      setonlineDevicesCount(onlineCount);
      setofflineDevicesCount(offlineCount);
   }

   const deviceCountElementByStatus = (count, mode) => {
      return (<span>{`${mode === 'online' ? "Online Devices: " : "Offline Devices: "}`}{count}</span>)
   }

   const handleChildrentChange = (param) =>{
      console.log('handleChildrentChange', param)
   } 

   const renderAddModal = () => {
      return (
         <Col md={2}>
            <Button 
            outline
            color="custom" 
            style={{ position: 'relative', marginRight: '10px', marginLeft: '15px' }} 
            onClick={toggleAddModal}>
               Add Device
            </Button>
            <AddDeviceModal
               modalVisible={addModalVisible}
               toggleModal={toggleAddModal}
               type={type}
               handleChildrentChange={handleChildrentChange}
            />
         </Col>
      )
   }

   const toggleAddModal = () => {
      setaddModalVisible(!addModalVisible)
   }

   return (
      <>
         {Columns &&
            <ToolkitProvider
               keyField={type === "nvr" ? 'nvrCode' : type==="sensor" ? 'sensorId' : 'assetId'}
               data={TableData}
               columns={Columns}
               search={{ searchFormatted: true, afterSearch: (newResult) => {updateStatusCount(newResult)}}}
            >
               {props => (
                  <div class='deviceContainer'>
                     <Row>
                        <Col md={2}>
                           <SearchBar {...props.searchProps} delay={500} style={{margin: 'auto', width: '100%'}} id="deviceSearch" placeholder='Quick search'/>
                        </Col>
                        <Col md={4}>
                           <Select
                              isClearable
                              placeholder='Select Location'
                              options = {locationOpt}
                              value={selectedLocation}
                              onChange={hanldeLocationChange}
                              styles={customStyles}
                           />
                        </Col>
                        {type === 'nvr' &&
                           renderAddModal()
                        }
                     </Row>
                     <Row>
                        <Col md={3}>
                           <div className={`btn btn-filter${onlineFilter ? '-active' : '-inactive'}`} onClick={toggleOnlineFilter}>{onlineFilter ? deviceCountElementByStatus(onlineDevicesCount, "online") : 'Show Online Devices'}</div>
                        </Col>
                        <Col md={3}>
                           <div className={`btn btn-filter${offlineFilter ? '-active' : '-inactive'}`} onClick={toggleOfflineFilter}>{offlineFilter ? deviceCountElementByStatus(offlineDevicesCount, "offline") : 'Show Offline Devices'}</div>
                        </Col>
                     </Row>
                     <BootstrapTableNext
                        classes='deviceTable'
                        {...props.baseProps}
                        // rowEvents={{ onClick: (e, row) => onRowClick(row.id) }}
                        caretRender={sortCaret}
                        pagination={deviceList.length > 0 ? paginationFactory() : null}
                        sort={{ sortCaret }}
                        bordered={false}
                        striped
                        noDataIndication={'There is no data to display'}
                        rowClasses={smartBuildingDeviceRowClass}
                     />
                   </div>
               )}
            </ToolkitProvider>
          }

      </>
   )
}




export default withRouter(RenderDeviceList);
