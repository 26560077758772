/* eslint-disable */
import axios from 'axios';
import { WEB_SERVICE_ROOT } from '../../constants/appConstants';
import { toast } from 'react-toastify';
import { forEach } from 'lodash';

export const FETCH_NVRS_DEVICES_REQUEST = 'FETCH_NVRS_DEVICES_REQUEST';
export const FETCH_NVRS_DEVICES_SUCCESS = 'FETCH_NVRS_DEVICES_SUCCESS';
export const FETCH_NVRS_DEVICES_FAILURE = 'FETCH_NVRS_DEVICES_FAILURE';

export const FETCH_NVR_PERMISSION_GROUPS_REQUEST = 'FETCH_NVR_PERMISSION_GROUPS_REQUEST';
export const FETCH_NVR_PERMISSION_GROUPS_SUCCESS = 'FETCH_NVR_PERMISSION_GROUPS_SUCCESS';
export const FETCH_NVR_PERMISSION_GROUPS_FAILURE = 'FETCH_NVR_PERMISSION_GROUPS_FAILURE';

export const FETCH_NVRS_PERMISSION_GROUPS_REQUEST = 'FETCH_NVRS_PERMISSION_GROUPS_REQUEST';
export const FETCH_NVRS_PERMISSION_GROUPS_SUCCESS = 'FETCH_NVRS_PERMISSION_GROUPS_SUCCESS';
export const FETCH_NVRS_PERMISSION_GROUPS_FAILURE = 'FETCH_NVRS_PERMISSION_GROUPS_FAILURE';

export const ADD_NVRS_PERMISSION_GROUPS_REQUEST = 'ADD_NVRS_PERMISSION_GROUPS_REQUEST';
export const ADD_NVRS_PERMISSION_GROUPS_SUCCESS = 'ADD_NVRS_PERMISSION_GROUPS_SUCCESS';
export const ADD_NVRS_PERMISSION_GROUPS_FAILURE = 'ADD_NVRS_PERMISSION_GROUPS_FAILURE';

export const EDIT_NVR_PERMISSION_GROUP_REQUEST = 'EDIT_NVR_PERMISSION_GROUPS_REQUEST';
export const EDIT_NVR_PERMISSION_GROUP_SUCCESS = 'EDIT_NVR_PERMISSION_GROUP_SUCCESS';
export const EDIT_NVR_PERMISSION_GROUP_FAILURE = 'EDIT_NVR_PERMISSION_GROUPS_FAILURE';

export const EDIT_NVRS_PERMISSION_GROUPS_REQUEST = 'EDIT_NVRS_PERMISSION_GROUPS_REQUEST';
export const EDIT_NVRS_PERMISSION_GROUPS_SUCCESS = 'EDIT_NVRS_PERMISSION_GROUPS_SUCCESS';
export const EDIT_NVRS_PERMISSION_GROUPS_FAILURE = 'EDIT_NVRS_PERMISSION_GROUPS_FAILURE';

export const DELETE_NVR_PERMISSION_GROUP_REQUEST = 'DELETE_NVR_PERMISSION_GROUP_REQUEST';
export const DELETE_NVR_PERMISSION_GROUP_SUCCESS = 'DELETE_NVR_PERMISSION_GROUP_SUCCESS';
export const DELETE_NVR_PERMISSION_GROUP_FAILURE = 'DELETE_NVR_PERMISSION_GROUP_FAILURE';

export const FETCH_NVRS_USERS_REQUEST = 'FETCH_NVRS_USERS_REQUEST';
export const FETCH_NVRS_USERS_SUCCESS = 'FETCH_NVRS_USERS_SUCCESS';
export const FETCH_NVRS_USERS_FAILURE = 'FETCH_NVRS_USERS_FAILURE';

export const FETCH_NVR_USERS_REQUEST = 'FETCH_NVR_USERS_REQUEST';
export const FETCH_NVR_USERS_SUCCESS = 'FETCH_NVR_USERS_SUCCESS';
export const FETCH_NVR_USERS_FAILURE = 'FETCH_NVR_USERS_FAILURE';

export const ADD_NVRS_USER_REQUEST = 'ADD_NVRS_USER_REQUEST';
export const ADD_NVRS_USER_SUCCESS = 'ADD_NVRS_USER_SUCCESS';
export const ADD_NVRS_USER_FAILURE = 'ADD_NVRS_USER_FAILURE';

export const ADD_NVR_USER_REQUEST = 'ADD_NVR_USER_REQUEST';
export const ADD_NVR_USER_SUCCESS = 'ADD_NVR_USER_SUCCESS';
export const ADD_NVR_USER_FAILURE = 'ADD_NVR_USER_FAILURE';

export const EDIT_NVRS_USERS_REQUEST = 'EDIT_NVRS_USERS_REQUEST';
export const EDIT_NVRS_USERS_SUCCESS = 'EDIT_NVRS_USERS_SUCCESS';
export const EDIT_NVRS_USERS_FAILURE = 'EDIT_NVRS_USERS_FAILURE';

export const EDIT_NVR_USER_REQUEST = 'EDIT_NVR_USER_REQUEST';
export const EDIT_NVR_USER_SUCCESS = 'EDIT_NVR_USER_SUCCESS';
export const EDIT_NVR_USER_FAILURE = 'EDIT_NVR_USER_FAILURE';

export const DELETE_NVRS_USERS_REQUEST = 'DELETE_NVRS_USERS_REQUEST';
export const DELETE_NVRS_USERS_SUCCESS = 'DELETE_NVRS_USERS_SUCCESS';
export const DELETE_NVRS_USERS_FAILURE = 'DELETE_NVRS_USERS_FAILURE';

export const DELETE_NVR_USERS_REQUEST = 'DELETE_NVRS_USERS_REQUEST';
export const DELETE_NVR_USERS_SUCCESS = 'DELETE_NVRS_USERS_SUCCESS';
export const DELETE_NVR_USERS_FAILURE = 'DELETE_NVRS_USERS_FAILURE';

/*
 * /nvrs/[{id,id,...}]/devices
 */ 
const fetchNvrsDevicesRequest = () => ({
    type: FETCH_NVRS_DEVICES_REQUEST,
})

const fetchNvrsDevicesSuccess = (deviceList) => ({
    type: FETCH_NVRS_DEVICES_SUCCESS,
    payload: deviceList
})

const fetchNvrsDevicesFailure = (error) => ({
    type: FETCH_NVRS_DEVICES_FAILURE,
    payload: error
})

export const fetchNvrsDevices = (nvrIds) => {
    return async (dispatch) => {
        dispatch(fetchNvrsDevicesRequest())
        try {
            let url = `${WEB_SERVICE_ROOT}nvrs/${nvrIds}/devices`
            const response = await axios.post(url)
            if (response.data.success) {
                dispatch(fetchNvrsDevicesSuccess(response.data.data))
            } else {
                dispatch(fetchNvrsDevicesFailure(response.data.message))
            }
        } catch(error) {
            dispatch(fetchNvrsDevicesFailure(error))
        }
    }
}


/*
 * /nvrs/[{id,id,...}]/permission-groups/{type}
 */ 
const fetchSingleNvrPermissionGroupsRequest = () => ({
    type: FETCH_NVR_PERMISSION_GROUPS_REQUEST,
})

const fetchSingleNvrPermissionGroupsSuccess = (data) => ({
    type: FETCH_NVR_PERMISSION_GROUPS_SUCCESS,
    payload: data
})

const fetchSingleNvrPermissionGroupsFailure = (error) => ({
    type: FETCH_NVR_PERMISSION_GROUPS_FAILURE,
    payload: error
})

const fetchNvrsPermissionGroupsRequest = () => ({
    type: FETCH_NVRS_PERMISSION_GROUPS_REQUEST,
})

const fetchNvrsPermissionGroupsSuccess = (permissionGroupsList) => ({
    type: FETCH_NVRS_PERMISSION_GROUPS_SUCCESS,
    payload: permissionGroupsList
})

const fetchNvrsPermissionGroupsFailure = (error) => ({
    type: FETCH_NVRS_PERMISSION_GROUPS_FAILURE,
    payload: error
})

export const fetchNvrsPermissionGroups = (nvrIds, type) => {
    return async (dispatch) => {
        dispatch(fetchNvrsPermissionGroupsRequest())
        try {
            let url = `${WEB_SERVICE_ROOT}nvrs/${nvrIds}/permission-groups/${type}`
            const response = await axios.post(url)
            // console.log("http post request /premission-groups:", response.data.data)
            if (response.data.success) {
                dispatch(fetchNvrsPermissionGroupsSuccess(response.data.data))
            } else {
                dispatch(fetchNvrsPermissionGroupsFailure(response.data.message))
            }
        } catch(error) {
            dispatch(fetchNvrsPermissionGroupsFailure(error))
        }
    }
}

export const fetchSingleNvrPermissionGroups = (nvrId, type) => {

    return async (dispatch) => {
        dispatch(fetchSingleNvrPermissionGroupsRequest())
        try {
            let url = `${WEB_SERVICE_ROOT}nvr/${nvrId}/permission-groups/${type}`
            const response = await axios.post(url)
            // console.log("http post request /premission-groups:", response.data)
            if (response.data.success) {
                let singleNVRPermissionData = {
                    nvr: response.data.nvr,
                    permissionGroups: response.data.data
                }
                // console.log("permission group data nvr ", singleNVRPermissionData)
                dispatch(fetchSingleNvrPermissionGroupsSuccess(singleNVRPermissionData))
            } else {
                dispatch(fetchSingleNvrPermissionGroupsFailure(response.data.message))
                toast.error("Failed to fetch permission groups")
            }
        } catch(error) {
            dispatch(fetchSingleNvrPermissionGroupsFailure(error))
            toast.error("Failed to fetch permission groups")
        }
    }
}
/*
 * /nvrs/[{id,id,...}]/permission-groups/add
 */ 
const addNvrsPermissionGroupsRequest = () => ({
    type: ADD_NVRS_PERMISSION_GROUPS_REQUEST,
})

const addNvrsPermissionGroupsSuccess = (data) => ({
    type: ADD_NVRS_PERMISSION_GROUPS_SUCCESS,
    payload: data
})

const addNvrsPermissionGroupsFailure = (error) => ({
    type: ADD_NVRS_PERMISSION_GROUPS_FAILURE,
    payload: error
})

export const addNvrsPermissionGroups = (nvrIds, permissionGroup) => {
    // console.log("Add permission group data", nvrIds, permissionGroup)
    return async (dispatch) => {
        dispatch(addNvrsPermissionGroupsRequest())
        try {
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvrs/${nvrIds}/permission-groups/add`, permissionGroup)
            if (response.data.success) {
                toast.success("Successfully added permission group", permissionGroup.name)
                response.data.data.forEach((data) => {
                    if(!data.success){
                        toast.error("Failed to add permission group at", data.message)
                    }
                })
                dispatch(addNvrsPermissionGroupsSuccess(response.data))
            } else {
                dispatch(addNvrsPermissionGroupsFailure(response.data.message))
            }
        } catch(error) {
            dispatch(addNvrsPermissionGroupsFailure(error))
        }
    }
}


/*
 * /nvrs/id/permission-groups/edit/{permission-group-id}
 */

export const editSingleNvrPermissionGroup = (nvrId, permissionGroupId, permissionGroupData) => {
    return async (dispatch) => {
        dispatch({type: EDIT_NVR_PERMISSION_GROUP_REQUEST})
        try {
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvr/${nvrId}/permission-groups/edit/${permissionGroupId}`, permissionGroupData)
            if (response.data.success) {
                dispatch({type: EDIT_NVR_PERMISSION_GROUP_SUCCESS})
                toast.success(response.data.message)
            } else {
                dispatch({type: EDIT_NVR_PERMISSION_GROUP_FAILURE, payload: response.data.message})
            }
        } catch(error) {
            dispatch({type: EDIT_NVR_PERMISSION_GROUP_FAILURE, payload: error})
        }
    }
}

/*
 * /nvr/id/permission-groups/delete/{permission-group-id}
 */

//delete single permission group
export const deleteSingleNvrPermissionGroup = (nvrId, permissionGroupId) => {
    return async (dispatch) => {
        dispatch({type: DELETE_NVR_PERMISSION_GROUP_REQUEST})
        try {
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvr/${nvrId}/permission-groups/delete/${permissionGroupId}`)
            if (response.data.success) {
                dispatch({type: DELETE_NVR_PERMISSION_GROUP_SUCCESS})
                toast.success("Deleted permission group successfully")
            } else {
                dispatch({type: DELETE_NVR_PERMISSION_GROUP_FAILURE, payload: response.data.message})
                toast.error("Failed to delete permission group")
            }
        } catch(error) {
            dispatch({type: DELETE_NVR_PERMISSION_GROUP_FAILURE, payload: error})
            toast.error("Failed to delete permission group")
        }
    }
}

/*
 * /nvrs/[{id,id,...}]/users
 */ 
const fetchNvrsUsersRequest = () => ({
    type: FETCH_NVRS_USERS_REQUEST,
})

const fetchNvrUserRequest = () => ({
    type: FETCH_NVR_USERS_REQUEST,
})

const fetchNvrsUsersSuccess = (users) => ({
    type: FETCH_NVRS_USERS_SUCCESS,
    payload: users,
})
const fetchNvrUsersSuccess = (users) => ({
    type: FETCH_NVR_USERS_SUCCESS,
    payload: users,
})

const fetchNvrsUsersFailure = (error) => ({
    type:FETCH_NVRS_USERS_FAILURE,
    payload: error,
})
const fetchNvrUsersFailure = (error) => ({
    type:FETCH_NVR_USERS_FAILURE,
    payload: error,
})

export const fetchNvrsUsers = (nvrIds) => {
    return async (dispatch) => {
        dispatch(fetchNvrsUsersRequest())
        try {
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvrs/${nvrIds}/users`)
            if (response.data.success) {
                // console.log("http post request /users:", response.data.data)
                dispatch(fetchNvrsUsersSuccess(response.data.data))
            } else {
                dispatch(fetchNvrsUsersFailure(response.data.message))
            }
        } catch(error) {
            dispatch(fetchNvrsUsersFailure(error))
        }
    }
}

export const fetchSingleNvrUsers = (nvrId) => {

    return async (dispatch) => {
        dispatch(fetchNvrUserRequest())
        try {
            let url = `${WEB_SERVICE_ROOT}nvr/${nvrId}/users`
            const response = await axios.post(url)
            if (response.data.success) {
                let singleNvrUserData = {
                    nvr: response.data.nvr,
                    users: response.data.data
                }
                // console.log("users data nvr ", singleNvrUserData)
                dispatch(fetchNvrUsersSuccess(singleNvrUserData))
            } else {
                dispatch(fetchNvrUsersFailure(response.data.message))
                toast.error("Failed to fetch users data")
            }
        } catch(error) {
            dispatch(fetchNvrUsersFailure(error))
            toast.error("Failed to fetch users data")
        }
    }
}

/**
 * /nvrs/[{id,id,...}]/users/add/group/{group-name}
 */

export const addUserMultipleNvrs = (nvrIds,userData, permissionGroupName) => {
    return async (dispatch) => {
        dispatch({type: ADD_NVRS_USER_REQUEST})
        try {
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvrs/${nvrIds}/users/add/group/${permissionGroupName}`, userData)
            if (response.data.success) {
                dispatch({type: ADD_NVRS_USER_SUCCESS})
                toast.success("Finished adding user to all NVRs")
                //loop through response.data.data
                response.data.data.forEach((data) => {
                    if(!data.success){
                        toast.error(`Failed to add user to ${data.nvr.nvrName}. ${data.message}`)
                    }
                })
            } else {
                dispatch({type: ADD_NVRS_USER_FAILURE, payload: response.data.message})
                toast.error(response.data.message)
            }
        } catch(error) {
            dispatch({type: ADD_NVRS_USER_FAILURE, payload: error})
            toast.error(error)
        }
    }
}


/*
 * /nvr/id/users/add
 * @param {*} nvrId 
 * @param {*} userData 
 */
export const addNvrUser = (nvrId,userData) => {
    return async (dispatch) => {
        dispatch({type: ADD_NVR_USER_REQUEST})
        try {
            // console.log("Add user data", userData)
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvr/${nvrId}/users/add`, userData)
            if (response.data.success) {
                dispatch({type: ADD_NVR_USER_SUCCESS})
                toast.success(response.data.message)
            } else {
                dispatch({type: ADD_NVR_USER_FAILURE, payload: response.data.message})
                toast.error(response.data.message)
            }
        } catch(error) {
            dispatch({type: ADD_NVRS_USER_FAILURE, payload: error})
            toast.error(error)
        }
    }
}

/*
* /nvr/id/users/edit/{user-id}
*/

export const editNvrUser = (nvrId, userId, userData) => {
    return async (dispatch) => {
        dispatch({type: EDIT_NVR_USER_REQUEST})
        try {
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvr/${nvrId}/users/edit/${userId}`, userData)
            if (response.data.success) {
                dispatch({type: EDIT_NVR_USER_SUCCESS})
                toast.success(response.data.message)
            } else {
                dispatch({type: EDIT_NVR_USER_FAILURE, payload: response.data.message})
                toast.error("Failed to edit user")
            }
        } catch(error) {
            dispatch({type: EDIT_NVR_USER_FAILURE, payload: error})
            toast.error("Failed to edit user")
        }
    }
}

/*
* /nvr/id/users/delete/{user-id}
*/

//delete nvr user
export const deleteNvrUser = (nvrId, userId) => {
    return async (dispatch) => {
        dispatch({type: DELETE_NVR_USERS_REQUEST})
        try {
            const response = await axios.post(`${WEB_SERVICE_ROOT}nvr/${nvrId}/users/delete/${userId}`)
            if (response.data.success) {
                dispatch({type: DELETE_NVR_USERS_SUCCESS})
                toast.success(response.data.message)
            } else {
                dispatch({type: DELETE_NVR_USERS_FAILURE, payload: response.data.message})
                toast.error("Failed to delete user")
            }
        } catch(error) {
            dispatch({type: DELETE_NVR_USERS_FAILURE, payload: error})
            toast.error("Failed to delete user")
        }
    }
}

