/* eslint-disable */
import React, { Component, useEffect, useContext, createContext} from 'react';
import { withRouter } from "react-router-dom";
import {Col, Row, Card, CardBody, Container, Nav, NavItem, NavLink, TabPane, TabContent, CardTitle, Form, Label, Input} from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { addNvrsPermissionGroups, fetchNvrsDevices, fetchNvrsPermissionGroups, fetchNvrsUsers } from '../../redux/actions/nvrMgmtAction';
import { loadNVRs } from '../../redux/actions/surveilenceActions';
import { dispatch } from 'd3';
import NVRSettings from './components/NVRSettings';
import { getCurrentUser } from '../../factories/auth';

const NvrMgmt = ({loadNVRs}) => {
    // static contextType = UserContext;
    useEffect(() => {
        loadNVRs()
    }, [])

    return (
        <Container className='dashboard'>
            <Row>
                <Col md={12}>
                    <NVRSettings />
                </Col>
            </Row>

        </Container>
    );

}

const mapStateToProps = (state) => ({
    nvrs: state.surveillance.nvrList,
    devices: state.nvrMgmt.devices,
    permissionGroups: state.nvrMgmt.permissionGroups,
    users: state.nvrMgmt.users,
})
const mapDispatchToProps = (dispatch) => ({
    loadNVRs: () => dispatch(loadNVRs()),
    fetchNvrsDevices: (nvrIds) => dispatch(fetchNvrsDevices(nvrIds)),
    fetchNvrsPermissionGroups: (nvrIds, type) => dispatch(fetchNvrsPermissionGroups(nvrIds, type)),
    fetchNvrsUsers: (nvrIds) => dispatch(fetchNvrsUsers(nvrIds)),
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(NvrMgmt)