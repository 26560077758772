/* eslint-disable */
import React,{ Component, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {Col, Row, Card, CardBody, Container, CardTitle, Form, Label, Input, Button, ListGroup, ListGroupItem} from 'reactstrap';
import { loadNVRs } from '../../../redux/actions/surveilenceActions';
import NVRSettingPanel from './NVRSettingPanel';
import {addNvrsPermissionGroups, fetchSingleNvrPermissionGroups, fetchSingleNvrUsers} from '../../../redux/actions/nvrMgmtAction'
import PermissionGroupPanel from './PermissionGroupPanel';
import { NavTabs } from '../../Map/components/MainBoard';
import { Tab } from 'react-tabs';
import { ExpandMore } from '@material-ui/icons';
import { set } from 'lodash';




const NVRSettings = ({nvrList, addNvrsPermissionGroups, nvrPermissionGroupList, fetchSingleNvrPermissionGroups, nvrUserList, fetchSingleNvrUsers}) => {
    const [nvrListState, setNvrListState] = useState(null)

    const handleNVRClick = (nvr, index) => {
        if((!nvr.permissionGroups) || nvr.needReload){
            // console.log("fetching permission groups", nvr)
            fetchSingleNvrPermissionGroups(nvr.id, "full")
        }

        if((!nvr.users) || nvr.needReloadUser){
            // console.log("fetching users", nvr)
            fetchSingleNvrUsers(nvr.id)
        }
        let tempNVRList = [...nvrListState]
        tempNVRList[index].collapse = !tempNVRList[index].collapse
        tempNVRList[index].needReload = false
        tempNVRList[index].needReloadUser = false
        setNvrListState(tempNVRList)
    }

    useEffect(() => {
        loadNVRs()
    }, [])

    useEffect(() => {
        let sortedNVRList = nvrList && nvrList.length > 0 ? [...nvrList].sort((a, b) => a.center.localeCompare(b.center)) : null
        sortedNVRList && sortedNVRList.map(nvr => nvr.collapse = false)
        setNvrListState(sortedNVRList)
    }, [nvrList])

    useEffect(() => {
        //add nvrPermissionGroupList to nvrListState
        if(nvrPermissionGroupList && nvrPermissionGroupList.length > 0 && nvrListState && nvrListState.length > 0){
            let tempNVRList = [...nvrListState]
            nvrPermissionGroupList.forEach(nvrPermissionGroup => {
                let nvrIndex = tempNVRList.findIndex(nvr => nvr.id === nvrPermissionGroup.nvr.id)
                if(nvrIndex !== -1){
                    tempNVRList[nvrIndex].permissionGroups = nvrPermissionGroup.permissionGroups
                }
            })
            // console.log("nvr permission group list", tempNVRList)
            setNvrListState(tempNVRList)
        }
    }, [nvrPermissionGroupList])
    useEffect(() => {

        //add users data to nvrListState
        if(nvrUserList && nvrUserList.length > 0 && nvrListState && nvrListState.length > 0){
            let tempNVRList = [...nvrListState]
            nvrUserList.forEach(nvrUser => {
                let nvrIndex = tempNVRList.findIndex(nvr => nvr.id === nvrUser.nvr.id)
                if(nvrIndex !== -1){
                    tempNVRList[nvrIndex].users = nvrUser.users
                }
            })
            // console.log("nvr with user list", tempNVRList)
            setNvrListState(tempNVRList)
        }
    }, [nvrUserList])

    // const renderNVRsPermission = () => {
    // let sortedNVRList = nvrList && nvrList.length > 0 ? [...nvrList].sort((a, b) => a.center.localeCompare(b.center)) : null
    //     return (
    //         <ListGroup>
    //             {sortedNVRList && sortedNVRList.map((nvr, index) => {
    //                 return <NVRSettingPanel index={index} nvr={nvr}/>                       
    //             })}
    //         </ListGroup>
    //     )
    // }

    const renderNVRList = () => {
        return (
            <ListGroup>
                {nvrListState && nvrListState.map((nvr, index) => {
                    return  (
                        <>          
                        <ListGroupItem 
                        tag="a" 
                        onClick={()=>handleNVRClick(nvr, index)} 
                        action className={`accordion-list-item ${nvr.collapse ? 'accordion-selected' : ''}`} 
                        key={index}>
                            <span>{nvr.nvrName}</span> <ExpandMore/>
                        </ListGroupItem>
                        <NVRSettingPanel index={index} nvr={nvr} permissionGroups={nvr.permissionGroups} users={nvr.users} collapse={nvr.collapse} handleSetReloadDataForAllCam={handleSetReloadDataForAllCam}/>
                        </>
                    )
                })}
            </ListGroup>
        )
    }
    

    const handleSetReloadDataForAllCam = (needReload) => {
        // console.log("setting need reload", needReload)
        let newNVRStateList = nvrListState.map(nvr => {
           return {...nvr, needReload: needReload}
        })
        setNvrListState(newNVRStateList)
    }


    return (
        <>
        <Row>
            <h4>NVRs</h4>
        </Row>
        {/* NVR List */}
        {renderNVRList()}
        </>
    )
}

const mapStateToProps = (state) => ({
    nvrList: state.surveillance.nvrList,
    nvrPermissionGroupList: state.nvrMgmt.nvrPermissions,
    nvrUserList: state.nvrMgmt.users 
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({loadNVRs, addNvrsPermissionGroups, fetchSingleNvrPermissionGroups, fetchSingleNvrUsers}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NVRSettings)