/* eslint-disable */
export const MAP_DEVICE_LOAD_SUCCEED = 'MAP_DEVICE_LOAD_SUCCEED';
export const MAP_DEVICE_LOAD_FAILED = 'MAP_DEVICE_LOAD_FAILED';
export const MAP_DEVICE_ATTR_LOAD_SUCCEED = 'MAP_DEVICE_ATTR_LOAD_SUCCEED';
export const MAP_DEVICE_ATTR_LOAD_FAILED = 'MAP_DEVICE_ATTR_LOAD_FAILED';
export const MAP_DEVICE_MAP_LOAD_SUCCEED = 'MAP_DEVICE_MAP_LOAD_SUCCEED';
export const MAP_DEVICE_MAP_LOAD_FAILED = 'MAP_DEVICE_MAP_LOAD_FAILED';
export const MAP_DEVICE_MAP_LOAD_CLEAR = 'MAP_DEVICE_MAP_LOAD_CLEAR';

export const DEVICE_ADD_ATTR_DEVICE = 'DEVICE_ADD_ATTR_DEVICE';
export const DEVICE_UPDATE_ATTR_DEVICE = 'DEVICE_UPDATE_ATTR_DEVICE';


export const API_ADDR = 'http://10.99.0.45';

export const API_ROOT_URL = '/api/um';

export const SEARCH_USER = '/search';
export const SEARCH_USER_STARTED = 'SEARCH_USER_STARTED';
export const SEARCH_USER_SUCCEED = 'SEARCH_USER_SUCCEED';
export const SEARCH_USER_FAILED = 'SEARCH_USER_FAILED';

export const GET_USER_DATA = '/getuser';
export const GET_USER_DATA_STARTED = 'GET_USER_DATA_STARTED';
export const GET_USER_DATA_SUCCEED = 'GET_USER_DATA_SUCCEED';
export const GET_USER_DATA_FAILED = 'GET_USER_DATA_FAILED';

export const SAVE_USER_DATA = '/updateuser';
export const SAVE_USER_DATA_STARTED = 'SAVE_USER_DATA_STARTED';
export const SAVE_USER_DATA_SUCCEED = 'SAVE_USER_DATA_SUCCEED';
export const SAVE_USER_DATA_FAILED = 'SAVE_USER_DATA_FAILED';

export const ADD_USER_DATA = '/adduser';
export const ADD_USER_DATA_STARTED = 'ADD_USER_DATA_STARTED';
export const ADD_USER_DATA_SUCCEED = 'ADD_USER_DATA_SUCCEED';
export const ADD_USER_DATA_FAILED = 'ADD_USER_DATA_FAILED';

export const DELETE_USER_DATA = '/deleteuser';
export const DELETE_USER_DATA_STARTED = 'DELETE_USER_DATA_STARTED';
export const DELETE_USER_DATA_SUCCEED = 'DELETE_USER_DATA_SUCCEED';
export const DELETE_USER_DATA_FAILED = 'DELETE_USER_DATA_FAILED';

export const GET_ALL_MODULES = '/modules';
export const GET_ALL_MODULES_STARTED = 'GET_ALL_MODULES_STARTED';
export const GET_ALL_MODULES_SUCCEED = 'GET_ALL_MODULES_SUCCEED';
export const GET_ALL_MODULES_FAILED = 'GET_ALL_MODULES_FAILED';

export const LOGIN_ROOT = '/login';
export const LOGIN_STARTED = 'LOGIN_START';
export const LOGIN_SUCCEED = 'LOGIN_SUCCEED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const STORE_RESET = 'STORE_RESET';

export const SUCCESS_MSG = 'Success';

export const MAP_FILTER_THEME_INPUT_BIND = 'MAP_FILTER_THEME_INPUT_BIND';
export const MAP_FILTER_MESSAGE_TYPE_INPUT_BIND = 'MAP_FILTER_MESSAGE_TYPE_INPUT_BIND';
export const MAP_FILTER_DEVICE_TYPE_INPUT_BIND = 'MAP_FILTER_DEVICE_TYPE_INPUT_BIND';
export const MAP_FILTER_FROM_INPUT_BIND = 'MAP_FILTER_FROM_INPUT_BIND';
export const MAP_FILTER_TO_INPUT_BIND = 'MAP_FILTER_TO_INPUT_BIND';
export const MAP_FILTER_ATTRIBUTE_INPUT_BIND = 'MAP_FILTER_ATTRIBUTE_INPUT_BIND';
export const MAP_FILTER_ATTRIBUTE_CHANGE = 'MAP_FILTER_ATTRIBUTE_CHANGE';
export const MAP_FILTER_ASSETCLASS_INPUT_BIND = 'MAP_FILTER_ASSETCLASS_INPUT_BIND';
export const MAP_FILTER_ASSET_ATTRIBUTE_CHANGE = 'MAP_FILTER_ASSET_ATTRIBUTE_CHANGE';

export const MAP_LOAD_ASSETS_SUCCEED = 'MAP_LOAD_ASSETS_SUCCEED';
export const MAP_LOAD_ASSETS_FAILED = 'MAP_LOAD_ASSETS_FAILED';
export const MAP_LOAD_ASSET_ATTRIBUTE_SUCCEED = 'MAP_LOAD_ASSET_ATTRIBUTE_SUCCEED';
export const MAP_LOAD_ASSET_ATTRIBUTE_FAILED = 'MAP_LOAD_ASSET_ATTRIBUTE_FAILED';
export const MAP_GET_ASSETS_DATA_SUCCEED = 'MAP_GET_ASSETS_DATA_SUCCEED';
export const MAP_GET_ASSETS_DATA_FAILED = 'MAP_GET_ASSETS_DATA_FAILED';
export const MAP_GET_THEME_SUCCEED = 'MAP_GET_THEME_SUCCEED';
export const MAP_GET_THEME_FAILED = 'MAP_GET_THEME_FAILED';

export const MAP_LOAD_ASSETCLASS_SUCCEED = 'MAP_LOAD_ASSETCLASS_SUCCEED';
export const MAP_LOAD_ASSETCLASS_FAILED = 'MAP_LOAD_ASSETCLASS_FAILED';
export const MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED = 'MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED';
export const MAP_GET_ASSETCLASS_ATTRIBUTE_FAILED = 'MAP_GET_ASSETCLASS_ATTRIBUTE_FAILED';
export const MAP_CLEAR_ASSETCLASS_ATTRIBUTE = 'MAP_CLEAR_ASSETCLASS_ATTRIBUTE';
export const MAP_REFRASH_STARTED = 'MAP_REFRASH_STARTED';

export const MAP_DEVICE_UPDATE_ONE_DEVICE = 'MAP_DEVICE_UPDATE_ONE_DEVICE';
export const MAP_LOAD_DEVICE_ATTRIBUTE_SUCCEED = 'MAP_LOAD_DEVICE_ATTRIBUTE_SUCCEED';
export const MAP_LOAD_DEVICE_ATTRIBUTE_FAILED = 'MAP_LOAD_DEVICE_ATTRIBUTE_FAILED';


export const MAP_GET_DEVICE_GROUP_DATA_SUCCEED = 'MAP_GET_DEVICE_GROUP_DATA_SUCCEED';
export const MAP_GET_DEVICE_GROUP_DATA_FAILED = 'MAP_GET_DEVICE_GROUP_DATA_FAILED';
export const MAP_CLEAR_DEVICE_GROUP_DATA = 'MAP_CLEAR_DEVICE_GROUP_DATA';

export const MAP_GET_ASSET_GROUP_DATA_SUCCEED = 'MAP_GET_ASSET_GROUP_DATA_SUCCEED';
export const MAP_GET_ASSET_GROUP_DATA_FAILED = 'MAP_GET_ASSET_GROUP_DATA_FAILED';
export const MAP_CLEAR_ASSET_GROUP_DATA = 'MAP_CLEAR_ASSET_GROUP_DATA';
export const MAP_FILTER_ASSET_GROUP_ATTRIBUTE_CHANGE = 'MAP_FILTER_ASSET_GROUP_ATTRIBUTE_CHANGE';

export const MAP_GET_DEVICE_BY_GROUP_SUCCEED = 'MAP_GET_DEVICE_BY_GROUP_SUCCEED';
export const MAP_GET_DEVICE_BY_GROUP_FAILED = 'MAP_GET_DEVICE_BY_GROUP_FAILED';
export const MAP_CLEAR_GROUP_DEVICES = 'MAP_CLEAR_GROUP_DEVICES';

export const ASSET_LOAD_DEVICE_START = 'ASSET_LOAD_DEVICE_START';
export const ASSET_LOAD_DEVICE_SUCCEED = 'ASSET_LOAD_DEVICE_SUCCEED';
export const ASSET_LOAD_DEVICE_FAILED = 'ASSET_LOAD_DEVICE_FAILED';

export const ASSET_LOAD_CAMERA_LIST_SUCCEED = 'ASSET_LOAD_CAMERA_LIST_SUCCEED';
export const ASSET_LOAD_CAMERA_LIST_FAILED = 'ASSET_LOAD_CANERA_LIST_FAILED';

export const ASSET_LOAD_SENSOR_LIST_SUCCEED = 'ASSET_LOAD_SENSOR_LIST_SUCCEED';
export const ASSET_LOAD_SENSOR_LIST_FAILED = 'ASSET_LOAD_SENSOR_LIST_FAILED';



export const ASSET_LOAD_ASSET_SUCCEED = 'ASSET_LOAD_ASSET_SUCCEED';
export const ASSET_LOAD_ASSET_FAILED = 'ASSET_LOAD_ASSET_FAILED';
export const ASSET_LOAD_CLASS_SUCCEED = 'ASSET_LOAD_CLASS_SUCCEED';
export const ASSET_LOAD_CLASS_FAILED = 'ASSET_LOAD_CLASS_FAILED';

export const ASSET_ADD_ASSET = 'ASSET_ADD_ASSET';
export const ASSET_UPDATE_ASSET = 'ASSET_UPDATE_ASSET';
export const ASSET_UPDATE_ONE_ASSET = 'ASSET_UPDATE_ONE_ASSET';
export const ASSET_REMOVE_ASSET = 'ASSET_REMOVE_ASSET';
export const ASSET_ADD_ASSET_CLASS = 'ASSET_ADD_ASSET_CLASS';
export const ASSET_UPDATE_ASSET_CLASS = 'ASSET_UPDATE_ASSET_CLASS';
export const ASSET_UPDATE_ONE_ASSET_CLASS = 'ASSET_UPDATE_ONE_ASSET_CLASS';
export const ASSET_REMOVE_ASSET_CLASS = 'ASSET_REMOVE_ASSET_CLASS';
export const ASSET_ADD_ATTR_ASSET_CLASS = 'ASSET_ADD_ATTR_ASSET_CLASS,';
export const ASSET_UPDATE_ATTR_ASSET_CLASS = 'ASSET_UPDATE_ATTR_ASSET_CLASS';
export const ASSET_REMOVE_ATTR_ASSET_CLASS = 'ASSET_REMOVE_ATTR_ASSET_CLASS,';


export const ASSET_ADD_ATTR_ASSET = 'ASSET_ADD_ATTR_ASSET';
export const ASSET_UPDATE_ATTR_ASSET = 'ASSET_UPDATE_ATTR_ASSET';
export const ASSET_DELETE_ATTR_ASSET = 'ASSET_DELETE_ATTR_ASSET';

export const REPORT_LOAD_ALL_REPORTS_SUCCESS = 'REPORT_LOAD_ALL_REPORTS_SUCCESS';
export const REPORT_LOAD_ALL_REPORTS_FAILED = 'REPORT_LOAD_ALL_REPORTS_FAILED';
export const REPORT_DELETE_ONE_REPORT_SUCCESS = 'REPORT_DELETE_ONE_REPORT_SUCCESS';
export const REPORT_DELETE_ONE_REPORT_FAILED = 'REPORT_DELETE_ONE_REPORT_FAILED';
export const REPORT_GET_ONE_REPORT_SUCCESS = 'REPORT_GET_ONE_REPORT_SUCCESS';
export const REPORT_GET_ONE_REPORT_FAILED = 'REPORT_GET_ONE_REPORT_FAILED';
export const REPORT_UPSERT_ONE_REPORT_SUCCESS = 'REPORT_UPSERT_ONE_REPORT_SUCCESS';
export const REPORT_UPSERT_ONE_REPORT_FAILED = 'REPORT_UPSERT_ONE_REPORT_FAILED';
export const REPORT_LOAD_ONE_REPORT = 'REPORT_LOAD_ONE_REPORT';
export const REPORT_ADD_NEW_REPORT = 'REPORT_ADD_NEW_REPORT';
export const REPORT_UPDATE_EXISTING_REPORT = 'REPORT_UPDATE_EXISTING_REPORT';

export const FENCE_LOAD_DEVICE_SUCCEED = 'FENCE_LOAD_DEVICE_SUCCEED';
export const FENCE_LOAD_DEVICE_FAILED = 'FENCE_LOAD_DEVICE_FAILED';
export const FENCE_LOAD_FENCE_SUCCEED = 'FENCE_LOAD_FENCE_SUCCEED';
export const FENCE_LOAD_FENCE_FAILED = 'FENCE_LOAD_FENCE_FAILED';
export const FENCE_UPDATE_FENCE_FAILED = 'FENCE_UPDATE_FENCE_FAILED';
export const FENCE_UPDATE_FENCE_SUCCEED = 'FENCE_UPDATE_FENCE_SUCCEED';
export const FENCE_DELETE_FENCE_SUCCEED = 'FENCE_DELETE_FENCE_SUCCEED';
export const FENCE_DELETE_FENCE_FAILED = 'FENCE_DELETE_FENCE_FAILED';
export const FENCE_TOGGLE_SUCCESS = 'FENCE_TOGGLE_SUCCESS';
export const FENCE_TOGGLE_ERROR = 'FENCE_TOGGLE_ERROR';
export const FENCE_MSG_TOGGLE_ON = 'FENCE_MSG_TOGGLE_ON';
export const FENCE_MSG_TOGGLE_OFF = 'FENCE_MSG_TOGGLE_OFF';
export const FENCE_UPDATE_FENCE_OBJ_SUCCEED = 'FENCE_UPDATE_FENCE_OBJ_SUCCEED';
export const FENCE_UPDATE_FENCE_OBJ_FAILED = 'FENCE_UPDATE_FENCE_OBJ_FAILED';

export const RULE_LOAD_DEVICE_TYPE_SUCCEED = 'RULE_LOAD_DEVICE_TYPE_SUCCEED';
export const RULE_LOAD_DEVICE_TYPE_FAILED = 'RULE_LOAD_DEVICE_TYPE_FAILED';
export const RULE_LOAD_DEVICE_SUCCEED = 'RULE_LOAD_DEVICE_SUCCEED';
export const RULE_LOAD_DEVICE_FAILED = 'RULE_LOAD_DEVICE_FAILED';
export const RULE_GET_RULE_SUCCEED = 'RULE_GET_RULE_SUCCEED';
export const RULE_GET_RULE_FAILED = 'RULE_GET_RULE_FAILED';
export const RULE_UPSERT_RULE_SUCCEED = 'RULE_UPSERT_RULE_SUCCEED';
export const RULE_UPSERT_RULE_FAILED = 'RULE_UPSERT_RULE_FAILED';
export const RULE_DELETE_RULE_SUCCEED = 'RULE_DELETE_RULE_SUCCEED';
export const RULE_DELETE_RULE_FAILED = 'RULE_DELETE_RULE_FAILED';

export const GET_ALL_GROUPS_STARTED = 'GET_ALL_GROUPS_STARTED';
export const GET_ALL_GROUPS_SUCCEED = 'GET_ALL_GROUPS_SUCCEED';
export const GET_ALL_GROUPS_FAILED = 'GET_ALL_GROUPS_FAILED';

export const ATTRI_MGMT_TOGGLE_ERROR = 'ATTRI_MGMT_TOGGLE_ERROR';
export const ATTRI_MGMT_TOGGLE_SUCCESS = 'ATTRI_MGMT_TOGGLE_SUCCESS';
export const ATTRI_MGMT_MSG_TOGGLE_OFF = 'ATTRI_MGMT_MSG_TOGGLE_OFF';
export const ATTRI_MGMT_MSG_TOGGLE_ON = 'ATTRI_MGMT_MSG_TOGGLE_ON';

export const ATTRI_MGMT_GET_ATTRIBUTE_SUCCEED = 'ATTRI_MGMT_GET_ATTRIBUTE_SUCCEED';
export const ATTRI_MGMT_GET_ATTRIBUTE_FAILED = 'ATTRI_MGMT_GET_ATTRIBUTE_FAILED';
export const ATTRI_MGMT_POST_ATTRIBUTE_SUCCEED = 'ATTRI_MGMT_POST_ATTRIBUTE_SUCCEED';
export const ATTRI_MGMT_POST_ATTRIBUTE_FAILED = 'ATTRI_MGMT_POST_ATTRIBUTE_FAILED';
export const ATTRI_MGMT_DELETE_ATTRIBUTE_SUCCEED = 'ATTRI_MGMT_DELETE_ATTRIBUTE_SUCCEED';
export const ATTRI_MGMT_DELETE_ATTRIBUTE_FAILED = 'ATTRI_MGMT_DELETE_ATTRIBUTE_FAILED';
export const ATTRI_MGMT_LOAD_DEVICETYPE_SUCCEED = 'ATTRI_MGMT_LOAD_DEVICETYPE_SUCCEED';
export const ATTRI_MGMT_LOAD_DEVICETYPE_FAILED = 'ATTRI_MGMT_LOAD_DEVICETYPE_FAILED';
export const ATTRI_MGMT_LOAD_ASSET_SUCCEED = 'ATTRI_MGMT_LOAD_ASSET_SUCCEED';
export const ATTRI_MGMT_LOAD_ASSET_FAILED = 'ATTRI_MGMT_LOAD_ASSET_FAILED';
export const ATTRI_MGMT_CLEAR_ATTRIBUTE = 'ATTRI_MGMT_CLEAR_ATTRIBUTE';

export const ATTRI_THEME_GET_THEME_LIST_SUCCEED = 'ATTRI_THEME_GET_THEME_LIST_SUCCEED';
export const ATTRI_THEME_GET_THEME_LIST_FAILED = 'ATTRI_THEME_GET_THEME_LIST_FAILED';
export const ATTRI_THEME_GET_ASSETCLASS_THEME_SUCCEED = 'ATTRI_THEME_GET_ASSETCLASS_THEME_SUCCEED';
export const ATTRI_THEME_GET_ASSETCLASS_THEME_FAILED = 'ATTRI_THEME_GET_ASSETCLASS_THEME_FAILED';
export const ATTRI_THEME_UPSERT_THEME_SUCCEED = 'ATTRI_THEME_UPSERT_THEME_SUCCEED';
export const ATTRI_THEME_UPSERT_THEME_FAILED = 'ATTRI_THEME_UPSERT_THEME_FAILED';
export const ATTRI_THEME_REMOVE_THEME_SUCCEED = 'ATTRI_THEME_REMOVE_THEME_SUCCEED';
export const ATTRI_THEME_REMOVE_THEME_FAILED = 'ATTRI_THEME_REMOVE_THEME_FAILED';
export const ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_SUCCEED = 'ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_SUCCEED';
export const ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_FAILED = 'ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_FAILED';


export const ACTION_MGMT_MSG_TOGGLE_OFF = 'ACTION_MGMT_MSG_TOGGLE_OFF';
export const ACTION_MGMT_LOAD_ASSET_SUCCEED = 'ACTION_MGMT_LOAD_ASSET_SUCCEED';
export const ACTION_MGMT_LOAD_ASSET_FAILED = 'ACTION_MGMT_LOAD_ASSET_FAILED';
export const ACTION_MGMT_LOAD_ASSET_DEVICE_SUCCEED = 'ACTION_MGMT_LOAD_ASSET_DEVICE_SUCCEED';
export const ACTION_MGMT_LOAD_ASSET_DEVICE_FAILED = 'ACTION_MGMT_LOAD_ASSET_DEVICE_FAILED';
export const ACTION_MGMT_LOAD_ACTION_SUCCEED = 'ACTION_MGMT_LOAD_ACTION_SUCCEED';
export const ACTION_MGMT_LOAD_ACTION_FAILED = 'ACTION_MGMT_LOAD_ACTION_FAILED';

export const ACTION_GET_ACTIONS_SUCCEED = 'ACTION_GET_ACTIONS_SUCCEED';
export const ACTION_GET_ACTIONS_FAILED = 'ACTION_GET_ACTIONS_FAILED';
export const ACTION_UPSERT_ACTIONS_SUCCEED = 'ACTION_UPSERT_ACTIONS_SUCCEED';
export const ACTION_UPSERT_ACTIONS_FAILED = 'ACTION_UPSERT_ACTIONS_FAILED';
export const ACTION_GET_ACTION_BY_ID_SUCCEED = 'ACTION_GET_ACTION_BY_ID_SUCCEED';
export const ACTION_GET_ACTIONS_BY_ID_FAILED = 'ACTION_GET_ACTIONS_BY_ID_FAILED';
export const ACTION_GET_TELECOM_ACTION_BY_ID_SUCCEED = 'ACTION_GET_TELECOM_ACTION_BY_ID_SUCCEED';
export const ACTION_GET_TELECOM_ACTION_BY_ID_FAILED = 'ACTION_GET_TELECOM_ACTION_BY_ID_FAILED';
export const ACTION_CLEAR_ACTIONS_BY_ID = 'ACTION_CLEAR_ACTIONS_BY_ID';
export const ACTION_GET_ACTION_RULE_SUCCEED = 'ACTION_GET_ACTION_RULE_SUCCEED';
export const ACTION_GET_ACTION_RULE_FAILED = 'ACTION_GET_ACTION_RULE_FAILED';
export const ACTION_CLEAR_ACTION_RULE = 'ACTION_CLEAR_ACTION_RULE';
export const ACTION_UPSERT_ACTION_RULE_SUCCEED = 'ACTION_UPSERT_ACTION_RULE_SUCCEED';
export const ACTION_UPSERT_ACTION_RULE_FAILED = 'ACTION_UPSERT_ACTION_RULE_FAILED';
export const ACTION_GET_ACTION_ALERT_SUCCEED = 'ACTION_GET_ACTION_ALERT_SUCCEED';
export const ACTION_GET_ACTION_ALERT_FAILED = 'ACTION_GET_ACTION_ALERT_FAILED';
export const ACTION_CLEAR_ACTION_ALERT = 'ACTION_CLEAR_ACTION_ALERT';
export const ACTION_UPSERT_ACTION_ALERT_SUCCEED = 'ACTION_UPSERT_ACTION_ALERT_SUCCEED';
export const ACTION_UPSERT_ACTION_ALERT_FAILED = 'ACTION_UPSERT_ACTION_ALERT_FAILED';
export const ACTION_SET_ACTION_ID_SUCCEED = 'ACTION_SET_ACTION_ID_SUCCEED';
export const ACTION_DELETE_ACTIONS_SUCCEED = 'ACTION_UPSERT_ACTIONS_SUCCEED';
export const ACTION_DELETE_ACTIONS_FAILED = 'ACTION_UPSERT_ACTIONS_FAILED';
export const ACTION_GET_ACTION_LOG_SUCCEED = 'ACTION_GET_ACTION_LOG_SUCCEED';
export const ACTION_GET_ACTION_LOG_FAILED = 'ACTION_GET_ACTION_LOG_FAILED';
export const ACTION_GET_TEMPLATE_SUCCEED = 'ACTION_GET_TEMPLATE_SUCCEED';
export const ACTION_GET_TEMPLATE_FAILED = 'ACTION_GET_TEMPLATE_FAILED';
export const ACTION_SET_TELECOM_SUCCEED = 'ACTION_SET_TELECOM_SUCCEED'
export const ACTION_SET_TELECOM_FAILED = 'ACTION_SET_TELECOM_FAILED'

export const ACTION_GET_CONTACT_LIST_SUCCEED = 'ACTION_GET_CONTACT_LIST_SUCCEED';
export const ACTION_GET_CONTACT_LIST_FAILED = 'ACTION_GET_CONTACT_LIST_FAILED';
export const ACTION_POST_CONTACT_LIST_SUCCEED = 'ACTION_POST_CONTACT_LIST_SUCCEED';
export const ACTION_POST_CONTACT_LIST_FAILED = 'ACTION_POST_CONTACT_LIST_FAILED';
export const ACTION_GET_NOTIFICTION_TEMPLATE_LIST_SUCCEED = 'ACTION_GET_NOTIFICTION_TEMPLATE_LIST_SUCCEED';
export const ACTION_GET_NOTIFICTION_TEMPLATE_LIST_FAILED = 'ACTION_GET_NOTIFICTION_TEMPLATE_LIST_FAILED';
export const ACTION_GET_DASHBOARDSETTING_LIST_SUCCEED = 'ACTION_GET_DASHBOARDSETTING_LIST_SUCCEED';
export const ACTION_GET_DASHBOARDSETTING_LIST_FAILED = 'ACTION_GET_DASHBOARDSETTING_LIST_FAILED';
export const ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED = 'ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED';
export const ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED = 'ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED';
export const ACTION_INVENTORY_UPLOAD_ACTIONS_FAILED = 'ACTION_INVENTORY_UPLOAD_ACTIONS_FAILED';

export const ASSET_LOAD_ICON_SUCCEED = 'ASSET_LOAD_ICON_SUCCEED';
export const ASSET_LOAD_ICON_FAILED = 'ASSET_LOAD_ICON_FAILED';
export const ASSET_DELETE_ICON = 'ASSET_DELETE_ICON';
export const CLEAR_USER_DETAIL = 'CLEAR_USER_DETAIL';
export const USER_LOGIN_SUCESS = 'USER_LOGIN_SUCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS';
export const USER_ADD_FAILED = 'USER_ADD_FAILED';
export const USER_FORGET_PASSWORD_SUCCESS = 'USER_FORGET_PASSWORD_SUCCESS';
export const USER_FORGET_PASSWORD_NOT_FOUND = 'USER_FORGET_PASSWORD_NOT_FOUND';
export const USER_FORGET_PASSWORD_NOT_ALLOWED = 'USER_FORGET_PASSWORD_NOT_ALLOWED';
export const USER_FORGET_PASSWORD_FAILED = 'USER_FORGET_PASSWORD_FAILED';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAILED = 'USER_RESET_PASSWORD_FAILED';
/////wether app adding multi users  from csv file
export const MULTI_USER_ADD_SUCCESS = 'MULTI_USER_ADD_SUCCESS';
export const MULTI_USER_ADD_FAILED = 'MULTI_USER_ADD_FAILED';
/////////////
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';
export const CHANGE_NVR_PASSWORD_SUCCESS = 'CHANGE_NVR_PASSWORD_SUCCESS';
export const CHANGE_NVR_PASSWORD_FAIL = 'CHANGE_NVR_PASSWORD_FAIL';
export const USER_REMOVE_SUCCESS = 'USER_REMOVE_SUCCESS';
export const USER_REMOVE_FAILED = 'USER_REMOVE_FAILED';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const DASHBOARDTEMPLATE_USERLIST_GET_SUCCESS = 'DASHBOARDTEMPLATE_USER_GET_SUCCESS';
export const DASHBOARDTEMPLATE_LAYOUT_GET_SUCCESS = 'DASHBOARDTEMPLATE_LAYOUT_GET_SUCCESS';
export const DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS = 'DASHBOARDTEMPLATE_LAYOUT_Delete_SUCCESS';
export const DASHBOARDTEMPLATE_DELETE_SUCCESS = 'DASHBOARDTEMPLATE_Delete_SUCCESS';
export const DASHBOARDTEMPLATE_NEW_SUCCESS = 'DASHBOARDTEMPLATE_NEW_SUCCESS';
export const DASHBOARDTEMPLATE_LAYOUT_UPDATE_SUCCESS = 'DASHBOARDTEMPLATE_LAYOUT_UPDATE_SUCCESS';
export const DASHBOARDTEMPLATE_LAYOUT_UPDATE_FAIL = 'DASHBOARDTEMPLATE_LAYOUT_UPDATE_FAIL';
export const USER_GET_FAILED = 'USER_GET_FAILED';
export const USER_ADD_ASSETCLASS_LIST_SUCCESS = 'USER_ADD_ASSETCLASS_LIST_SUCCESS';
export const USER_ADD_ASSETCLASS_LIST_FAILED = 'USER_ADD_ASSETCLASS_LIST_FAILED';
export const USER_ADD_DEVICETYPE_LIST_SUCCESS = 'USER_ADD_DEVICETYPE_LIST_SUCCESS';
export const USER_ADD_DEVICETYPE_LIST_FAILED = 'USER_ADD_DEVICETYPE_LIST_FAILED';

export const USER_CHECK_ASSETCLASS_LIST_SUCCESS = 'USER_CHECK_ASSETCLASS_LIST_SUCCESS';
export const USER_CHECK_ASSETCLASS_LIST_FAILED = 'USER_CHECK_ASSETCLASS_LIST_FAILED';
export const USER_CHECK_DEVICETYPE_LIST_SUCCESS = 'USER_CHECK_DEVICETYPE_LIST_SUCCESS';
export const USER_CHECK_DEVICETYPE_LIST_FAILED = 'USER_CHECK_DEVICETYPE_LIST_FAILED';
export const USER_GET_COMPANY_LIST_SUCCESS = 'USER_GET_COMPANY_LIST_SUCCESS';
export const USER_GET_COMPANT_LIST_FAILED = 'USER_GET_COMPANT_LIST_FAILED';

export const USER_GET_COMPANY_OBJ_SUCCESS = 'USER_GET_COMPANY_OBJ_SUCCESS';
export const USER_GET_COMPANY_OBJ_FAILED = 'USER_GET_COMPANY_OBJ_FAILED';
export const USER_UPDATE_COMPANY_OBJ_SUCCESS = 'USER_UPDATE_COMPANY_OBJ_SUCCESS';
export const USER_UPDATE_COMPANY_OBJ_FAILED = 'USER_UPDATE_COMPANY_OBJ_FAILED';
export const USER_DELETE_COMPANY_OBJ_SUCCESS = 'USER_DELETE_COMPANY_OBJ_SUCCESS';
export const USER_DELETE_COMPANY_OBJ_FAILED = 'USER_DELETE_COMPANY_OBJ_FAILED';

export const USER_UPDATE_RESELLER_OBJ_SUCCESS = 'USER_UPDATE_RESELLER_OBJ_SUCCESS';
export const USER_UPDATE_RESELLER_OBJ_FAILED = 'USER_UPDATE_RESELLER_OBJ_FAILED';
export const USER_DISABLE_RESELLER_OBJ_SUCCESS = 'USER_DISABLE_RESELLER_OBJ_SUCCESS';
export const USER_DISABLE_RESELLER_OBJ_FAILED = 'USER_DISABLE_RESELLER_OBJ_FAILED';

export const USER_GET_PAGE_CONTENT_SUCCESS = 'USER_GET_PAGE_CONTENT_SUCCESS';
export const USER_GET_PAGE_CONTENT_FAILED = 'USER_GET_PAGE_CONTENT_FAILED';

export const USER_GET_ROLES_SUCCESS = 'USER_GET_ROLES_SUCCESS';
export const USER_GET_ROLES_FAILED = 'USER_GET_ROLES_FAILED';
export const USER_GET_COMPANY_DEVICE_TYPES_SUCCESS = 'USER_GET_COMPANY_DEVICE_TYPES_SUCCESS';
export const USER_GET_COMPANY_DEVICE_TYPES_FAILED = 'USER_GET_COMPANY_DEVICE_TYPES_FAILED';
export const USER_GET_COMPANY_ASSET_CLASS_SUCCESS = 'USER_GET_COMPANY_ASSET_CLASS_SUCCESS';
export const USER_GET_COMPANY_ASSET_CLASS_FAILED = 'USER_GET_COMPANY_ASSET_CLASS_FAILED';
export const USER_GET_COUNTRY_LIST_SUCCESS = 'USER_GET_COUNTRY_LIST_SUCCESS';
export const USER_GET_PROVINCE_LIST_SUCCESS = 'USER_GET_PROVINCE_LIST_SUCCESS';
export const USER_GET_ALL_COMPANIES_SUCCESS = 'USER_GET_ALL_COMPANIES_SUCCESS';
export const USER_GET_ALL_COMPANIES_FAILED = 'USER_GET_ALL_COMPANIES_FAILED';
export const USER_GET_ALL_RESELLER_SUCCESS = 'USER_GET_ALL_RESELLER_SUCCESS';
export const USER_GET_ALL_RESELLER_FAILED = 'USER_GET_ALL_RESELLER_FAILED';

export const STEP_FORWARD = 'STEP_FORWARD';
export const STEP_BACKWARD = 'STEP_BACKWARD';
export const STET_RESET = 'STET_RESET';

export const DASHBOARD_GET_LAYOUT_SUCCEED = 'DASHBOARD_GET_LAYOUT_SUCCEED';
export const DASHBOARD_GET_LAYOUT_FAILED = 'DASHBOARD_GET_LAYOUT_FAILED';
export const DASHBOARD_GET_ASSET_MAP_SUCCEED = 'DASHBOARD_GET_ASSET_MAP_SUCCEED';
export const DASHBOARD_GET_ASSET_MAP_FAILED = 'DASHBOARD_GET_ASSET_MAP_FAILED';
export const DASHBOARD_GET_LAYOUT_LIST_SUCCEED = 'DASHBOARD_GET_LAYOUT_LIST_SUCCEED';
export const DASHBOARD_GET_LAYOUT_LIST_FAILED = 'DASHBOARD_GET_LAYOUT_LIST_FAILED';

export const DASHBOARD_GET_DEVICE_DATA_SUCCEED = 'DASHBOARD_GET_DEVICE_DATA_SUCCEED';
export const DASHBOARD_GET_DEVICE_DATA_FAILED = 'DASHBOARD_GET_DEVICE_DATA_FAILED';
export const DASHBOARD_UPDATE_LAYOUT_SUCCEED = 'DASHBOARD_UPDATE_LAYOUT_SUCCEED';
export const DASHBOARD_UPDATE_LAYOUT_FAILED = 'DASHBOARD_UPDATE_LAYOUT_FAILED';

export const DASHBOARD_GET_DEVICE_LIST_SUCCEED = 'DASHBOARD_GET_DEVICE_LIST_SUCCEED';
export const DASHBOARD_GET_DEVICE_LIST_FAILED = 'DASHBOARD_GET_DEVICE_LIST_FAILED';

export const DASHBOARD_GET_DEVICE_MSG_COUNT_SUCCEED = 'DASHBOARD_GET_DEVICE_MSG_COUNT_SUCCEED';
export const DASHBOARD_GET_DEVICE_MSG_COUNT_FAILED = 'DASHBOARD_GET_DEVICE_MSG_COUNT_FAILED';

export const DASHBOARD_GET_ASSETS_DATA_SUCCEED = 'DASHBOARD_GET_ASSETS_DATA_SUCCEED';
export const DASHBOARD_GET_ASSETS_DATA_FAILED = 'DASHBOARD_GET_ASSETS_DATA_FAILED';

export const DASHBOARD_GET_ASSETS_LIST_SUCCEED = 'DASHBOARD_GET_ASSETS_LIST_SUCCEED';
export const DASHBOARD_GET_ASSETS_LIST_FAILED = 'DASHBOARD_GET_ASSETS_LIST_FAILED';

export const DASHBOARD_GET_THRESHOLD_SUCCEED = 'DASHBOARD_GET_THRESHOLD_SUCCEED';
export const DASHBOARD_GET_THRESHOLD_FAILED = 'DASHBOARD_GET_THRESHOLD_FAILED';

export const DASHBOARD_GET_ASSETS_LIST_CLEAR = 'DASHBOARD_GET_ASSETS_LIST_CLEAR';

export const DASHBOARD_RECURSIVE_START = 'DASHBOARD_RECURSIVE_START';
export const DASHBOARD_GET_RECURSIVE_DATA_SUCCEED = 'DASHBOARD_GET_RECURSIVE_DATA_SUCCEED';
export const DASHBOARD_GET_RECURSIVE_DATA_FAILED = 'DASHBOARD_GET_RECURSIVE_DATA_FAILED';

export const DASHBOARD_GET_ATTRIBUTE_CHART_SUCCEED = 'DASHBOARD_GET_ATTRIBUTE_CHART_SUCCEED';
export const DASHBOARD_GET_ATTRIBUTE_CHART_FAILED = 'DASHBOARD_GET_ATTRIBUTE_CHART_FAILED';

export const MAP_DEVICE_DATA = 'MAP_DEVICE_DATA';
export const MAP_ASSET_DATA = 'MAP_ASSET_DATA';

export const INVENTORY_GET_DEVICE_MASTER_SUCCEED = 'INVENTORY_GET_DEVICE_MASTER_SUCCEED';
export const INVENTORY_GET_DEVICE_MASTER_FAILED = 'INVENTORY_GET_DEVICE_MASTER_FAILED';
export const INVENTORY_GET_INVENTORY_SUCCEED = 'INVENTORY_GET_INVENTORY_SUCCEED';
export const INVENTORY_GET_INVENTORY_FAILED = 'INVENTORY_GET_INVENTORY_FAILED';
export const INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_SUCCEED = 'INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_SUCCEED';
export const INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_FAILED = 'INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_FAILED';
export const INVENTORY_SET_ACTIVATE_SIM_SUCCESS = 'INVENTORY_SET_ACTIVATE_SIM_SUCCESS';
export const INVENTORY_SET_ACTIVATE_SIM_FAILED = 'INVENTORY_SET_ACTIVATE_SIM_FAILED';

export const INVENTORY_GET_ONE_DEVICE_MASTER_SUCCEED = 'INVENTORY_GET_ONE_DEVICE_MASTER_SUCCEED';
export const INVENTORY_GET_ONE_DEVICE_MASTER_FAILED = 'INVENTORY_GET_ONE_DEVICE_MASTER_FAILED';
export const INVENTORY_GET_CONSUMER_SIM_SUCCEED = 'INVENTORY_GET_CONSUMER_SIM_SUCCEED';
export const INVENTORY_GET_CONSUMER_SIM_FAILED = 'INVENTORY_GET_CONSUMER_SIM_FAILED';

export const PUBSUB_UPDATE_DEVICE = 'PUBSUB_UPDATE_DEVICE';

export const ASSET_GET_RECURSIVE_DATA_SUCCEED = 'ASSET_GET_RECURSIVE_DATA_SUCCEED';
export const ASSET_GET_RECURSIVE_DATA_FAILED = 'ASSET_GET_RECURSIVE_DATA_FAILED';
export const ASSET_RESET_RECURSIVE_DATA = 'ASSET_RESET_RECURSIVE_DATA';
export const ASSET_RECURSIVE_START = 'ASSET_RECURSIVE_START';
export const ASSET_REMOVE_RECURSIVE_LEVEL = 'ASSET_REMOVE_RECURSIVE_LEVEL';

export const MESSAGE_DECODE_LOAD_SUCCEED = 'MESSAGE_DECODE_LOAD_SUCCEED';
export const MESSAGE_DECODE_ADD_MESSAGE_DECODE = 'MESSAGE_DECODE_ADD';
export const MESSAGE_DECODE_ADD_ITEM_MESSAGE_DECODE = 'MESSAGE_DECODE_ADD_ITEM_MESSAGE_DECODE';
export const MESSAGE_DECODE_UPDATE_ONE_MESSAGE_DECODE = 'MESSAGE_DECODE_UPDATE_ONE_MESSAGE_DECODE';
export const MESSAGE_DECODE_REMOVE_MESSAGE_DECODE = 'MESSAGE_DECODE_REMOVE_MESSAGE_DECODE';

export const MESSAGE_DECODE_ADD_GROUP_MESSAGE_DECODE = 'MESSAGE_DECODE_ADD_GROUP_MESSAGE_DECODE';
export const MESSAGE_DECODE_REMOVE_GROUP_MESSAGE_DECODE = 'MESSAGE_DECODE_REMOVE_GROUP_MESSAGE_DECODE';
export const MESSAGE_DECODE_UPDATE_GROUP_MESSAGE_DECODE = 'MESSAGE_DECODE_UPDATE_GROUP_MESSAGE_DECODE';


export const MESSAGE_DECODE_LOAD_LIST_START = 'MESSAGE_DECODE_LOAD_LIST_START';
export const MESSAGE_DECODE_LOAD_LIST_SUCCEED = 'MESSAGE_DECODE_LOAD_LIST_SUCCEED';
export const MESSAGE_DECODE_LOAD_LIST_FAILED = 'MESSAGE_DECODE_LOAD_LIST_FAILED';

export const MESSAGE_DECODE_LOAD_GROUP_START = 'MESSAGE_DECODE_LOAD_GROUP_START';
export const MESSAGE_DECODE_LOAD_GROUP_SUCCEED = 'MESSAGE_DECODE_LOAD_GROUP_SUCCEED';
export const MESSAGE_DECODE_LOAD_GROUP_FAILED = 'MESSAGE_DECODE_LOAD_GROUP_FAILED';



export const COMPANY_GET_BRANDING_SUCCESS='COMPANY_GET_BRANDING_SUCCESS';
export const COMPANY_GET_BRANDING_FAILED='COMPANY_GET_BRANDING_FAILED';

export const USER_GET_ACCOUNT_LIST_SUCCESS = 'USER_GET_ACCOUNT_LIST_SUCCESS';
export const USER_GET_ACCOUNT_LIST_FAILED = 'USER_GET_ACCOUNT_LIST_FAILED';
export const CARD_ADD_SUCCESS = 'CARD_ADD_SUCCESS';
export const CARD_ADD_FAILED = 'CARD_ADD_FAILED';
export const ACCOUNT_ADD_ACCOUNT = 'ACCOUNT_ADD_ACCOUNT';
export const ACCOUNT_DELETE_ACCOUNT = 'ACCOUNT_DELETE_ACCOUNT';
export const GET_ACCOUNT_CARDS_SUCCESS = 'GET_ACCOUNT_CARDS_SUCCESS';
export const GET_ACCOUNT_CARDS_FAILED = 'GET_ACCOUNT_CARDS_FAILED';

export const USER_GET_PLAN_ITEMS_SUCCESS = 'USER_GET_PLAN_ITEMS_SUCCESS';
export const USER_GET_PLAN_ITEMS_FAILED = 'USER_GET_PLAN_ITEMS_FAILED';

export const USER_GET_SIM_SERVICE_PLAN_SUCCESS = "USER_GET_SIM_SERVICE_PLAN_SUCCESS";
export const USER_GET_SIM_SERVICE_PLAN_FAILED = "USER_GET_SIM_SERVICE_PLAN_FAILED";

export const WEB_SERVICE_GET_SPECIAL_LINE_SUCCESS = "WEB_SERVICE_GET_SPECIAL_LINE_SUCCESS";
export const WEB_SERVICE_GET_SPECIAL_LINE_FAILED = "WEB_SERVICE_GET_SPECIAL_LINE_FAILED"

export const USER_CLEAR_CARRIER_PLAN_LIST_SUCCESS = "USER_CLEAR_CARRIER_PLAN_LIST_SUCCESS";
export const USER_CLEAR_CARRIER_PLAN_LIST_FAILED = "USER_CLEAR_CARRIER_PLAN_LIST_FAILED";

export const COMPANY_GET_PAYMENT_ITEM_SUCCESS = 'COMPANY_GET_PAYMENT_ITEM_SUCCESS';
export const COMPANY_GET_PAYMENT_ITEM_FAILED = 'COMPANY_GET_PAYMENT_ITEM_FAILED';

export const COMPANY_PAYMENT_CHECKOUT_SUCCESS = 'COMPANY_PAYMENT_CHECKOUT_SUCCESS';
export const COMPANY_PAYMENT_CHECKOUT_FAILED = 'COMPANY_PAYMENT_CHECKOUT_FAILED';

export const COMPANY_TRANSACTION_HISTORY_SUCCESS = 'COMPANY_TRANSACTION_HISTORY_SUCCESS';
export const COMPANY_TRANSACTION_HISTORY_FAILED = 'COMPANY_TRANSACTION_HISTORY_FAILED';
export const COMPANY_TRANSACTION_REFUND_SUCCESS = 'COMPANY_TRANSACTION_REFUND_SUCCESS';
export const COMPANY_TRANSACTION_REFUND_FAILED = 'COMPANY_TRANSACTION_REFUND_FAILED';

export const COMPANY_PAYMENT_HISTORY_SUCCESS = 'COMPANY_PAYMENT_HISTORY_SUCCESS';
export const COMPANY_PAYMENT_HISTORY_FAILED = 'COMPANY_PAYMENT_HISTORY_FAILED';
export const COMPANY_PAYMENT_INVOICE_FAILED = 'COMPANY_PAYMENT_INVOICE_FAILED';
export const COMPANY_PAYMENT_INVOICE_SUCCESS = 'COMPANY_PAYMENT_INVOICE_SUCCESS';
export const COMPANY_PAYMENT_INVOICE_CLEAR = 'COMPANY_PAYMENT_INVOICE_CLEAR';
export const COMPANY_PAYMENT_INVOICE_DETAILS_SUCCESS = 'COMPANY_PAYMENT_INVOICE_DETAILS_SUCCESS';
export const COMPANY_PAYMENT_INVOICE_DETAILS_FAILED = 'COMPANY_PAYMENT_INVOICE_DETAILS_FAILED';
export const COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_SUCCESS = 'COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_FAILED = 'COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_FAILED'
export const COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS = 'COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED = 'COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED'
export const COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS = 'COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED = 'COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED'

export const COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_SUCCESS = 'COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_FAILED = 'COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_FAILED'
export const COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS = 'COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED = 'COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED'
export const COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS = 'COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED = 'COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED'


export const COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS = 'COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED = 'COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED'
export const COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS = 'COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED = 'COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED'
export const COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS = 'COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED = 'COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED'

export const COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_SUCCESS = 'COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_FAILED = 'COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_FAILED'
export const COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_SUCCESS = 'COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_FAILED = 'COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_FAILED'

export const COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS = 'COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED = 'COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED'

export const COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_SUCCESS = 'COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_FAILED = 'COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_FAILED'

export const COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_START = 'COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_START'
export const COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_SUCCESS = 'COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_SUCCESS'
export const COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_FAILED = 'COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_FAILED'

export const COMPANY_PAYMENT_USAGE_REPORT_SUCCESS = 'COMPANY_PAYMENT_USAGE_REPORT_SUCCESS';
export const COMPANY_PAYMENT_USAGE_REPORT_FAILED = 'COMPANY_PAYMENT_USAGE_REPORT_FAILED';

export const COMPANY_PAYMENT_ADJUST_INVOICE_SUCCESS = 'COMPANY_PAYMENT_ADJUST_INVOICE_SUCCESS';
export const COMPANY_PAYMENT_ADJUST_INVOICE_FAILED = 'COMPANY_PAYMENT_ADJUST_INVOICE_FAILED';

export const WEB_SERVICE_INVENTORY_SUPLLIER_SUCCESS = 'WEB_SERVICE_INVENTORY_SUPLLIER_SUCCESS';
export const WEB_SERVICE_INVENTORY_SUPLLIER_ITEM_SUCCESS = 'WEB_SERVICE_INVENTORY_SUPLLIER_ITEM_SUCCESS';

export const COMPANY_MONERIS_ACCOUNT_START = 'COMPANY_MONERIS_ACCOUNT_START';
export const COMPANY_MONERIS_ACCOUNT_SUCCESS = 'WEB_SERVICE_MONERIS_ACCOUNT_SUCCESS';
export const COMPANY_MONERIS_ACCOUNT_FAILED = 'WEB_SERVICE_MONERIS_ACCOUNT_FAILED';
export const COMPANY_SET_CURRENT_COMPANY_CODE = 'COMPANY_SET_CURRENT_COMPANY_CODE';

export const CUSTOMER_COMPANY_GET_SUCCESS = 'CUSTOMER_COMPANY_GET_SUCCESS';
export const CUSTOMER_COMPANY_GET_FAILED = 'CUSTOMER_COMPANY_GET_FAILED';

export const CUSTOMER_GET_ORDERS_SUCCESS = 'CUSTOMER_GET_ORDERS_SUCCESS';
export const CUSTOMER_GET_ORDERS_FAILED = 'CUSTOMER_GET_ORDERS_FAILED';

export const CUSTOMER_UPDATE_ORDERS_SUCCESS = 'CUSTOMER_UPDATE_ORDERS_SUCCESS';
export const CUSTOMER_UPDATE_ORDERS_FAILED = 'CUSTOMER_UPDATE_ORDERS_FAILED';

export const ORDER_ADJUSTMENT_GET_SUCCESS = 'ORDER_ADJUSTMENT_GET_SUCCESS';
export const ORDER_ADJUSTMENT_GET_FAILED = 'ORDER_ADJUSTMENT_GET_FAILED';
export const ORDER_ADJUSTMENT_CLEAR = 'ORDER_ADJUSTMENT_CLEAR';

export const GENERATE_PURCHASE_ORDER_SUCCESS = 'GENERATE_PURCHASE_ORDER_SUCCESS';
export const GENERATE_PURCHASE_ORDER_FAILED = 'GENERATE_PURCHASE_ORDER_FAILED';
export const EMAIL_PURCHASE_ORDER_SUCCESS = 'EMAIL_PURCHASE_ORDER_SUCCESS';
export const EMAIL_PURCHASE_ORDER_FAILED = 'EMAIL_PURCHASE_ORDER_FAILED';


//Smart Building
export const surveilActionTypes = {
  ADD_ASSET: 'ADD_ASSET',
  LOAD_ASSETS: 'LOAD_ASSETS',
  CLEAR_ASSETS: 'CLEAR_ASSETS',
  LOAD_LPR_SUCCEED: 'LOAD_LPR_SUCCEED', //load license plates records
  LOAD_LPR_FAILED: 'LOAD_LPR_FAILED', //load license plates records
  LOAD_FACE_RECOGNITION_SUCCEED: 'LOAD_FACE_RECOGNITION_SUCCEED',
  LOAD_FACE_RECOGNITION_FAILED: 'LOAD_FACE_RECOGNITION_FAILED',
  LOAD_FACE_DETECTION_SUCCEED: 'LOAD_FACE_DETECTION_SUCCEED',
  LOAD_FACE_DETECTION_FAILED: 'LOAD_FACE_DETECTION_FAILED',
  LOAD_PEOPLE_COUNT_SUCCEED: 'LOAD_PEOPLE_COUNT_SUCCEED',
  LOAD_PEOPLE_COUNT_FAILED: 'LOAD_PEOPLE_COUNT_FAILED',
  LOAD_CAR_COUNT_SUCCEED: 'LOAD_CAR_COUNT_SUCCEED',
  LOAD_CAR_COUNT_FAILED: 'LOAD_CAR_COUNT_FAILED',
  LOAD_CAMERA_RESTRICTIONS_SUCCEED: 'LOAD_CAMERA_RESTRICTIONS_SUCCEED',
  LOAD_CAMERA_RESTRICTIONS_FAILED: 'LOAD_CAMERA_RESTRICTIONS_FAILED',
  UPDATE_CAMERA_RESTRICTIONS_SUCCEED: 'UPDATE_CAMERA_RESTRICTIONS_SUCCEED',
  UPDATE_CAMERA_RESTRICTIONS_FAILED: 'UPDATE_CAMERA_RESTRICTIONS_FAILED',
  LOAD_NVR_LIST_SUCCEED: 'LOAD_NVR_LIST_SUCCEED',
  LOAD_NVR_LIST_FAILED: 'LOAD_NVR_LIST_FAILED',
  ADD_NVR_SUCCEED: 'ADD_NVR_SUCCEED',
  ADD_NVR_FAILED: 'ADD_NVR_FAILED',
  LOAD_CAMERA_GROUPS_REQUEST: 'LOAD_CAMERA_GROUPS_REQUEST',
  LOAD_CAMERA_GROUPS_SUCCEED: 'LOAD_CAMERA_GROUPS_SUCCEED',
  LOAD_CAMERA_GROUPS_FAILED: 'LOAD_CAMERA_GROUPS_FAILED',
};

export const WEB_SERVICE_GET_SAP_CSV_SUCCESS = 'WEB_SERVICE_GET_SAP_CSV_SUCCESS';
export const WEB_SERVICE_GET_SAP_CSV_FAILED = 'WEB_SERVICE_GET_SAP_CSV_FAILED';

export const GET_ACTIVATED_SIM_DASHBOARD_START = 'GET_ACTIVATED_SIM_DASHBOARD_START';
export const GET_ACTIVATED_SIM_DASHBOARD_SUCCESS = 'GET_ACTIVATED_SIM_DASHBOARD_SUCCESS';
export const GET_ACTIVATED_SIM_DASHBOARD_FAILED = 'GET_ACTIVATED_SIM_DASHBOARD_FAILED';

export const GET_SIM_USAGE_SUCCESS = 'GET_SIM_USAGE_SUCCESS';
export const GET_SIM_USAGE_FAILED = 'GET_SIM_USAGE_FAILED';

export const GET_USAGE_FOR_RESELLER_SUCCESS = 'GET_USAGE_FOR_RESELLER_SUCCESS';
export const GET_USAGE_FOR_RESELLER_FAILED = 'GET_USAGE_FOR_RESELLER_FAILED';


export const GET_CARRIER_LIST_SUCCESS = "GET_CARRIER_LIST_SUCCESS";
export const GET_CARRIER_LIST_FAILED = "GET_CARRIER_LIST_FAILED";

export const CONSUMER_ADD_SUCCESS = 'CONSUMER_ADD_SUCCESS';
export const CONSUMER_ADD_FAILED = 'CONSUMER_ADD_FAILED';

export const CONSUMER_GET_SUCCESS = 'CONSUMER_GET_SUCCESS';
export const CONSUMER_GET_FAILED = 'CONSUMER_GET_FAILED';

export const CONSUMER_UPDATE_SUCCESS = 'CONSUMER_UPDATE_SUCCESS';
export const CONSUMER_UPDATE_FAILED = 'CONSUMER_UPDATE_FAILED';

export const CONSUMER_GET_PLAN_USAGE_SUCCESS = 'CONSUMER_GET_PLAN_USAGE_SUCCESS';
export const CONSUMER_GET_PLAN_USAGE_FAILED = 'CONSUMER_GET_PLAN_USAGE_FAILED';

//Weather App
export const WEATHER_GET_ASSET_DATA_SUCCEED = 'WEATHER_GET_ASSET_DATA_SUCCEED';
export const WEATHER_GET_ASSET_DATA_FAILED = 'WEATHER_GET_ASSET_DATA_FAILED';
export const WEATHER_GET_ASSET_DATA_CLEAR = 'WEATHER_GET_ASSET_DATA_CLEAR';
export const WEATHER_SET_STORE_ASSET_DATA = 'WEATHER_SET_STORE_ASSET_DATA';

export const WEATHER_GET_ALARM_COUNT_START = 'WEATHER_GET_ALARM_COUNT_START';
export const WEATHER_GET_ALARM_COUNT_SUCCEED = 'WEATHER_GET_ALARM_COUNT_SUCCEED';
export const WEATHER_GET_ALARM_COUNT_FAILED = 'WEATHER_GET_ALARM_COUNT_FAILED';
export const WEATHER_GET_ALARM_COUNT_CLEAR = 'WEATHER_GET_ALARM_COUNT_CLEAR';
export const WEATHER_GET_ALARM_COUNT_LIST_START = 'WEATHER_GET_ALARM_COUNT_LIST_START';
export const WEATHER_GET_ALARM_COUNT_LIST_SUCCEED = 'WEATHER_GET_ALARM_COUNT_LIST_SUCCEED';
export const WEATHER_GET_ALARM_COUNT_LIST_FAILED = 'WEATHER_GET_ALARM_COUNT_LIST_FAILED';
export const WEATHER_GET_ALARM_COUNT_LIST_CLEAR = 'WEATHER_GET_ALARM_COUNT_LIST_CLEAR';
export const WEATHER_GET_HOURLYALARM_COUNT_START = 'WEATHER_GET_HOURLYALARM_COUNT_START';
export const WEATHER_GET_HOURLYALARM_COUNT_SUCCEED = 'WEATHER_GET_HOURLYALARM_COUNT_SUCCEED';
export const WEATHER_GET_HOURLYALARM_COUNT_FAILED = 'WEATHER_GET_HOURLYALARM_COUNT_FAILED';
export const WEATHER_GET_HOURLYALARM_COUNT_CLEAR = 'WEATHER_GET_HOURLYALARM_COUNT_CLEAR';
export const WEATHER_GET_MONTHLYALARM_COUNT_START = 'WEATHER_GET_MONTHLYALARM_COUNT_START';
export const WEATHER_GET_MONTHLYALARM_COUNT_SUCCEED = 'WEATHER_GET_MONTHLYALARM_COUNT_SUCCEED';
export const WEATHER_GET_MONTHLYALARM_COUNT_FAILED = 'WEATHER_GET_MONTHLYALARM_COUNT_FAILED';
export const WEATHER_GET_MONTHLYALARM_COUNT_CLEAR = 'WEATHER_GET_MONTHLYALARM_COUNT_CLEAR';
export const WEATHER_GET_QUARTERMONTHALARM_COUNT_START = 'WEATHER_GET_QUARTERMONTHALARM_COUNT_START';
export const WEATHER_GET_QUARTERMONTHALARM_COUNT_SUCCEED = 'WEATHER_GET_QUARTERMONTHALARM_COUNT_SUCCEED';
export const WEATHER_GET_QUARTERMONTHALARM_COUNT_FAILED = 'WEATHER_GET_QUARTERMONTHALARM_COUNT_FAILED';
export const WEATHER_GET_QUARTERMONTHALARM_COUNT_CLEAR = 'WEATHER_GET_QUARTERMONTHALARM_COUNT_CLEAR';
export const WEATHER_GET_6MONTHSALARM_COUNT_START = 'WEATHER_GET_6MONTHSALARM_COUNT_START';
export const WEATHER_GET_6MONTHSALARM_COUNT_SUCCEED = 'WEATHER_GET_6MONTHSALARM_COUNT_SUCCEED';
export const WEATHER_GET_6MONTHSALARM_COUNT_FAILED = 'WEATHER_GET_6MONTHSALARM_COUNT_FAILED';
export const WEATHER_GET_6MONTHSALARM_COUNT_CLEAR = 'WEATHER_GET_6MONTHSALARM_COUNT_CLEAR';

export const WEATHER_GET_24HYALARM_COUNT_START = 'WEATHER_GET_24HYALARM_COUNT_START';
export const WEATHER_GET_24HALARM_COUNT_SUCCEED = 'WEATHER_GET_24HALARM_COUNT_SUCCEED';
export const WEATHER_GET_24HALARM_COUNT_FAILED = 'WEATHER_GET_24HALARM_COUNT_FAILED';
export const WEATHER_GET_24HALARM_COUNT_CLEAR = 'WEATHER_GET_24HALARM_COUNT_CLEAR';

export const WEATHER_GET_ROLLING24HALARM_COUNT_START = 'WEATHER_GET_ROLLING24HALARM_COUNT_START';
export const WEATHER_GET_ROLLING24HALARM_COUNT_SUCCEED = 'WEATHER_GET_ROLLING24HALARM_COUNT_SUCCEED';
export const WEATHER_GET_ROLLING24HALARM_COUNT_FAILED = 'WEATHER_GET_ROLLING24HALARM_COUNT_FAILED';
export const WEATHER_GET_ROLLING24HALARM_COUNT_CLEAR = 'WEATHER_GET_ROLLING24HALARM_COUNT_CLEAR';


export const WEATHER_GET_ASSET_HISTORY_START = 'WEATHER_GET_ASSET_HISTORY_START';
export const WEATHER_GET_ASSET_HISTORY_SUCCEED = 'WEATHER_GET_ASSET_HISTORY_SUCCEED';
export const WEATHER_GET_ASSET_HISTORY_FAILED = 'WEATHER_GET_ASSET_HISTORY_FAILED';
export const WEATHER_GET_ASSET_HISTORY_CLEAR = 'WEATHER_GET_ASSET_HISTORY_CLEAR';
export const WEATHER_SET_STORE_ASSET_HISTORY_DATA = 'WEATHER_SET_STORE_ASSET_HISTORY_DATA';
export const WEATHER_SUB_DATA_UPDATED = 'WEATHER_SUB_DATA_UPDATED';
export const WEATHER_GET_ALL_CONFIG_SUCCEED = 'WEATHER_GET_ALL_CONFIG_SUCCEED';
export const WEATHER_GET_ALL_CONFIG_FAILED = 'WEATHER_GET_ALL_CONFIG_FAILED';
export const WEATHER_POST_CONFIG_SUCCEED = 'WEATHER_POST_CONFIG_SUCCEED';
export const WEATHER_POST_CONFIG_FAILED = 'WEATHER_POST_CONFIG_FAILED';

export const WEATHER_GET_24_HISTORY_SUCCEED = 'WEATHER_GET_24_HISTORY_SUCCEED';
export const WEATHER_GET_24_HISTORY_FAILED = 'WEATHER_GET_24_HISTORY_FAILED';
export const WEATHER_GET_24_HISTORY_CLEAR = 'WEATHER_GET_24_HISTORY_CLEAR';

export const WEATHER_GET_1_MONTH_HISTORY_SUCCEED = 'WEATHER_GET_1_MONTH_HISTORY_SUCCEED';
export const WEATHER_GET_1_MONTH_HISTORY_FAILED = 'WEATHER_GET_1_MONTH_HISTORY_FAILED';

export const WEATHER_UPDATE_24_HISTORY = 'WEATHER_UPDATE_24_HISTORY';
export const WEATHER_UPDATE_ASSET_HISTORY_SUCCEED = 'WEATHER_UPDATE_ASSET_HISTORY_SUCCEED';
export const WEATHER_UPDATE_24_HISTORY_SUCCEED = 'WEATHER_UPDATE_24_HISTORY_SUCCEED';

export const WEATHER_GET_ASSET_DATA_START = 'WEATHER_GET_ASSET_DATA_START';


export const GET_PROMOTE_SUCCESS = 'GET_PROMOTE_SUCCESS'
export const GET_PROMOTE_FAILED = 'GET_PROMOTE_FAILED';

export const SET_PROMOTE_SUCCESS = 'SET_PROMOTE_SUCCESS'
export const SET_PROMOTE_FAILED = 'SET_PROMOTE_FAILED';

export const USER_GET_CARRIER_LIST_SUCCESS = 'USER_GET_CARRIER_LIST_SUCCESS';
export const USER_GET_CARRIER_LIST_FAILED = 'USER_GET_CARRIER_LIST_FAILED';

export const USER_GET_RESELLER_LIST_SUCCESS = 'USER_GET_RESELLER_LIST_SUCCESS';
export const USER_GET_RESELLER_LIST_FAILED = 'USER_GET_RESELLER_LIST_FAILED';

export const NEW_RESELLER_GET_DEVICE_INVENTORY_SUCCEED = 'NEW_RESELLER_GET_DEVICE_INVENTORY_SUCCEED';
export const NEW_RESELLER_GET_DEVICE_INVENTORY_FAILED = 'NEW_RESELLER_GET_DEVICE_INVENTORY_FAILED';

export const RESELLER_SET_ITEM_SUCCEED = 'RESELLER_SET_ITEM_SUCCEED';
export const RESELLER_SET_ITEM_FAILED = 'RESELLER_SET_ITEM_FAILED';

export const RESELLER_SET_SPECIAL_LINE_SUCCEED = 'RESELLER_SET_ITEM_SUCCEED';
export const RESELLER_SET_SPECIAL_LINE_FAILED = 'RESELLER_SET_ITEM_FAILED';


export const ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_SUCCEED = 'ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_SUCCEED';
export const ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_FAILED = 'ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_FAILED';

export const CARRIER_GET_PLANS_SUCCESS = "CARRIER_GET_PLANS_SUCCESS";
export const CARRIER_GET_PLANS_FAILED = "CARRIER_GET_PLANS_FAILED";
export const CARRIER_UPDATE_INFO_SUCCESS = "CARRIER_UPDATE_INFO_SUCCESS";
export const CARRIER_UPDATE_INFO_FAILED = "CARRIER_UPDATE_INFO_FAILED";

export const COMPANY_GET_ACTIVITY_REPORT_SUCCESS = "COMPANY_GET_ACTIVITY_REPORT_SUCCESS";
export const COMPANY_GET_ACTIVITY_REPORT_FAILED = "COMPANY_GET_ACTIVITY_REPORT_FAILED";

export const RESELLER_GET_SERVICE_INVENTORY_SUCCEED = 'RESELLER_GET_SERVICE_INVENTORY_SUCCEED';
export const RESELLER_GET_SERVICE_INVENTORY_FAILED = 'RESELLER_GET_SERVICE_INVENTORY_FAILED';

export const CLIENT_GET_SUCCESS = 'CLIENT_GET_SUCCESS';
export const CLIENT_GET_FAILED = 'CLIENT_GET_FAILED';

export const CLIENT_PAYMENT_CHECKOUT_SUCCESS = 'CLIENT_PAYMENT_CHECKOUT_SUCCESS';
export const CLIENT_PAYMENT_CHECKOUT_FAILED = 'CLIENT_PAYMENT_CHECKOUT_FAILED';

export const PAYMENT_ORDER_GET_ADDRESS_SUCCESS = 'PAYMENT_ORDER_GET_ADDRESS_SUCCESS';
export const PAYMENT_ORDER_GET_ADDRESS_FAILED = 'PAYMENT_ORDER_GET_ADDRESS_FAILED';


export const PAYMENT_ORDER_SET_ADDRESS_SUCCESS = 'PAYMENT_ORDER_SET_ADDRESS_SUCCESS';
export const PAYMENT_ORDER_SET_ADDRESS_FAILED = 'PAYMENT_ORDER_SET_ADDRESS_FAILED';

export const GET_PLAN_USAGE_DATA_START ='GET_PLAN_USAGE_DATA_START';
export const GET_PLAN_USAGE_DATA_SUCCESS ='GET_PLAN_USAGE_DATA_SUCCESS';
export const GET_PLAN_USAGE_DATA_FAILED = 'GET_PLAN_USAGE_DATA_FAILED';
export const SIM_SET_NAME_SUCCESS = 'SIM_SET_NAME_SUCCESS';
export const SIM_SET_NAME_FAILED = 'SIM_SET_NAME_FAILED';


export const GET_IDENTIFIER_USAGE_DATA_START ='GET_IDENTIFIER_USAGE_DATA_START';
export const GET_IDENTIFIER_USAGE_DATA_SUCCESS ='GET_IDENTIFIER_USAGE_DATA_SUCCESS';
export const GET_IDENTIFIER_USAGE_DATA_FAILED = 'GET_IDENTIFIER_USAGE_DATA_FAILED';

export const USER_GET_PAYMENT_ACCOUNT_LIST_SUCCESS = 'USER_GET_PAYMENT_ACCOUNT_LIST_SUCCESS';
export const USER_GET_PAYMENT_ACCOUNT_LIST_FAILED = 'USER_GET_PAYMENT_ACCOUNT_LIST_FAILED';

export const USER_UPDATE_PAYMENT_ACCOUNT_SUCCESS = 'USER_UPDATE_PAYMENT_ACCOUNT_SUCCESS';
export const USER_UPDATE_PAYMENT_ACCOUNT_FAILED = 'USER_UPDATE_PAYMENT_ACCOUNT_FAILED';

export const CLIENT_GET_BUSINESS_UNITS_SUCCESS = 'CLIENT_GET_BUSINESS_UNITS_SUCCESS';
export const CLIENT_GET_BUSINESS_UNITS_FAILED = 'CLIENT_GET_BUSINESS_UNITS_FAILED';

export const CLIENT_GET_BUSINESS_UNIT_RELATION_SUCCESS = 'CLIENT_GET_BUSINESS_UNIT_RELATION_SUCCESS';
export const CLIENT_GET_BUSINESS_UNIT_RELATION_FAILED = 'CLIENT_GET_BUSINESS_UNIT_RELATION_FAILED';

export const PAYMENT_ORDER_SET_DEFAULT_PAYMENT_SUCCESS = 'PAYMENT_ORDER_SET_DEFAULT_PAYMENT_SUCCESS';
export const PAYMENT_ORDER_SET_DEFAULT_PAYMENT_FAILED = 'PAYMENT_ORDER_SET_DEFAULT_PAYMENT_FAILED';

export const GET_TOTAL_USAGE_START = 'GET_TOTAL_USAGE_START';
export const GET_TOTAL_USAGE_SUCCESS = 'GET_TOTAL_USAGE_SUCCESS';
export const GET_TOTAL_USAGE_FAILE = 'GET_TOTAL_USAGE_FAILE';

export const CLEAR_CLIENT_DATA = "CLEAR_CLIENT_DATA";

export const GET_CALCULATE_ATTRIBUTE_SUCCESS= "GET_CALCULATE_ATTRIBUTE_SUCCESS";
export const GET_CALCULATE_ATTRIBUTE_FAILED= "GET_CALCULATE_ATTRIBUTE_FAILED";


export const GET_CALCULATE_RESULT_SUCCESS= "GET_CALCULATE_RESULT_SUCCESS";
export const GET_CALCULATE_RESULT_FAILED= "GET_CALCULATE_RESULT_FAILED";

export const GET_LATEST_CALCULATE_RESULT_SUCCESS= "GET_LATEST_CALCULATE_RESULT_SUCCESS";
export const GET_LATEST_CALCULATE_RESULT_FAILED= "GET_LATEST_CALCULATE_RESULT_FAILED";

export const POWER_GET_ASSET_DATA_START = "POWER_GET_ASSET_DATA_START";
export const POWER_GET_ASSET_DATA_SUCCEED = "POWER_GET_ASSET_DATA_SUCCESS";
export const POWER_GET_ASSET_DATA_FAILED = "POWER_GET_ASSET_DATA_FAILED";
export const POWER_GET_ASSET_DATA_CLEAR = "POWER_GET_ASSET_DATA_CLEAR";

export const POWER_GET_ASSET_HISTORY_START = 'POWER_GET_ASSET_HISTORY_START';
export const POWER_GET_ASSET_HISTORY_SUCCEED = 'POWER_GET_ASSET_HISTORY_SUCCEED';
export const POWER_GET_ASSET_HISTORY_FAILED = 'POWER_GET_ASSET_HISTORY_FAILED';
export const POWER_GET_ASSET_HISTORY_CLEAR = 'POWER_GET_ASSET_HISTORY_CLEAR';

export const POWER_SET_STORE_ASSET_DATA = "POWER_SET_STORE_ASSET_DATA";

export const GET_DEVICE_LIST_SUCCESS = "GET_DEVICE_LIST_SUCCESS";
export const GET_DEVICE_LIST_FAILED = "GET_DEVICE_LIST_FAILED";
export const CHANGE_DEVICE_STATUS_SUCCESS = "CHANGE_DEVICE_STATUS_SUCCESS";
export const CHANGE_DEVICE_STATUS_FAILED = "CHANGE_DEVICE_STATUS_FAILED";
export const REGISTER_DEVICE_SUCCESS = "REGISTER_DEVICE_SUCCESS";
export const REGISTER_DEVICE_FAILED = "REGISTER_DEVICE_FAILED";
export const CONTACT_US_EMAIL_SEND_SUCCEED = 'CONTACT_US_EMAIL_SEND_SUCCEED';
export const CONTACT_US_EMAIL_SEND_FAILED = 'CONTACT_US_EMAIL_SEND_FAILED';

export const GET_USER_ACTIVITY_TRACKING_SUCCEED = "GET_USER_ACTIVITY_TRACKING_SUCCEED";
export const GET_USER_ACTIVITY_TRACKING_FAILED = "GET_USER_ACTIVITY_TRACKING_FAILED";
