/* eslint-disable */
import React, {useState, useEffect} from 'react'
import { Modal, ModalHeader, ModalBody, Button, CardBody, Card, ListGroup, ListGroupItem, CardTitle, Row, Col, Form, FormGroup, InputGroup, Input, Label, CardHeader, Table, Dropdown,DropdownMenu, DropdownItem, DropdownToggle, Nav, NavLink } from 'reactstrap';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {getCurrentUser} from '../../../factories/auth';
import ConfirmDeleteDialog from '../../Asset/components/ConfirmDeleteDialog';
import PermissionGroupFunctionAccessConfig from './PermissionGroupFunctionAccessConfig';
import SelectNVRs from './SelectNVRs';
const PermissionGroupPanel= ({permission, handlePermissionGroupAdd, handlePermissionGroupEdit, handlePermissionGroupDelete, isEdit, nvrList, permissionModalOpen, togglePermissionModal}) => {
    const [permissionName, setPermissionName] = useState('')
    const [viewOnlyMode, setViewOnlyMode] = useState(false)
    const [permissionId, setPermissionId] = useState(null)
    const [localCameraMode, setLocalCameraMode] = useState(true)
    const [fullCamAccess, setFullCamAccess] = useState(false)
    const [addToAllNVRs, setAddToAllNVRs] = useState(false)
    //permission settings
    const [functionAccessConfig, setFunctionAccessConfig] = useState(null)  
    //camera access
    const [devicePermission, setDevicePermission] = useState(null)
    //delete dialogue
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    useEffect(() => {
        //set the state based on permission settings
        //set function access config
        if(permission){
            setPermissionId(permission.id)
            setPermissionName(permission.name)
            if(permission.devicePermission && permission.devicePermission.length > 0){
                setDevicePermission([...permission.devicePermission])
            }
            handleAccessConfigChange({
                localCamera: permission.localCamera,
                remoteCamera: permission.remoteCamera,
                disk: permission.disk,
                aiEvent: permission.aiEvent,
                network: permission.network,
                schedule: permission.schedule,
                record: permission.record,
                localSystem: permission.localSystem,
                remoteSystem: permission.remoteSystem,
                sampleDatabase: permission.sampleDatabase,
                accountAndAuthority: permission.accountAndAuthority,
                parkingLotManagement: permission.parkingLotManagement,
                accessControlManagement: permission.accessControlManagement,
                remoteLogin: permission.remoteLogin,
                audio: permission.audio
            })
        }else{ //add mode
            setPermissionId(null)
            setPermissionName('')
            handleAccessConfigChange({
                localCamera: false,
                remoteCamera: false,
                disk: false,
                aiEvent: false,
                network: false,
                schedule: false,
                record: false,
                localSystem: false,
                remoteSystem: false,
                sampleDatabase: false,
                accountAndAuthority: false,
                parkingLotManagement: false,
                accessControlManagement: false,
                remoteLogin: false,
                audio: false
            })
        }
    }, [permission, isEdit])

    const handleAccessConfigChange = (configData) => {
        setFunctionAccessConfig(configData)
    }

    useEffect(() => {
        // console.log('user', user)
        const user = getCurrentUser();
        setViewOnlyMode(user?.userName !== 'admin')
    }, [])


    const handleChangePermission = (field, value, index, isSelectAll) => {
        let tempDevicePermission = [...devicePermission]
        if(isSelectAll){
            tempDevicePermission.map(p=>{
                if(value === "OFF"){
                    p.auth = p.auth.filter(p=>p!==field)
                }else{
                    if(!p.auth.some(p=>p===field)) p.auth.push(field)
                }
            })
        }else{
            if(tempDevicePermission[index].auth){
                let indexOfField = tempDevicePermission[index].auth.findIndex(p=>p===field)
                if(value === "OFF"){
                    tempDevicePermission[index].auth = tempDevicePermission[index].auth.filter(p=>p!==field)
                }else{
                    if(indexOfField < 0) tempDevicePermission[index].auth.push(field)
                }
            } 
        }
        setDevicePermission(tempDevicePermission)
        //change the permission settings
        //dispatch action to update the permission settings
    }

    const renderChangeDevicePermission = (isOn, field, index, isSelectAll) => {
        //return a dropdown with ON and OFF options
        return (
            <DevicePermissionDropdown 
                field={field} 
                viewOnly={viewOnlyMode} 
                handleChange={handleChangePermission} 
                isOn={isOn} 
                index={index} 
                isSelectAll={isSelectAll}
            />
        )
    }

    const handleDeletePermissionGroup = () => {
        //dispatch action to delete the permission group
        handlePermissionGroupDelete(permission.id)
        setConfirmDialogOpen(false)
        togglePermissionModal(false)
    }


    const onSubmit = () => {
        //edit mode
        //update permission data JSON
        if(permission && isEdit){
            let permissionGroupData = {
                name: permission.name,
                enableEdit: permission.enableEdit,
                ...functionAccessConfig,
                devicePermission
            }
            togglePermissionModal()
            handlePermissionGroupEdit(permission.id, permissionGroupData)
        }else{
            //add mode
            let permissionGroupData = {
                name: permissionName,
                enableEdit: true,
                ...functionAccessConfig,
                grantAllCamerasAccess: true,
                systemDefaultUser: false
            }
            // console.log("permission group data to add", permissionGroupData, "adding to all nvrs", addToAllNVRs)

            handlePermissionGroupAdd(permissionGroupData, addToAllNVRs)
            togglePermissionModal()
        }

    }
    return (
        <Modal isOpen={permissionModalOpen} toggle={togglePermissionModal} size="lg">
            <ModalHeader toggle={togglePermissionModal}>{isEdit ? permission?.name : "Add NVR Permission Group"}</ModalHeader>
            <Card>
                <CardBody>
                    {!isEdit && 
                        <FormGroup>
                            <Label>Permission Group Name</Label>
                            <Input value={permissionName} onChange={(e)=>setPermissionName(e.target.value)}/>
                        </FormGroup>
                    }
                    <Row>
                        <Col md="4" className="align-self-center">
                            <CardTitle className="sub-list-title">Access Config</CardTitle>
                        </Col>
                        {isEdit && <Col md="8" className="text-right">
                            <IconButton disabled={viewOnlyMode} onClick={()=>setConfirmDialogOpen(true)}>
                                <DeleteIcon/>
                            </IconButton>

                            <ConfirmDeleteDialog 
                                modalVisible={confirmDialogOpen} 
                                toggleOff={()=>setConfirmDialogOpen(false)} 
                                onConfirm={handleDeletePermissionGroup} 
                                msg="Are you sure you want to delete this permission group?"/>
                        </Col>}
                    </Row>
                    <PermissionGroupFunctionAccessConfig configData={functionAccessConfig} handleAccessConfigChange={handleAccessConfigChange} viewOnlyMode={viewOnlyMode} isEdit={isEdit}/>
                    {isEdit && <div>
                        <Card>
                            <CardTitle className={`sub-list-title`}>Camera Access</CardTitle>
                                <Row>
                                    <Col md="12">
                                        <div className="mode-btn-group">
                                            <a className={`mode-btn ${localCameraMode ? 'active' : ''}`} onClick={e=>setLocalCameraMode(true)}>Local</a>
                                            <a className={`mode-btn ${!localCameraMode ? 'active' : ''}`} onClick={e=>setLocalCameraMode(false)}>Remote</a>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{
                                    maxHeight: '320px',
                                    overflowY: 'auto'
                                }}>
                                    <Table bordered className="device-permission-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className='header-span'>Device Name</span>
                                                </th>
                                                <th>
                                                    <span className='header-span'>Preview
                                                        {renderChangeDevicePermission(false, `${localCameraMode ? 'Local_' : 'Remote_'}Preview`, null, true)}
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className='header-span'>Search & Playback
                                                        {renderChangeDevicePermission(false, `${localCameraMode ? 'Local_' : 'Remote_'}SearchAndPlayback`, null, true)}
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className='header-span'>
                                                        Search & Backup
                                                        {renderChangeDevicePermission(false, `${localCameraMode ? 'Local_' : 'Remote_'}SearchAndBackup`, null, true)}
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className='header-span'>
                                                        Audio
                                                        {renderChangeDevicePermission(false, `${localCameraMode ? 'Local_' : 'Remote_'}Audio`, null, true)}
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className='header-span'>
                                                        PTZ
                                                        {renderChangeDevicePermission(false, `${localCameraMode ? 'Local_' : 'Remote_'}PTZControl`, null, true)}
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {devicePermission && devicePermission.map((p, index) => {
                                                let previewField = `${localCameraMode ? 'Local_' : 'Remote_'}Preview`
                                                let searchAndPlaybackField = `${localCameraMode ? 'Local_' : 'Remote_'}SearchAndPlayback`
                                                let searchAndBackupField = `${localCameraMode ? 'Local_' : 'Remote_'}SearchAndBackup`
                                                let audioField = `${localCameraMode ? 'Local_' : 'Remote_'}Audio`
                                                let ptzField = `${localCameraMode ? 'Local_' : 'Remote_'}PTZControl`
                                                let previewIsOn = p.auth?.some(p=>p===previewField)
                                                let searchAndPlaybackIsOn = p.auth?.some(p=>p===searchAndPlaybackField)
                                                let searchAndBackupIsOn = p.auth?.some(p=>p===searchAndBackupField)
                                                let audioIsOn = p.auth?.some(p=>p===audioField)
                                                let ptzIsOn = p.auth?.some(p=>p===ptzField)
                                                return (
                                                            <tr>
                                                                <td>{p.name}</td>
                                                                <td>{renderChangeDevicePermission(previewIsOn, previewField, index, false)}</td>
                                                                <td>{renderChangeDevicePermission(searchAndPlaybackIsOn, searchAndPlaybackField, index, false)}</td>
                                                                <td>{renderChangeDevicePermission(searchAndBackupIsOn, searchAndBackupField, index, false)}</td>
                                                                <td>{renderChangeDevicePermission(audioIsOn, audioField, index, false)}</td>
                                                                <td>{renderChangeDevicePermission(ptzIsOn, ptzField, index, false)}</td>
                                                            </tr>
                                                        )
                                                }
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                        </Card> 
                    </div>}
                    {!isEdit &&
                        <>
                            <Row>
                                <Col md="12">
                                    <CardTitle className="sub-list-title">Adding Options</CardTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Input type="checkbox" checked={fullCamAccess} onChange={(e)=>setFullCamAccess(e.target.checked)}/>
                                        <Label>Full Camera Access</Label>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Input type="checkbox" checked={addToAllNVRs} onChange={(e)=>setAddToAllNVRs(e.target.checked)}/>
                                        <Label>Applied To All NVRs</Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    }
                    <Row className="button-row">
                        <Col className="text-right" md="12">
                            {!viewOnlyMode && <Button onClick={onSubmit}>{isEdit ? 'Save Changes' : 'Add'}</Button>}
                            <Button color="info" onClick={togglePermissionModal}>Close</Button>
                        </Col>
                    </Row>
                    {/* {!isEdit && nvrList && <div>
                        <SelectNVRs handleNVRListChange={handleNVRListChange} nvrList={nvrList}/>
                    </div>} */}
                </CardBody>
            </Card>
        </Modal>
    )
}

export default PermissionGroupPanel


const DevicePermissionDropdown = ({isOn, field, index, handleChange, isSelectAll, viewOnly})=>{
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)
    return (
        <Dropdown className="in-table" isOpen={isOpen} toggle={toggle}>
            <DropdownToggle className={`${isSelectAll ? 'dropdown-toggle-button-blank' : 'dropdown-toggle-button'} ${!isSelectAll ? isOn ? 'status-on' : 'status-off' : ''}`} caret={!viewOnly}>
                {!isSelectAll ? isOn ? "ON" : "OFF" : ''}
            </DropdownToggle>
            {!viewOnly && <DropdownMenu className="dropdown-menu">
                <DropdownItem onClick={e=>handleChange(field, "ON", index, isSelectAll)}>ON</DropdownItem>
                <DropdownItem onClick={e=>handleChange(field, "OFF", index, isSelectAll)}>OFF</DropdownItem>
            </DropdownMenu>}
        </Dropdown>
    )
}
