/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Collapse, Button, CardBody, Card, ListGroup, ListGroupItem, CardTitle, CardHeader, Row, Col, TabContent, TabPane } from 'reactstrap';
import ExpandMore from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { fetchSingleNvrPermissionGroups, editSingleNvrPermissionGroup, deleteSingleNvrPermissionGroup, addNvrsPermissionGroups } from '../../../redux/actions/nvrMgmtAction';
import PermissionGroupPanel from './PermissionGroupPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavTabs } from '../../Map/components/MainBoard';
import { Tab } from 'react-tabs';
import NVRUserModal from './NVRUserModal';


const NVRSettingPanel = ({index, nvr, permissionGroups, fetchSingleNvrPermissionGroups, editSingleNvrPermissionGroup, deleteSingleNvrPermissionGroup, addNvrsPermissionGroups, users, handleSetReloadDataForAllCam, collapse}) => {
    const [isEdit, setIsEdit] = useState(true)
    const [isEditUser, setIsEditUser] = useState(true)
    const [permissionModalOpen, setPermissionModalOpen] = useState(false)
    const [userModalOpen, setUserModalOpen] = useState(false)
    const [selectedPermissionGroup, setSelectedPermissionGroup] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [tabArrs, setTabArrs] = useState(["Permission Groups", "NVR Users"])
    const [activeTab, setActiveTab] = useState('1')


    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const togglePermissionModal = () => {
        setPermissionModalOpen(!permissionModalOpen)
    }

    const toggleUserModal = () => {
        setUserModalOpen(!userModalOpen)
    }

    const handleSelectPermissionGroup = (permission) => {
        setSelectedPermissionGroup(permission)
        setIsEdit(true)
        togglePermissionModal()
    }

    const handleSelectUser = (user) => {
        setSelectedUser(user)
        setIsEditUser(true)
        toggleUserModal()
    }

    const renderPermissionGroupPanel = (permission, index) => {
        return  <ListGroupItem 
                    action 
                    onClick={()=>handleSelectPermissionGroup(permission)} 
                    className={`accordion-list-item list-item-flush`} 
                    key={index}>
                        <span>{permission.name}</span>
                </ListGroupItem>
    }

    const renderUserPanel = (user, index) => {
        return  <ListGroupItem 
                    action 
                    onClick={()=>handleSelectUser(user)} 
                    className={`accordion-list-item list-item-flush`} 
                    key={index}>
                        <span>{user.userName}</span>
                        <span>{`(${user.authGroupName ? user.authGroupName : 'default' })`}</span>
                        <ExpandMore/>
                </ListGroupItem>
    }

    //edit permission group
    const handlePermissionGroupEdit = (permissionGroupId, permissionGroupData) => {
        // console.log("permission group data", permissionGroupData)
        editSingleNvrPermissionGroup(nvr.id, permissionGroupId, permissionGroupData)
    }
    
    //delte permission group
    const handlePermissionGroupDelete = async(permissionGroupId) => {
        // console.log("permission group data", permissionGroupId)
        await deleteSingleNvrPermissionGroup(nvr.id, permissionGroupId)
        fetchSingleNvrPermissionGroups(nvr.id, "full")
    }

    const handlePermissionGroupAdd = async(permissionGroupData, addToAllNVRs) => {
        // console.log("adding permission group data", permissionGroupData)
        await addNvrsPermissionGroups(addToAllNVRs ? "all" : nvr.id, permissionGroupData)
        fetchSingleNvrPermissionGroups(nvr.id, "full")
        handleSetReloadDataForAllCam(addToAllNVRs === "on" ? true : false);
        
    }

    const toggleAddingPermissionModal = () => {
        setIsEdit(false)
        setSelectedPermissionGroup(null)
        togglePermissionModal()
    }

    const toggleAddUserModal = () => {
        setIsEditUser(false)
        toggleUserModal()
    }

    return (
        <>
            <Collapse isOpen={collapse}>
                <Card>
                    <CardBody>
                        <NavTabs tabArrs={tabArrs} activeTab={activeTab} toggleTab={toggleTab}/>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className='inner-card-body'>
                                    {/* {!permissionGroups && <Button  color="info" onClick={() => fetchSingleNvrPermissionGroups(nvr.id, "full")}>Load Permission Data</Button>} */}
                                    {!permissionGroups && <CircularProgress size={20}/>}
                                    {permissionGroups && permissionGroups.length === 0 && <p>No Permission Data</p>}
                                    {permissionGroups && permissionGroups.length > 0  && 
                                        <>
                                            <Row>
                                                <Col md="6">
                                                    <CardTitle>All Groups</CardTitle>
                                                </Col>
                                                <Col md="4">
                                                    <Button color="custom" outline onClick={toggleAddingPermissionModal}>Add</Button>
                                                </Col>
                                            </Row>
                                            <ListGroup>
                                                { permissionGroups.map((permission, index) => {
                                                    return renderPermissionGroupPanel(permission, index)
                                                })}
                                            </ListGroup>
                                        </>
                                    }

                                    <PermissionGroupPanel 
                                        isEdit={isEdit}
                                        permission={selectedPermissionGroup}
                                        handlePermissionGroupAdd={handlePermissionGroupAdd}
                                        handlePermissionGroupEdit={handlePermissionGroupEdit} 
                                        handlePermissionGroupDelete={handlePermissionGroupDelete}
                                        nvrId={nvr.id}
                                        permissionModalOpen={permissionModalOpen}
                                        togglePermissionModal={togglePermissionModal}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className='inner-card-body'>
                                    {!users && <CircularProgress size={20}/>}
                                    {users && users.length === 0 && <p>No Permission Data</p>}
                                    {users && users.length > 0  && 
                                        <>
                                        <Row>
                                            <Col md="6">
                                                <CardTitle>All Users</CardTitle>
                                            </Col>
                                            <Col md="4">
                                                <Button color="custom" outline onClick={toggleAddUserModal}>Add</Button>
                                            </Col>
                                        </Row>
                                        <ListGroup>
                                            {users.map((user, index) => {
                                                return renderUserPanel(user, index)
                                            })}
                                        </ListGroup>
                                    </>
                                    }

                                    <NVRUserModal 
                                        isEdit={isEditUser}
                                        user={selectedUser}
                                        nvr={nvr}
                                        nvrId={nvr.id}
                                        permissionGroups={permissionGroups}
                                        userModalOpen={userModalOpen}
                                        toggleUserModal={toggleUserModal}
                                    />
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Collapse>
        </>
    )
}

const mapStateToProps = (state) => ({
  nvrPermissionGroupList: state.nvrMgmt.nvrPermissions  
})

const mapDispatchToProps = (dispatch) => ({
    fetchSingleNvrPermissionGroups: (nvrId, listType) => dispatch(fetchSingleNvrPermissionGroups(nvrId, listType)),
    addNvrsPermissionGroups: (nvrId, permissionGroupData) => dispatch(addNvrsPermissionGroups(nvrId, permissionGroupData)),
    editSingleNvrPermissionGroup: (nvrId, permissionGroupId, permissionGroupData) => dispatch(editSingleNvrPermissionGroup(nvrId, permissionGroupId, permissionGroupData)),
    deleteSingleNvrPermissionGroup: (nvrId, permissionGroupId) => dispatch(deleteSingleNvrPermissionGroup(nvrId, permissionGroupId))
})

export default connect(mapStateToProps, mapDispatchToProps)(NVRSettingPanel)