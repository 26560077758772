/* eslint-disable */
import React, { Component, PureComponent, Fragment } from 'react';
import { connect } from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import { NavTabs } from '../../Map/components/MainBoard';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { VIDEO_ROOT, WEB_SUB_CHANNEL, WEB_SUB_EVENT } from '../../../constants/appConstants';
import CameraMapDisplay from './camera map/CameraMapDisplay';
import CameraListDisplay from './cameraList/CameraListDisplay';
import { loadAssets, loadClasses, postAssetAttribute } from '../../../redux/actions/assetActions';
import { loadAssetsSurveillance, loadCarCountLocations, loadLicensePlateRecords, loadNVRs, loadPeopleCountLocations, loadCameraGroups } from '../../../redux/actions/surveilenceActions';
import { loadDevice, loadDeviceInfo, getAssetClassAttributeAjax } from '../../../redux/actions/mapActions';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { bindActionCreators } from 'redux';
import { Menu, MenuItem, Typography, Button, styled } from '@material-ui/core';
import NestedMenuItem from "material-ui-nested-menu-item";
import office_img from '../../../../src/shared/img/Office_Layout.jpg';
import PeopleCountBoard from './peopleCountRecords/PeopleCountBoard';
import CarCountBoard from './carCountRecords/CarCountBoard';
import NvrDisplay from "./nvr/NvrDisplay";
// import MainBoard from '../../Opg/components/MainBoard';
import { withRouter } from 'react-router-dom';
// import SensorMonitoring from '../components/sensorMonitoring/SensorMonitoring'
import _, { initial, isEqual } from 'lodash';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { nvrLoginXml, getNVRChannelList } from './videoPlayer/nvr/nvrXMLRequestsUtils';




// const WS_ROOT = 'wss://wssdemo.itsp-inc.com';
const CustomizedBtn = styled(Button)({
    backgroundColor: '#e6e6e6',
    color: 'var(--headerFontColor)',
    fontSize: '20px',
    border: 'none',
    padding: '0 15px',
    margin: '0 0 15px 10px',
    boxShadow: '2px 3px 2px 1px #cccaca',
    "&:hover": {
        backgroundColor: 'var(--appButtonColor)',
        opacity: 40
    }


})
class SurveillanceMain extends PureComponent {
    constructor(props) {
        super(props);
        this.eventSource = new EventSource(WEB_SUB_CHANNEL);
        this.state = {
            activeTab: '1',
            activeSubTab: null,
            // pubSubData: null,
            menuPosition: null,
            pathItems: null,
            selectedLayoutId: null,
            layoutImg: null,
            showPeopleCount: false,
            showCarCount: false,
            selectedLayout: null,
            showFacialRecognition: true,
            extraTabs: [],
            activeFloorTab: '1',
            curAss: [],
            loggedInIp: null,
            nvrChannelListMain: null,
            nvrChannelListCC: null,
            httpPortMain:null,
            httpPortCC:null,
            loggedInIpMain: null,
            loggedInIpCC: null,
            nvrs: {}
        }
        this.userObj = getCurrentUser();
        this.handleTreeItemClick = this.handleTreeItemClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSelectingPlace = this.handleSelectingPlace.bind(this);
    }






    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    
    toggleSubTab = (tab) => {
        if (this.state.activeSubTab !== tab) {
            this.handleSelectingPlace(tab)
        }
    }

    handleFloorTabClick = (tab) => {
        if (this.state.activeFloorTab !== tab) {
            this.setState({ activeFloorTab: tab })
        }
    }




    handleSelectingPlace = () => {
        let layoutAssetList = this.props.allAssets.filter(asset => asset.assetclassname.includes("Layout"))
        // console.log("LAYOUT LIST...", layoutAssetList)
        let showAsset
        if (this.state.selectedAssetId) {
            let firstMatchedAsset = layoutAssetList.find(asset => asset.assetid == this.state.selectedAssetId)
            showAsset = firstMatchedAsset ? firstMatchedAsset : layoutAssetList[0]
            // console.log("SHOW ASSET", showAsset)
        } else if (this.props.selectedPlace) { //selected place from dashboard map
            let firstMatchedAsset = layoutAssetList.find(asset => asset.assetname == this.props.selectedPlace.assetname)
            showAsset = firstMatchedAsset ? firstMatchedAsset : layoutAssetList[0]
            // console.log("SHOW ASSET", showAsset)
        } else {
            showAsset = layoutAssetList[0]; // default asset
        }
        let defaultUserLayoutId = showAsset ? showAsset.assetid : null;
        // let filterDefaultLayouts = layoutAssetList.filter(asset => asset.assetid == defaultUserLayoutId);
        // let defaultLayout = filterDefaultLayouts.length > 0 ? filterDefaultLayouts[0] : null
        let floorPlanImg
        if (showAsset && showAsset.assetattributes.length > 0 && showAsset.assetattributes.filter(attribute => attribute.attmetaname == "Image").length > 0) {
            floorPlanImg = showAsset.assetattributes.filter(attribute => attribute.attmetaname == "Image")[0].value
        }
        let nginxPort
        if (showAsset && showAsset.assetattributes.length > 0 && showAsset.assetattributes.filter(attribute => attribute.attmetaname == "port").length > 0) {
            nginxPort = showAsset.assetattributes.filter(attribute => attribute.attmetaname == "port")[0].value
        }

        //find matching nvr and car counting nvr
        let mainNVR = null
        let ccNVR = null
        let showAssetNameSplitted = showAsset?.assetname?.split(' ');
        let reducedShowAssetName = showAssetNameSplitted?.slice(0, showAssetNameSplitted.length - 1).join(' ')
        if(reducedShowAssetName){
            //special case for Maple Tim hortons, need to find the main plaza nvr
            if ("Maple Tim Hortons".includes(reducedShowAssetName)) {
                let tempNVR = this.props.nvrList.filter(n => n.nvrName.includes("Maple Main Plaza"))[0]
                mainNVR = tempNVR ? tempNVR : null
                
            } else {
                let tempNVR = this.props.nvrList.filter(n => (!n.nvrType.includes("car counting")) && n.nvrName.includes(reducedShowAssetName))[0]
                //for car counting, some locations have multi layouts and the name is not exactly the same as the location name, so we need to slice the name, e.g Newcastle Main Plaza F1, Newcastle Main Plaza F2
                let tempCCNVR = this.props.nvrList.filter(n => n.nvrType.includes("car counting")  && n.nvrName.includes(reducedShowAssetName))[0]
                mainNVR = tempNVR ? tempNVR : null
                ccNVR = tempCCNVR ? tempCCNVR : null
            }
        }
        // console.log(">>>NVR main", mainNVR, ", ccNVR",ccNVR)
        if(mainNVR){
            this.props.loadCameraGroups(mainNVR.id);
        }
        this.setState({
            ...this.state,
            selectedLayoutId: defaultUserLayoutId,
            layoutList: layoutAssetList,
            treeAssetList: recursiveAssetList([...this.props.allAssets]),
            selectedLayout: showAsset,
            layoutImg: floorPlanImg ? floorPlanImg : office_img,
            nginxPort: nginxPort ? nginxPort : '8001',
            nvrs: {mainNVR: mainNVR, ccNVR: ccNVR}
        })
    }


    // handleSelectingPlace = (tab) => {
    //     let selectedBuildingName
    //     if(this.state.buildingTabs.length > 0){
    //         selectedBuildingName = this.state.buildingTabs[tab-1]
    //     }
    //     let selectedPlaceAsset = this.props.layoutAssetList.find(asset=>asset.assetname.includes(selectedBuildingName))
    //     let defaultUserLayoutId = selectedPlaceAsset ? selectedPlaceAsset.assetid : null;
    //     let floorPlanImg
    //     if (showAsset && showAsset.assetattributes.length > 0 && showAsset.assetattributes.filter(attribute => attribute.attmetaname == "Image").length > 0) {
    //         floorPlanImg = showAsset.assetattributes.filter(attribute => attribute.attmetaname == "Image")[0].value
    //     }
    //     let nginxPort
    //     if (showAsset && showAsset.assetattributes.length > 0 && showAsset.assetattributes.filter(attribute => attribute.attmetaname == "port").length > 0) {
    //         nginxPort = showAsset.assetattributes.filter(attribute => attribute.attmetaname == "port")[0].value
    //     }

    //     //find matching nvr and car counting nvr
    //     let mainNVR = null
    //     let ccNVR = null
    //     let showAssetNameSplitted = showAsset?.assetname?.split(' ');
    //     let reducedShowAssetName = showAssetNameSplitted?.slice(0, showAssetNameSplitted.length - 1).join(' ')
    //     if(reducedShowAssetName){
    //         //special case for Maple Tim hortons, need to find the main plaza nvr
    //         if ("Maple Tim Hortons".includes(reducedShowAssetName)) {
    //             let tempNVR = this.props.nvrList.filter(n => n.nvrName.includes("Maple Main Plaza"))[0]
    //             mainNVR = tempNVR ? tempNVR : null
                
    //         } else {
    //             let tempNVR = this.props.nvrList.filter(n => (!n.nvrType.includes("car counting")) && n.nvrName.includes(reducedShowAssetName))[0]
    //             //for car counting, some locations have multi layouts and the name is not exactly the same as the location name, so we need to slice the name, e.g Newcastle Main Plaza F1, Newcastle Main Plaza F2
    //             let tempCCNVR = this.props.nvrList.filter(n => n.nvrType.includes("car counting")  && n.nvrName.includes(reducedShowAssetName))[0]
    //             mainNVR = tempNVR ? tempNVR : null
    //             ccNVR = tempCCNVR ? tempCCNVR : null
    //         }
    //     }
    //     // console.log(">>>NVR main", mainNVR, ", ccNVR",ccNVR)

    //     this.setState({
    //         ...this.state,
    //         selectedLayoutId: defaultUserLayoutId,
    //         layoutList: this.props.layoutAssetList,
    //         activeSubTab: tab,
    //         treeAssetList: recursiveAssetList([...this.props.allAssets]),
    //         selectedLayout: selectedPlaceAsset,
    //         layoutImg: floorPlanImg ? floorPlanImg : office_img,
    //         nginxPort: nginxPort ? nginxPort : '8001',
    //         nvrs: {mainNVR: mainNVR, ccNVR: ccNVR}
    //     })
    // }


    componentDidMount() {
        // console.log('COMPONENT DID MOUNT...', this.props.allAssets)
        // this.eventSource.addEventListener(WEB_SUB_EVENT, (e)=> {
        //     console.log('camera pubsub ', e.data)
        //     this.pubSubUpdate(e.data);
        // });
        // this.props.loadCameraRestrictionsOnroute();
        if (this.props.allAssets && this.props.allAssets.length > 0) {
            this.handleSelectingPlace()
        }
        this.props.loadAssets();
        this.props.loadAssetsSurveillance();
        this.props.loadClasses();
        this.props.loadDevice();
        this.props.loadDeviceInfo();
        this.props.loadNVRs();
        // this.props.loadPeopleCountLocations();
        // this.props.loadCarCountLocations();

    }

    static getDerivedStateFromProps(props, state) {

    }

    componentWillUnmount() {
        // console.log('COMPONENT WILL UNMOUNT...')
    }

    componentDidUpdate(prevProps, prevState) {
        // Object.entries(this.props).forEach(([key,val])=>{
        //     if (!_.isEqual(val, prevProps[key])){
        //         console.log(`Prop '${key}' changed....`)
        //     }
        // })
        // Object.entries(this.state).forEach(([key,val])=>{
        //     if (!_.isEqual(val, prevState[key])){
        //         console.log(`State '${key}' changed...`)
        //     }
        // })
        if ((!isEqual(prevProps.allAssets, this.props.allAssets) && this.props.allAssets) || (prevState.selectedAssetId !== this.state.selectedAssetId) || !isEqual(prevProps.nvrList, this.props.nvrList)) {
            // console.log('ASSETS CHANGED OR SELECTED PLACE CHANGED, HANDLE SELECTING PLACE')
            this.handleSelectingPlace()
        }

        if (!isEqual(prevProps.selectedFloor, this.props.selectedFloor) && this.props.selectedFloor) {
            let activeFloor = this.props.selectedFloor + 1
            this.setState({
                ...this.state,
                activeFloorTab: activeFloor + ''
            })
        }

        if(!isEqual(prevState.nvrs, this.state.nvrs)){
            this.nvrLogin(this.state.nvrs).then((res)=>{
                console.log('NVR LOGIN RES', res)
                this.setState({
                    nvrChannelListMain: res.nvrChannelListMain,
                    loggedInIpMain: res.loggedInIpMain,
                    httpPortMain: res.httpPortMain,
                    nvrChannelListCC: res.nvrChannelListCC,
                    loggedInIpCC: res.loggedInIpCC,
                    httpPortCC: res.httpPortCC
                })
            })
        }


        //switch people count and car count tabs based on selected layout and available nvr lists
        if( this.state.selectedLayout && this.props.nvrList && (!_.isEqual(prevProps.nvrList, this.props.nvrList) ||!_.isEqual(prevState.selectedLayout, this.state.selectedLayout))){
            let selectedLayoutNameSplitted = this.state.selectedLayout.assetname.split(' ');
            let reducedSelectedLayoutName = selectedLayoutNameSplitted.slice(0, selectedLayoutNameSplitted.length - 1).join(' ')
            if(this.props.nvrList.some(nvr => nvr.nvrName.includes(reducedSelectedLayoutName) && (nvr.nvrType === 'plaza' || nvr.nvrType === 'development'))){
                this.setState({
                    showPeopleCount: true
                })
            }
            else{
                this.setState({
                    showPeopleCount: false
                })
            }

            //for car counting, some locations have multi layouts and the name is not exactly the same as the location name, so we need to slice the name, e.g Newcastle Main Plaza F1, Newcastle Main Plaza F2
            // let selectedLayoutNameSplitted = this.state.selectedLayout.assetname.split(' ');
            // let reducedSelectedLayoutName = selectedLayoutNameSplitted.slice(0, selectedLayoutNameSplitted.length - 1).join(' ')
            if(this.props.nvrList.some(nvr=> nvr.nvrName.includes(reducedSelectedLayoutName) && (nvr.nvrType === 'car counting' || nvr.nvrType === 'development'))){
                this.setState({
                    showCarCount: true
                })
            }
            else{
                this.setState({
                    showCarCount: false
                })
            }
        }

        if(this.state.showCarCount!=prevState.showCarCount || this.state.showPeopleCount!=prevState.showPeopleCount){
            this.setState({
                activeTab: '1'
            })
        }


    }
    // shouldComponentUpdate(prevProps, prevState) {

    // }

    componentWillUnmount() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    // pubSubUpdate = (data) => {
    //     this.setState({
    //       pubSubData: JSON.parse(data),
    //     });
    // }

    handleBackClick() {
        // console.log(this.props.history)
        let path = '/pages/dashboard';
        this.props.history.goBack()
    }

    // handleRightClick = (event) => {
    //     if (this.state.menuPosition) {
    //         return;
    //     }
    //     event.preventDefault();
    //     this.setState({
    //         menuPosition: {
    //             top: event.pageY,
    //             left: event.pageX
    //         }
    //     });
    //     // setMenuPosition({
    //     //   top: event.pageY,
    //     //   left: event.pageX
    //     // });
    // };

    handleRightClick = (event) => {
        const layoutAssetsCount = this.props.allAssets.filter(asset => asset.assetclassname.includes("Layout")).length;

        // if there is only one location, hide the menu
        if (layoutAssetsCount <= 1) {
            this.setState({ menuPosition: null });
            return;
        }

        event.preventDefault();
        this.setState({
            menuPosition: {
                top: event.pageY,
                left: event.pageX
            }
        });
    };


    handleTreeItemClick = (value, path) => {
        // console.log('TREE ITEM CLICK', value, path)
        this.setState({
            pathItems: path,
            selectedAssetId: value, //layout level
            menuPosition: null,
            // activeTab: '1',
            activeFloor: '1',
        })
    }


    nvrLogin = async (nvrs)=>{
        let nvrChannelListMain,loggedInIpMain, httpPortMain, nvrChannelListCC, loggedInIpCC, httpPortCC
        if(nvrs){
            //loop through nvrs object items
            for(const [key, nvr] of Object.entries(nvrs)){      
                if(nvr){ 
                    let nvrChannelList
                    let httpPort
                    let loggedInIp
                    let ips = [
                        {ip: nvr.nvrIpPublic , port: nvr.httpPortPublic}, 
                        {ip: nvr.nvrIp, port: nvr.httpPort}
                    ]
                    try{
                        //logging in to NVR using public IP first, if failed, try private IP
                        for(const i of ips) {
                            let loggedIn = false
                            // console.log(">>>starting try IP - ", i.ip, "("+nvr.nvrType+")")
                            if (i.ip == null) {
                                continue;
                            } 
                            let reqLoginRes =  await nvrLoginXml ("admin",null, i.ip, i.port, nvr.nvrType).then(async(res)=>{
                                if(res.success){
                                    let chlList =  await getNVRChannelList(i.ip, i.port, nvr.nvrType);
                                    if(chlList){
                                        nvrChannelList = chlList
                                        httpPort = i.port              
                                    }else{
                                        nvrChannelList = null
                                        httpPort = i.port
                                    }
                                    loggedIn = true
                                    loggedInIp = i.ip
                                    httpPort = i.port
                                }
        
                                console.log(">>>log-in status IP - ", this.state.selectedLayout?.assetname, i.ip, "("+nvr.nvrType+")", res)
                            })
                            //break if first IP is successfully logged in
                            if (loggedIn){
                                break;
                            }
        
                        }
    
                        if(key === 'mainNVR'){
                            if(nvr.nvrType === 'development'){   
                                nvrChannelListMain = nvrChannelList
                                nvrChannelListCC = nvrChannelList
                                loggedInIpMain = loggedInIp
                                loggedInIpCC = loggedInIp
                                httpPortMain = httpPort
                                httpPortCC = httpPort
                            }else{
                                nvrChannelListMain = nvrChannelList
                                loggedInIpMain = loggedInIp
                                httpPortMain = httpPort
                            }
                        }else{
                            nvrChannelListCC = nvrChannelList
                            loggedInIpCC = loggedInIp
                            httpPortCC = httpPort
                        }
                    }catch(err){
                        console.log('NVR Login Error,', err)
                    }
                }     
            }
            return {nvrChannelListMain,loggedInIpMain, httpPortMain, nvrChannelListCC, loggedInIpCC, httpPortCC}
        }
    }

    renderMenubar = (treeAssetList) => {

        let locationData = handleLocationJudgement(treeAssetList);
        let locationName = '';
        if (locationData) {
            locationName = locationData.label;
            this.setState({
                selectedAssetId: locationData.value,
            })
        }

        return (
            <div>
                <Row>
                    <Col md={6}>
                        <CustomizedBtn
                            // aria-controls="customized-menu"
                            // aria-haspopup="true"
                            // variant="contained"
                            // color="success"
                            onClick={this.handleRightClick}
                        >
                            {locationName ? locationName : this.state.selectedLayout ? this.state.selectedLayout.assetname : 'Choose Location'}
                            {locationName || !locationName && (treeAssetList && treeAssetList.length == 0 || !treeAssetList) ? '' : <ChevronDownIcon style={{ marginLeft: '10px', marginRight: '0px' }} />}
                        </CustomizedBtn>
                        <Menu
                            open={!!this.state.menuPosition && !(locationName || !locationName && (treeAssetList && treeAssetList.length == 0 || !treeAssetList))}
                            onClose={() => this.setState({ menuPosition: null })}
                            anchorReference="anchorPosition"
                            anchorPosition={this.state.menuPosition}
                        >
                            <TreeMenu
                                dataList={treeAssetList}
                                menuPosition={this.state.menuPosition}
                                handleItemClick={(name, value, path) => this.handleTreeItemClick(value, path)}
                            ></TreeMenu>
                        </Menu>
                    </Col>
                    <Col md={10}>
                        {/* <Breadcrumb>
                            {this.state.pathItems && this.state.pathItems.map((item, index) => (
                                <BreadcrumbItem key={index}>{item.label}</BreadcrumbItem>
                            ))}
                        </Breadcrumb> */}
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        var tooltipid = this.refs.tooltipref;
        let tabArr = ['Floor Map', 'Camera List', 'License Plate Recognition']
        if (this.state.showFacialRecognition) {
            tabArr.push('Face Recognition')
        }
        if(this.state.showPeopleCount){
            tabArr.push('People Count')
        }
        if(this.state.showCarCount){
            tabArr.push('Car Count')
        }
        if (this.props.pageContentList && this.props.pageContentList.length > 0) { //temporary solution - do not follow
            // console.log('PAGE CONTENT', this.props.pageContentList)
            tabArr = tabArr.filter(tab => this.props.pageContentList.includes(tab))
        }
        //tabArr.push('Video Download');
        tabArr.push('NVR Web Client')
        if (this.userObj.userName == 'ONrouteLegal' || this.userObj.companyCode == 'ONRT') {
            tabArr = tabArr.filter(tab => !tab.includes('Camera List'))
        }
        //OPG multi floor
        let floorsLayout = []
        let floorTabs = ['floor 1']
        if (this.state.selectedLayout && this.props.layoutList) {
            // console.log('RENDER LAYOUT, ', this.state.selectedLayout)
            let floorAttributes = this.state.selectedLayout.assetattributes.filter(at => at.attmetaname.includes("floor"))
            if (floorAttributes && floorAttributes.length > 0) {
                //console.log("FLOOR ATTRIBUTES", floorAttributes)
                floorAttributes.forEach((floor, index) => {
                    let assetid = floor.value
                    let asset = this.state.layoutList.find(layout => layout.assetid == assetid)
                    if (asset) {
                        floorsLayout.push(asset)
                        //console.log('FLOOR ADDED', floorsLayout)
                    }
                    floorTabs.push('floor ' + (index + 2))
                })
            }
        }
        return (
            <Col md={12}>
                {this.renderMenubar(this.state.treeAssetList)}
                <NavTabs styles={{marginLeft: '1%'}} tabArrs={tabArr} activeTab={this.state.activeTab} toggleTab={this.toggleTab} handleBackClick={this.handleBackClick}/>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={tabArr.indexOf('Floor Map') + 1 + ''}>
                        {/* subtabs for multifloor if rendered asset has multifloor attribute  */}
                        {floorTabs.length > 1 &&
                            <NavTabs tabArrs={floorTabs} activeTab={this.state.activeFloorTab} toggleTab={this.handleFloorTabClick} />
                        }

                        {
                            floorTabs.length == 1 &&
                            <><CameraMapDisplay
                                cameraList={this.props.cameraList}
                                showAssetList={this.props.showAssetList}
                                cameraClass={this.props.cameraClass}
                                tooltipid={tooltipid}
                                // pubSubData={this.state.pubSubData}
                                postAssetAttribute={this.props.postAssetAttribute}
                                layoutImg={this.state.layoutImg}
                                selectedLayoutId={this.state.selectedLayoutId}
                                selectedLayout={this.state.selectedLayout}
                                mainNVR={this.state.nvrs.mainNVR}
                                nvrChannelList={this.state.nvrChannelListMain}
                                httpPort={this.state.httpPortMain}
                                nvrIP={this.state.loggedInIpMain}
                            />
                            </>
                        }
                        {/* multiple floors layout */}
                        {floorsLayout && floorsLayout.length > 0 && floorTabs.length > 1 &&
                            <TabContent activeTab={this.state.activeFloorTab}>
                                <TabPane tabId="1">
                                    <CameraMapDisplay
                                        // showAssetList={this.props.showAssetList}
                                        tooltipid={tooltipid}
                                        // pubSubData={this.state.pubSubData}
                                        postAssetAttribute={this.props.postAssetAttribute}
                                        layoutImg={this.state.layoutImg}
                                        selectedLayoutId={this.state.selectedLayoutId}
                                        selectedLayout={this.state.selectedLayout}
                                        nvrChannelList={this.state.nvrChannelListMain}
                                        httpPort={this.state.httpPortMain}
                                        nvrIP={this.state.loggedInIpMain}
                                        mainNVR={this.state.nvrs.mainNVR}
                                    />
                                </TabPane>
                                {floorsLayout.map((layout, index) => {
                                    return (<TabPane tabId={`${index + 2}`}>
                                        <CameraMapDisplay
                                            cameraList={this.props.cameraList}
                                            // showAssetList={this.props.showAssetList}
                                            tooltipid={tooltipid}
                                            // pubSubData={this.state.pubSubData}
                                            postAssetAttribute={this.props.postAssetAttribute}
                                            layoutImg={layout.assetattributes.find(attribute => attribute.attmetaname == "Image").value}
                                            selectedLayoutId={layout.assetid}
                                            selectedLayout={layout}
                                            nvrChannelList={this.state.nvrChannelListMain}
                                            httpPort={this.state.httpPortMain}
                                            nvrIP={this.state.loggedInIpMain}
                                            mainNVR={this.state.nvrs.mainNVR}
                                        />
                                    </TabPane>)
                                })}
                            </TabContent>}

                    </TabPane>
                    <TabPane tabId={tabArr.indexOf('Camera List') + 1 + ''}>
                        <CameraListDisplay />
                    </TabPane>
                    {/* <TabPane tabId={tabArr.indexOf('License Plate Recognition') + 1 + ''}>
                        <LicensePlateRecordsDisplay />
                    </TabPane>
                    <TabPane tabId={tabArr.indexOf('Face Recognition') + 1 + ''}>
                        <FaceRecordsDisplay />
                    </TabPane> */}
                    {/* <TabPane tabId={tabArr.indexOf('Face Detection') + 1 + ''}>
                        <FaceDetectionDisplay />
                    </TabPane> */}
                    <TabPane tabId={tabArr.indexOf('People Count') + 1 + ''}>
                        <PeopleCountBoard
                            location={this.state.selectedLayout}
                            nvrChannelList={this.state.nvrChannelListMain}
                            nvrIP={this.state.loggedInIpMain}
                            httpPort = {this.state.httpPortMain}
                            mainNVR = {this.state.nvrs.mainNVR}
                        />
                    </TabPane>
                    {/* <TabPane tabId={tabArr.indexOf('Sensor Monitoring') + 1 + ''}>
                            <SensorMonitoring/>
                    </TabPane> */}
                    <TabPane tabId={tabArr.indexOf('NVR Web Client') + 1 + ''}>
                        <NvrDisplay
                            nvrIP={this.state.loggedInIpMain}
                            port={this.state.nginxPort}
                            httpPort = {this.state.httpPortMain}

                        />
                    </TabPane>
                    <TabPane tabId={tabArr.indexOf('Car Count') + 1 + ''}>
                        <CarCountBoard
                            location={this.state.selectedLayout}
                            nvrChannelList={this.state.nvrChannelListCC}
                            nvrIP={this.state.loggedInIpCC}
                            httpPort = {this.state.httpPortCC}
                            ccNVR = {this.state.nvrs.ccNVR ? this.state.nvrs.ccNVR : this.state.nvrs.mainNVR}
                        />
                    </TabPane>
                    {/* <TabPane tabId={tabArr.indexOf('Video Download') + 1 + ''}>
                        <VideoDownLoad selectedLayoutId={this.state.selectedLayoutId} selectedLayout={this.state.selectedLayout}/>
                    </TabPane> */}
                </TabContent>
            </Col>
        );
    }
}

const assetShowList = (assetList, assetClassList) => {
    if (!assetList || !assetClassList) {
        return [];
    }
    // let displayList = assetList.filter(asset => {
    //     let match = false;
    //     asset.assetattributes && asset.assetattributes.forEach(attr => {
    //         if(attr.attmetaname == 'display' && attr.value == 'true'){
    //             match = true;
    //         }
    //     });
    //     return match;
    // });
    let showlist = assetList.map(asset => {
        let assetclass = {};
        if (assetClassList) {
            assetclass = assetClassList.find(ac => ac.id == asset.assetclassid);
            // console.log('loadAssetsSurveillance===================================================',assetclass)
        }
        return { asset, assetclass }
    });
    // console.log('loadAssetsSurveillance==========================================================',showlist)
    return showlist;
}

const recursiveAssetList = (assetList) => {

    let treeList = []
    if (!assetList) {
        return treeList;
    }
    let rootList = assetList.filter(asset => asset.assetclassname.includes('Area'));
    rootList && rootList.forEach((asset, index) => {
        let rootItem = {
            label: asset.assetname,
            value: asset.assetid,
            path: [{ label: asset.assetname }]
        }
        childNode(assetList, rootItem);
        treeList.push(rootItem);
    });
    return treeList;
}

const childNode = (sourceList, parentNode) => {

    // let childrenList = sourceList.filter(asset => asset.parentasset == parentNode.value && !asset.assetname.includes('Floor'));
    let childrenList = sourceList.filter(asset => asset.parentasset == parentNode.value);
    let items = [];
    childrenList && childrenList.forEach((child, index) => {
        let isEnd = (child.assetclassname.includes('Layout'));
        let childItem = {
            label: child.assetname,
            value: child.assetid,
            last: isEnd,
            path: [...parentNode.path, { label: child.assetname }]
        }
        if (!isEnd) {
            childNode(sourceList, childItem);
        }
        items.push(childItem);
    });
    if (items.length > 0) {
        parentNode.items = items;
    }
}

const TreeMenu = (props) => {
    return (
        <div>
            {props.dataList && props.dataList.map((item, index) => {
                if (!item.last && item.items && item.items.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            label={item.label}
                            parentMenuOpen={!!props.menuPosition}
                            className="tree-item"
                        >
                            <TreeMenu
                                dataList={item.items}
                                menuPosition={props.menuPosition}
                                handleItemClick={props.handleItemClick}
                            ></TreeMenu>
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            key={index}
                            className="tree-item"
                            onClick={() => { if (item.last && props.handleItemClick) { props.handleItemClick(item.label, item.value, item.path) } }}
                        >{item.label}</MenuItem>
                    );
                }
            })}
        </div>
    )
}

// Recursively determine whether the current user has only one location
// If so, hide the location selection menu
// If not, show the location selection menu
const handleLocationJudgement = (treeAssetList) => {
    let locationData = null;

    if (treeAssetList && treeAssetList.length == 1) {
        if (treeAssetList[0].items && treeAssetList[0].items.length == 1) {
            locationData = handleChildLocationJudgement(treeAssetList[0].items);
        } else if (!treeAssetList[0].items || treeAssetList[0].items.length == 0) {
            locationData = {
                label: treeAssetList[0].label,
                value: treeAssetList[0].value
            };
        }
    }

    return locationData;
}

const handleChildLocationJudgement = (treeAssetList) => {
    let locationData = '';

    if (treeAssetList && treeAssetList.length == 1) {
        if (treeAssetList[0].items && treeAssetList[0].items.length == 1) {
            locationData = handleChildLocationJudgement(treeAssetList[0].items);
        } else if (!treeAssetList[0].items || treeAssetList[0].items.length == 0) {
            locationData = {
                label: treeAssetList[0].label,
                value: treeAssetList[0].value
            };
        }
    }

    return locationData;
}


const mapStateToProps = (state) => {
    const props = {
        // layoutList: state.asset.assetList.filter(asset => asset.assetclassname.includes('Layout')),
        // cameraList: state.asset.assetList.filter(asset=> asset.assetclassname=='Camera_Base'),
        // showAssetList: state.surveillance.assets.map(assetobj => {
        //     let asset = state.asset.assetList.find(a => a.assetid == assetobj.asset.assetid);
        //     return { asset, assetclass: assetobj.assetclass }
        // }),
        // showLoadAssetList: assetShowList(state.asset.assetList, state.asset.assetClassList),
        // treeAssetList: recursiveAssetList(state.asset.assetList),
        // cameraClass: state.asset.assetClassList.find(c => c.name.includes('Camera_Base')),
        // sensorClass: state.asset.assetClassList.filter(c=>c.name.includes('Airbox_Sensor')),
        // deviceTypes: state.mapboard.types,
        centerList: state.asset.assetList.filter(asset => asset.assetclassname.includes('Center')),
        layoutAssetList: state.asset.assetList.filter(asset => asset.assetclassname.includes('Layout')),
        allAssets: state.asset.assetList,
        peopleCountRecords: state.surveillance.peopleCountRecords,
        nvrList: state.surveillance.nvrList,
        pageContentList: state.user.pageContentList
    }
    return props;
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ loadAssets, loadAssetsSurveillance, loadClasses, postAssetAttribute, loadDevice, loadDeviceInfo, loadNVRs, loadCameraGroups}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveillanceMain));