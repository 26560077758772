/* eslint-disable */
import { stat } from 'fs-extra';
import {surveilActionTypes} from '../../constants/actionTypes'
import SurveillancePage from '../../containers/Surveillance';
import { toast } from 'react-toastify';

const initialState = {
    assets: [], //asset list to show in surveillance map
    licenseplates: [],
    faceRecords: [],
    faceDetectionRecords: [],
    peopleCountRecords: [],
    // cameraRestrictions: [],
    carCountRecords: [],
    nvrList: [],
    nvrCameraGroup: []
}

export default function(state = initialState, action) {
    const {type, payload} = action;
    switch(type) {
        case surveilActionTypes.ADD_ASSET:
            let assetList = state.assets
            let assetToAdd = payload;
            if(assetList.includes(assetToAdd)){ //if item already in list
                return state
            }else{
                //store the list of assets in the local storage for now, later will have to post to backend
                let list = JSON.stringify([...assetList, assetToAdd])
                sessionStorage.setItem("tempList", list)
                return {
                    ...state,
                    assets: [...assetList, assetToAdd]
                }
            }
        
        case surveilActionTypes.LOAD_ASSETS:
            return {
                ...state,
                assets: payload
            }

        case surveilActionTypes.CLEAR_ASSETS:
            sessionStorage.setItem("tempList", "[]")
            return {
                ...state,
                assets: []
            }
        
        case surveilActionTypes.LOAD_LPR_SUCCEED:
            // console.log('LOAD LPR SUCCEED')
            return {
                ...state,
                licenseplates: payload
            }

        case surveilActionTypes.LOAD_FACE_RECOGNITION_SUCCEED:
            // console.log('LOAD FR SUCCEED', payload)
            return {
                ...state,
                faceRecords: payload
            }
        case surveilActionTypes.LOAD_FACE_DETECTION_SUCCEED:
            // console.log('LOAD FR SUCCEED', payload)
            return {
                ...state,
                faceDetectionRecords: payload
            }
        case surveilActionTypes.LOAD_PEOPLE_COUNT_SUCCEED:
            if(payload.building){  //if building value not empty
                let newPeopleCountData = [...state.peopleCountRecords];
                //store records are grouped by date
                let currentDateDataIndex = newPeopleCountData.findIndex(a=>a.date === payload.date)
                if(currentDateDataIndex > -1){
                    let buildingData = newPeopleCountData[currentDateDataIndex].data
                    let buildingDataIndex = buildingData.findIndex(c => c.building === payload.building)
                    if(buildingDataIndex > -1){
                        let camData = buildingData[buildingDataIndex].data
                        //loop through payload.data array and find in camData the item with the same camera name value
                        //if found, update the data value, if not found, add the item to camData
                        payload.data.forEach(d=>{
                            let camDataIndex = camData.findIndex(e=>e.camName === d.camName)
                            if(camDataIndex > -1){
                                camData[camDataIndex].data = [...camData[camDataIndex].data, ...d.data]
                            }else{
                                camData.push(d)
                            }
                        })    
                    }else{
                        newPeopleCountData[currentDateDataIndex].data.push({
                            building: payload.building,
                            data: payload.data
                        })
                    }
                }else{
                    newPeopleCountData.push({
                        date: payload.date,
                        data: [
                            {                       
                                building: payload.building,
                                data:payload.data
                            }
                        ]
                    })
                }
                return {
                    ...state,
                    peopleCountRecords: newPeopleCountData
                }
            }else{
                return {
                    ...state
                }
            }
        case surveilActionTypes.LOAD_CAR_COUNT_SUCCEED:
            // console.log('LOAD CAR COUNT SUCCEED', payload)
            if(payload.building){  //if building value not empty
                let newCarCountData = [...state.carCountRecords];
                //store records are grouped by date
                let currentDateDataIndex = newCarCountData.findIndex(a=>a.date === payload.date)
                if(currentDateDataIndex > -1){
                    let buildingData = newCarCountData[currentDateDataIndex].data
                    let buildingDataIndex = buildingData.findIndex(c => c.building === payload.building)
                    if(buildingDataIndex > -1){
                        let camData = buildingData[buildingDataIndex].data
                        //loop through payload.data array and find in camData the item with the same camera name value
                        //if found, update the data value, if not found, add the item to camData
                        payload.data.forEach(d=>{
                            let camDataIndex = camData.findIndex(e=>e.camName === d.camName)
                            if(camDataIndex > -1){
                                camData[camDataIndex].data = [...camData[camDataIndex].data, ...d.data]
                            }else{
                                camData.push(d)
                            }
                        })    
                    }else{
                        newCarCountData[currentDateDataIndex].data.push({
                            building: payload.building,
                            data: payload.data
                        })
                    }
                }else{
                    newCarCountData.push({
                        date: payload.date,
                        data: [
                            {                       
                                building: payload.building,
                                data:payload.data
                            }
                        ]
                    })
                }
                return {
                    ...state,
                    carCountRecords: newCarCountData
                }
            }else{
                return {
                    ...state
                }
            }
        
        case surveilActionTypes.LOAD_NVR_LIST_SUCCEED:
            return {
                ...state,
                nvrList: payload
            }
        // case surveilActionTypes.LOAD_CAMERA_RESTRICTIONS_SUCCEED:
        //     return{
        //         ...state,
        //         cameraRestrictions: payload
        //     }
        // case surveilActionTypes.UPDATE_CAMERA_RESTRICTIONS_SUCCEED:
        //     toast.success("Updated restrictions successfully", payload);
        //     return{
        //         ...state,
        //         cameraRestrictions: payload
        //     }
        
        case surveilActionTypes.LOAD_NVR_LIST_FAILED:
            toast.error('Failed to load NVR list')

        case surveilActionTypes.ADD_NVR_SUCCEED:
            return {
                ...state,
                nvrList: [...state.nvrList, payload]
            }
        case surveilActionTypes.ADD_NVR_FAILED:
            toast.error(payload);
        
        case surveilActionTypes.LOAD_CAMERA_GROUPS_REQUEST:
            return {
                ...state
            }
        case surveilActionTypes.LOAD_CAMERA_GROUPS_SUCCEED:
            //add response data to the associated nvr in the nvr list
            // console.log('LOAD CAMERA GROUPS SUCCEED', payload);
            let tempNvrCameraGroup = [...state.nvrCameraGroup]
            let nvrIndex = tempNvrCameraGroup.findIndex(nvr=>nvr.id == payload.nvr.id)
            if(nvrIndex > -1){
                tempNvrCameraGroup[nvrIndex].cameraGroups = payload.data
            }else{
                tempNvrCameraGroup.push({
                    nvr: payload.nvr,
                    cameraGroups: payload.data
                })
            }
            return {
                ...state,
                nvrCameraGroup: tempNvrCameraGroup
            }
        default:
            return state;
    }
}
