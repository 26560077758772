/*eslint-disable */
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { Col, Row, Card, CardBody, Label, Button, Input, CardTitle } from 'reactstrap';
import {Collapse} from 'reactstrap';
import { withRouter } from "react-router-dom";
import * as d3 from "d3";
import Zoomable from './ZoomableMap/ZoomableMap';
import DragVideoModalIncon from './CameraIcon/DragVideoModalIcon';
import DragCameraModalIcon from './CameraIcon/DragCameraModalIcon';
import office_img from '../../../../shared/img/Office_Layout.jpg';
import { VIDEO_ROOT, WEB_SUB_CHANNEL, WEB_SUB_EVENT } from '../../../../constants/appConstants';
import log from 'loglevel';
import AssetFilterModal from './FilterModal/AssetFilterModal';
import DragAssetModalIcon from './CameraIcon/DragAssetModalIcon';
import { nvrLoginXml, getNVRChannelList } from '../videoPlayer/nvr/nvrXMLRequestsUtils';
import {getLastMsgBySingleAssetAsync, loadAssetsSurveillance, clearAssetsSurveillance, loadCameraRestrictionsOnroute} from '../../../../redux/actions/surveilenceActions';
import {
    // getCompanyAssetClass,
    // getWeatherAssetData,
    // clearWeatherAssetData,
    // getWeatherAlarmCount,
    // clearWeatherAlarmCount,
    // updateWeatherAssetData,
    // updatePubSubAssetData,
    // pubsubUpdateWeatherAssetHistoryData,
    getAssetHistoryData
  } from "../../../../redux/actions/weatherAction";
import { cssNumber } from 'jquery';
import {connect} from 'react-redux';
import {renderSpinner} from '../../../Client/components/ClientBoard';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import {OPG_ASSETCLASS_NAME_ARRAY} from '../../../Opg/components/MainBoard';
import { FaLastfmSquare } from 'react-icons/fa';
import { bindActionCreators } from 'redux';
import TortoiseHistoryLocation from '../../../Opg/components/TortoiseHistoryLocation';
import {getCurrentUser} from '../../../../factories/auth'
import { loadAssets } from '../../../../redux/actions/assetActions';
import CameraAttributeFilter from '../filters/CameraAttributeFilter';
import { isEqual } from 'lodash';
import { CameraAssetFilters } from '../../utils/cameraUtils';
import CameraGroupFilter from '../filters/CameraGroupFilter';


class CameraMapDisplay extends Component{
    constructor(props){
        super(props)
        this.eventSource = new EventSource(WEB_SUB_CHANNEL);
        this.state = {
            cameraToEdit: props.cameraToEdit,
            filteredCamList: null,
            filters: null,
            camName: '',
            // pubSubData: props.pubSubData,
            filterModalVisible: false,
            degree: 30,
            mapLocked: true,
            pubSubData: null,
            startDate: null,
            endDate: null,
            trackedLocations: null,
            curAsset:[],
            nvrToken: null,
            nvrNonce: null,
            nvrSessionId: null,
            nvrChannelList: null,
            nvrIP:null,
            filterOn: false

            
        }
        this.opgClassId = null;
        this.toggleFilterModal = this.toggleFilterModal.bind(this)
        this.handleClearAsset = this.handleClearAsset.bind(this)
        this.handleLockChange = this.handleLockChange.bind(this)
        this.loadAssetHistoryData = this.loadAssetHistoryData.bind(this)
        this.setFilteredCams = this.setFilteredCams.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.setCamName = this.setCamName.bind(this)
        this.renderCameraList = this.renderCameraList.bind(this)
        this.renderSensorList = this.renderSensorList.bind(this)
        this.handleCameraGroupSelect = this.handleCameraGroupSelect.bind(this)


    }
    componentDidMount(){
        // await this.props.loadAssetsSurveillance();
        this.eventSource.addEventListener(WEB_SUB_EVENT, (e)=> {
            //console.log('SENSOR pubsub ', e.data)
            this.pubSubUpdate(e.data);
        });

        let user = getCurrentUser();
        if(user && user.userName=='admin' && user.companyCode=="121"){
            this.setState({mapLocked: false})
        }
        // if(this.props.cameraList){
        //     console.warn(this.props.cameraList);
        // }

        // this.nvrLogin()

    }
    componentWillUnmount(){
        this.eventSource.close();
    }

    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(this.props.layoutCameraList, prevProps.layoutCameraList) || (this.props.selectedLayoutId !== prevProps.selectedLayoutId)){
            this.setFilteredCamsNew()
        }
        if(this.state.pubSubData){
            //console.log('PUB SUB UPDATE')
            //console.log(this.state.pubSubData)
        }
        // Object.entries(this.props).forEach(([key, val]) =>
        //     prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        // );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //     prevState[key] !== val && console.log(`State '${key}' changed`)
        //     );
        // }
        

        if(this.props.assetList && this.props.selectedLayoutId && (this.props.selectedLayoutId!== prevProps.selectedLayoutId)){
     
            let asset
            if(this.props.selectedLayout){
                asset = this.props.selectedLayout
            }else{
                asset = this.props.assetList.filter(asset=>asset.assetid == this.props.selectedLayoutId)[0];
            }
            
        }

        if( this.props.mainNVR && (!isEqual(this.props.mainNVR, prevProps.mainNVR) || !isEqual(this.props.nvrCamGroupsList, prevProps.nvrCamGroupsList))){
            let nvrCamGroups = this.props.nvrCamGroupsList.find(d=>d.nvr.id == this.props.mainNVR.id)  
            if(nvrCamGroups && nvrCamGroups.cameraGroups){
                //update camera groups state
                // console.log('CAMERA GROUPS', nvrCamGroups.cameraGroups)
                this.setState({
                    cameraGroups: nvrCamGroups.cameraGroups
                })
            }else{
                this.setState({
                    cameraGroups: []
                })
            }
        }

        // if(this.props.layoutCameraList && !isEqual(this.props.layoutCameraList, prevProps.layoutCameraList) || !isEqual(prevState.filters, this.state.filters)){
            
        // }




        //OPG DEMO
        // if(this.props.deviceHistoryData && this.props.layoutSensorList){
        //     if(JSON.stringify(this.props.deviceHistoryData) !== JSON.stringify(prevProps.deviceHistoryData) || JSON.stringify(this.props.layoutSensorList) !== JSON.stringify(prevProps.layoutSensorList)) {

        //         // console.log('DEVICE HISTORY DATA', this.props.deviceHistoryData)
        //         let beaconsVisibleMsgs = this.props.deviceHistoryData.filter(msg=>msg.attributename.includes("BeaconId"))
        //         if(beaconsVisibleMsgs && beaconsVisibleMsgs.length > 0){
        //             beaconsVisibleMsgs = beaconsVisibleMsgs.map(msg=>{
        //                 let beaconId = msg.value
        //                 let beaconPosition = null
        //                 let beaconAssetList = this.props.layoutSensorList.filter(device=>device.assetclassname.includes('beacon'))
        //                 if(beaconAssetList && beaconAssetList.length > 0){
        //                     let beaconFound = beaconAssetList.find(b=> b.assetattributes.some(atbt=> atbt.value == beaconId))
        //                     if(beaconFound){
        //                         let beaconPositionAttribute = beaconFound.assetattributes.find(atbt=> atbt.attmetaname.includes('position'))
        //                         if(beaconPositionAttribute) beaconPosition = JSON.parse(beaconPositionAttribute.value)
        //                     }
        //                 }
        //                 return {
        //                     deviceId: msg.deviceid,
        //                     beacon: msg.value,
        //                     timestamp: msg.timestamp,
        //                     position: beaconPosition ? beaconPosition : null
        //                 }
        //             })
        //             if(!this.state.trackedLocations || (JSON.stringify(prevState.trackedLocations) !== JSON.stringify(beaconsVisibleMsgs))){
        //                 this.setState({
        //                     trackedLocations: beaconsVisibleMsgs
        //                 }, /*console.log("HISTORY LOCATION", this.state.trackedLocations)*/)
        //             }
        //         }
        //     }

        // }

        // if(this.props.layoutSensorList && OPG_ASSETCLASS_NAME_ARRAY && !this.opgClassId){
        //     let opgClass = []
        //     this.props.layoutSensorList.every(sensor=>{
        //         let sensorClassName = sensor.assetclassname;
        //         OPG_ASSETCLASS_NAME_ARRAY.every(predefinedOPG=>{
        //             if(sensorClassName.includes(predefinedOPG)){
        //                 if(!opgClass.includes(sensor.assetclassid)){
        //                     opgClass.push(sensor.assetclassid)
        //                 }
        //             }
        //             return true
        //         })
        //         return true
        //     })
        //     if(opgClass.length>0) this.opgClassId = [...opgClass]
        //     //console.log("OPG DEMO", opgClass)
        // }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.props.selectedLayoutId!== nextProps.selectedLayoutId && isEqual(this.props.assetList, nextProps.assetList) && isEqual(this.props.layoutCameraList, nextProps.layoutCameraList) && isEqual(this.props.layoutSensorList, nextProps.layoutSensorList) && isEqual(this.state.filters, nextState.filters) && isEqual(this.state.filteredCamList, nextState.filteredCamList) && isEqual(this.state.pubSubData, nextState.pubSubData) && this.state.camName ===nextState.camName &&
         isEqual(this.state.cameraGroups, nextState.cameraGroups)){
            return false
        }
        return true
    }

    //response to event devicemessage update
    pubSubUpdate = (data) => {
        this.setState({
          pubSubData: JSON.parse(data),
        });
    }

    setFilters(filter){
        this.setState({
            filters: filter
        }, this.setFilteredCams)
    }

    setCamName(e){
        this.setState({
            camName: e.target.value
        }, this.setFilteredCamsNew)
    }

    setFilteredCamsNew(){
        if(this.props.layoutCameraList && this.props.layoutCameraList.length > 0){
            let filteredCamList = this.props.layoutCameraList.slice();
            if(this.state.camName && this.state.camName.trim() !== ''){
                filteredCamList = filteredCamList.filter(cam=> cam.assetattributes.some(att=>att.attmetaname.includes("name") && att.value.includes(this.state.camName.trim())))
            }
            if(this.state.cameraNameFilteredByGroup && this.state.cameraNameFilteredByGroup.length > 0){
                filteredCamList = filteredCamList.filter(cam=> cam.assetattributes.some(att=>att.attmetaname.includes("name") && this.state.cameraNameFilteredByGroup.includes(att.value)))
            }
            this.setState({
                filteredCamList: filteredCamList
            })
        }
    }
    

    //outdated
    setFilteredCams(){
        if(this.props.layoutCameraList && this.props.layoutCameraList.length > 0){
            let camListWithNameFilter = this.props.layoutCameraList.slice();
            let filterOn = false;
            if(this.state.camName && this.state.camName.trim() !== ''){
                camListWithNameFilter = camListWithNameFilter.filter(cam=>{
                  let nameAttr = cam.assetattributes.find(att=>att.attmetaname.includes("name"))
                  return nameAttr && nameAttr.value.includes(this.state.camName)
                })
                filterOn = true;
            }
            if(this.state.filters){
                //reduced to filter with selectedVal only
                let reducedFilters = this.state.filters.filter(f=>f.selectedVal)
                if(reducedFilters.length > 0){
                    filterOn = true
                    //find camera that meet all filtering conditions
                    let filteredCamList = camListWithNameFilter.reduce((acc, cam)=>{
                        let isMet = true
                        reducedFilters.forEach((f)=>{
                            if(cam.assetattributes && cam.assetattributes.length > 0){
                                let camAttributesByFilter = cam.assetattributes.find(attr => attr.attmetaname == f.filterName.toLowerCase())
                                if(camAttributesByFilter == undefined || camAttributesByFilter.value !== f.selectedVal.value){
                                    isMet = false
                                }
                            }
                        })
                        if(isMet) acc.push(cam)
                        return acc
                    }, [])
                    this.setState({
                        filteredCamList: filteredCamList,
                        filterOn: filterOn
                    })
                }else{ //if none filters applied set all cams
                    this.setState({
                        filteredCamList: camListWithNameFilter,
                        filterOn: filterOn
                    })
                }    
            }else{
                this.setState({
                    filteredCamList: camListWithNameFilter,
                    filterOn: filterOn
                })
            }
        }else{
            this.setState({
                filteredCamList: [],
                filterOn: false
            })

        }
    }





    //update position attribute of asset

    updateAssetAttribute = (x, y , assetid, attributeid, prevX, prevY) => {
        // console.log('update asset attribute')
        if((x !== prevX || y !== prevY) && (Math.abs(x - prevX) > 2 || Math.abs(y - prevY) > 2)){
            if (!assetid || !attributeid) {
                log.warn("assetid or attributeid was not provided");
                return;
            }
            let param =  {
                assetid: assetid,
                attmetaid: attributeid,
                value: JSON.stringify({x: x, y: y})
            };
            this.props.postAssetAttribute(param).then(
                // res => {loadAssets();}
                res => { 
                    // log.info(`update camera ${assetid} position: x - ${x} , y - ${y}`)
                    this.props.loadAssets();// update assetsList!! Same as 'this.props.updateCo(res)'
                    }
            ).catch((err) => {
                log.error(err)
            });
        }
    }

    //update degree attribute of camera
    updateDegreeAttribute = (deg, assetid, attributeid) => {
        // console.table({
        //     deg: deg,
        //     assetid: assetid,
        //     attributeid: attributeid
        // })
        if (!assetid || !attributeid) {
            log.warn("assetid or attributeid was not provided");
            return;
        }
        let param =  {
            assetid: assetid,
            attmetaid: attributeid,
            value: deg
        };
        this.props.postAssetAttribute(param).then(
            // res => {loadAssets();}
            res => {
                // log.info(`update camera ${assetid} degree ${attributeid}: ${deg}`);
                // this.props.loadAssets();
            }
        ).catch((err) => {
            log.error(err)
        });
    }

    //update display attribute of asset
    updateDisplayAttribute = (display, assetid, attributeid) => {
        if (!assetid || !attributeid) {
            log.warn("assetid or attributeid was not provided");
            return;
        }
        let param =  {
            assetid: assetid,
            attmetaid: attributeid,
            value: display
        };
        this.props.postAssetAttribute(param).then(
            // res => {loadAssets();}
            res => {
                log.info(`UPDATE DISPLAY ATTR ASSET ${assetid}`);
                this.props.loadAssets();
            }
        ).catch((err) => {
            log.error(err)
        });
    }

    toggleFilterModal(){
        this.setState({
            ...this.state,
            filterModalVisible: !this.state.filterModalVisible
        })
    }

    handleClearAsset(){

        this.props.showLoadAssetList && this.props.showLoadAssetList.forEach(assetobj => {

            let asset = assetobj.asset;
            if (!asset){
                return null;
            }
            let posattr =  assetobj.assetclass ? assetobj.assetclass.attributeMetas.find(att=> att.name == 'display') : null;
            let attributeid = posattr ? posattr.attributemetaid : null;


            this.updateDisplayAttribute('false', asset.assetid, attributeid);
        });
        this.props.clearAssetsSurveillance();
        this.props.loadAssetsSurveillance();
    }

    handleDegreeChange = (e) => {
        this.setState({
            degree: e.target.value
        }, ()=>{

        });
    }

    mouseoverIcon = (e) => {
        let cur = e._groups[0][0];
        //console.log('node e',cur);
        var ss = d3.selectAll("#use1");
        //console.log('node ss',ss);
        ss.filter((item) =>{
            //console.log('node ss this', this)
            return item===cur;
        });
        // ss._groups[0].forEach(item=>{
        //     console.log('node item',item)
        //     if(item===cur){
        //         console.log('node true')
        //     }else{
        //         console.log('node false')
        //     }
        // })
    }

    handleLockChange = (e)=> {
        //console.log('Screenlock is now', !this.state.mapLocked)
        this.setState({
            ...this.state,
            mapLocked: !this.state.mapLocked
        })
    }

    handleDateRangeChange = ({startDate, endDate}) =>{
        //console.log("SMART BUILDING START DATE", moment(startDate).valueOf())
        this.setState({
            startDate: startDate,
            endDate: endDate
        }, ()=>{
            this.loadAssetHistoryData();
        })
    }

    loadAssetHistoryData = () => {
        if(this.opgClassId && this.state.startDate && this.state.endDate){
          let params = {
            entitygroup: this.opgClassId,
            entity: "",
            starttime: moment(this.state.startDate).valueOf(),
            endtime: moment(this.state.endDate).valueOf(),
            latest: "0",
            themeid: ""
          }

        //   console.log(this.props.getAssetHistoryData(params));
        }
      }



    handleClearAsset(){
        this.props.clearAssetsSurveillance();
        this.props.loadAssetsSurveillance()
    }

    liveURL() {
        window.location.href = "http://192.168.2.90/#login";
        //console.log("TESTING THIS")
    }

    handleCameraGroupSelect = (selectedOptions) => {
        // console.log('Selected Camera Group', selectedOptions)
        let selectedCameraNames = selectedOptions?.value?.devices.map(device=>device.name)
        this.setState({
            cameraNameFilteredByGroup: selectedCameraNames
        }, this.setFilteredCamsNew)
    }

    renderCameraList(tooltipid){
        return this.state.filteredCamList && this.state.filteredCamList.map((camera, index) => {
            let chid = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('camera number')) : null
            let ch = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('camera channel')) : null
            let position = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname == 'position') : null
            let status = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('status')) : null
            let ip = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('IP')) : null
            let cName = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('name')) : null
            let degree = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('position_x')) : null
            let camFunction = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('function')) : null
            let camArea = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('area')) : null
            let camView = camera.assetattributes ? camera.assetattributes.find(item=>item.attmetaname.includes('view')) : null
            let degreeattrid = degree ? degree.attmetaid : null;
            let degree_value = degree ? degree.value : 0;
            let ipVal = ip ? ip.value : null
            //
            let posattrid = position? position.attmetaid : null;
            let pos = null
            try {
                pos = position ? JSON.parse(position.value) : {}
            }
            catch(ex){
                pos = {}
            }
            let posX = pos.x ?  pos.x * (window.innerWidth / 2400) : 146 * (window.innerWidth / 2400)
            let posY = pos.y ? pos.y * (window.innerHeight /1211) : 30 * (window.innerHeight /1211)


            let cameraGroupTags = []
            //loop through camera groups and find the group that the camera belongs to
            if(this.state.cameraGroups && this.state.cameraGroups.length > 0 && cName){
                this.state.cameraGroups.forEach(group=>{
                    if(group.devices && group.devices.length > 0){
                        let found = group.devices.find(d=>d.name == cName.value)
                        if(found){
                            cameraGroupTags.push(group.name)
                        }
                    }
                })
            }

            if (chid && chid.value && status) {
                //console.log('Camera info',camera, chid.value, " ",)
                //console.log('===Camera info',camera, chid.value, " ",)
                return (
                    <DragCameraModalIcon
                        key={index}
                        assetid={camera.assetid}
                        assetname={camera.assetname}
                        camFunction = {camFunction? camFunction.value : null}
                        camArea = {camArea? camArea.value : null}
                        camView = {camView? camView.value : null}
                        status = {status}
                        port={8098}
                        chid = {chid.value}
                        ch = {ch? ch.value : null}
                        ip= {ipVal}
                        x={posX}
                        y={posY}
                        degree={degree_value}
                        mouseoverIcon={this.mouseoverIcon}
                        hanldeDegreeChange={this.handleDegreeChange}
                        updatePosition={(x, y)=>{this.updateAssetAttribute(Math.floor(x * (2400/window.innerWidth)), Math.floor(y * (1211/window.innerHeight)) , camera.assetid, posattrid, pos?.x, pos?.y)}}
                        updateDegree={(deg)=>{this.updateDegreeAttribute(deg, camera.assetid, degreeattrid)}}
                        degreeattrid={degreeattrid}
                        tooltipref={tooltipid}
                        updateData = {this.state.pubSubData}
                        locked = {this.state.mapLocked}
                        tempLocation = {this.state.displayName}
                        nvrChannelList = {this.props.nvrChannelList}
                        nvrIP = {this.props.nvrIP}
                        httpPort = {this.props.httpPort}
                        camera={camera}
                        name={cName ?cName.value : null}
                        filterOn = {this.state.filterOn}
                        nvr={this.props.mainNVR}
                        cameraGroupTags={cameraGroupTags}
                    />
                )
            }else{
                return null
            }
        }
        )
    }

    renderSensorList(tooltipid){
        return this.props.layoutSensorList && this.props.layoutSensorList.map((asset, index) => {
            //console.log('================SHOW SENSOR=======================')
            //console.table(asset);
            let assetid = asset.assetid
            let assetClass = (this.props.assetClassList && asset) ? this.props.assetClassList.find(assetClass => assetClass.id == asset.assetclassid): null
            //if(assetClass) console.log("ICON SVG========", assetClass.iconsvg);

            let position = asset.assetattributes.find(posatr=>posatr.attmetaname=="position")
            let attributeid = position ? position.attmetaid : null;
            if(position){
               // console.log('=============================POSATR===================================', position)
            }
            let device = asset.devices && asset.devices.length > 0 ? asset.devices[0] : null
            let status = null;
            if(device){
                if(device.lastmessagetime){
                    let lastmessagetime = device.lastmessagetime? new Date(device.lastmessagetime).getTime(): null;
                    status = (new Date().getTime() - lastmessagetime) / (60 * 60 * 1000) < 24 ? "online": "offline";
                }
            }
            let pos = null
            try {
                pos = position ? JSON.parse(position.value) : {}
            }
            catch(ex){
                pos = {}
            }
            return(<DragAssetModalIcon
                key={index}
                assetid={asset.assetid}
                assetname={asset.assetname}
                status = {status}
                assetIcon = {assetClass ? assetClass.iconsvg : null}
                assetIconId = {assetClass ? assetClass.iconid : null}
                device = {device}
                x={pos?  Math.floor(pos.x * (window.innerWidth / 2400)) : 146 * (window.innerWidth / 2400)}
                y={pos?  Math.floor(pos.x * (window.innerHeight / 1211)) : 30 * (window.innerHeight / 1211)}
                // updatePosition={(x, y)=>{console.log('Updated position asset')}}
                updatePosition={(x, y)=>{this.updateAssetAttribute(Math.floor(x * (2400/ window.innerWidth)), Math.floor(y * (1211 /window.innerHeight)) , asset.assetid, attributeid, pos?.x, pos?.y)}}
                tooltipref={tooltipid}
                updateData = {this.state.pubSubData}
                locked = {this.state.mapLocked}
            />)
        })
    }

    render (){

        // console.log("========LAYOUT CAMERA LIST ============", this.props.layoutCameraList)
        var tooltipid = this.refs.tooltipref;
        let showlist = this.props.layoutSensorList;
        //console.log('List of Sensor', JSON.stringify(showlist));
        //console.log('INNER DIMENSIONS,', window.innerWidth, window.innerHeight)
        let nlist = ""
        for (const n in this.props.nvrList) {
            nlist += this.props.nvrList[n].center+", "
        }
        return (
            // <Collapse isOpen={this.state.cameraToEdit == null}>
            <Card>
                <CardBody>
                    <CameraGroupFilter 
                        cameraGroups={this.state.cameraGroups}
                        handleCameraGroupSelect={this.handleCameraGroupSelect}
                        camName={this.state.camName}
                        setCamName={this.setCamName}
                    />
                    <Zoomable
                        controls={false}
                        disableZoom={true}
                        disablePan={false}
                        >
                        <svg style={{
                                        backgroundImage: `url(${this.props.layoutImg})`,
                                        backgroundRepeat: 'no-repeat',
                                        width:`${window.innerWidth * 0.625}px`,height:`${window.innerHeight *0.83}px`,
                                        color:'white',
                                        backgroundSize: `${window.innerWidth * 0.625}px ${window.innerHeight * 0.83}px`
                                    }}>
                            {this.renderCameraList(tooltipid)}
                            {this.renderSensorList(tooltipid)}

                            {/* OPG demo */}
                            {this.state.trackedLocations && this.state.trackedLocations.map((beaconHistory, index) => {
                                return(
                                <TortoiseHistoryLocation
                                    deviceId = {beaconHistory.deviceId}
                                    beaconId = {beaconHistory.beacon}
                                    position = {beaconHistory.position}
                                    timestamp = {beaconHistory.timestamp}
                                />
                                // <p className = "test">{`${beaconHistory.deviceId}`}</p>
                                )
                            })}


                        </svg>
                    </Zoomable>
                    {/* <div id={tooltipid} ref='tooltipref' style={{position: 'relative', visibility: 'visible', fontWeight: 'bold'}} ></div> */}
                    {/*<a href={"http://192.168.2.90/#live"} onClick={this.liveCamera()}>Camera link</a>*/}
                    {this.props.layoutSensorList && this.props.layoutSensorList.some(item=>item.assetclassname.includes('beacon') || item.assetclassname.includes('OPG Sensor')) && <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        showClearDates = {true}
                        small = {true}
                        block = {true}
                        openDirection = "up"
                        // disabled = {!this.state.mapData || this.state.mapData.length == 0}
                    />
                    }
                </CardBody>
            </Card>
        // </Collapse>
        )
    }
};

function mapDispatchToProps (dispatch){
    return bindActionCreators ({
        getAssetHistoryData,
        loadAssets,
        loadCameraRestrictionsOnroute
    }, dispatch);
}

const mapStateToProps = (state, ownProps) => {
    return {
        layoutCameraList: state.asset.assetList.filter(asset=> asset.assetclassname.includes('Camera_Base') && asset.parentasset == ownProps.selectedLayoutId),
        layoutSensorList: state.asset.assetList.filter(asset=> (asset.assetclassname.includes('Airbox_Sensor') || asset.assetclassname.includes('beacon') || asset.assetclassname.includes('OPG Sensor')) && asset.parentasset == ownProps.selectedLayoutId),
        assetClassList: state.asset.assetClassList,
        assetList: state.asset.assetList,
        deviceHistoryData: state.weather.assetHistoryData,
        nvrCamGroupsList: state.surveillance.nvrCameraGroup,
        // opgAssets: state.asset.assetList.filter(asset=>OPG_ASSETCLASS_NAME_ARRAY.includes(asset.assetclassname))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CameraMapDisplay));