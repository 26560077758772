/* eslint-disable */

import {
    ASSET_LOAD_DEVICE_START,
    ASSET_LOAD_DEVICE_SUCCEED,
    ASSET_LOAD_DEVICE_FAILED,
    ASSET_LOAD_CAMERA_LIST_SUCCEED,
    ASSET_LOAD_CAMERA_LIST_FAILED,
    ASSET_LOAD_SENSOR_LIST_SUCCEED,
    ASSET_LOAD_SENSOR_LIST_FAILED,
    ASSET_LOAD_ASSET_SUCCEED,
    ASSET_LOAD_ASSET_FAILED,
    ASSET_LOAD_CLASS_SUCCEED,
    ASSET_LOAD_CLASS_FAILED,
    ASSET_ADD_ASSET,
    ASSET_UPDATE_ASSET,
    ASSET_UPDATE_ONE_ASSET,
    ASSET_REMOVE_ASSET,
    ASSET_ADD_ASSET_CLASS,
    ASSET_UPDATE_ASSET_CLASS,
    ASSET_UPDATE_ONE_ASSET_CLASS,
    ASSET_REMOVE_ASSET_CLASS,
    ASSET_ADD_ATTR_ASSET_CLASS,
    ASSET_UPDATE_ATTR_ASSET_CLASS,
    ASSET_REMOVE_ATTR_ASSET_CLASS,
    ASSET_ADD_ATTR_ASSET,
    ASSET_UPDATE_ATTR_ASSET,
    ASSET_DELETE_ATTR_ASSET,
    ASSET_LOAD_ICON_SUCCEED,
    ASSET_LOAD_ICON_FAILED,
    GET_CALCULATE_ATTRIBUTE_SUCCESS,
    GET_CALCULATE_ATTRIBUTE_FAILED,
    GET_CALCULATE_RESULT_SUCCESS,
    GET_CALCULATE_RESULT_FAILED,
    GET_LATEST_CALCULATE_RESULT_SUCCESS,
    GET_LATEST_CALCULATE_RESULT_FAILED,
    ASSET_DELETE_ICON,
} from '../../constants/actionTypes'
import { getCurrentUser } from '../../factories/auth'

const initState = {
    currentDeviceTypeId: '',
    currentDeviceId: '',
    currentDeviceTypeList: [],
    currentDeviceList: [],
    currentDeviceDetailsList: [],
    calculatedAttributes: [],
    assetList: [],
    assetClassList: [],
    cameraList:[],
    sensorList:[],
    cameraList2:[],
    sensorList2:[],
    currentDeviceListLoading: false,
    iconList: [],
    calculatedResult: [],
    latestCalculateResult: {},
    currentAssetSelected: '',
    currentAssetClassSelected: '',

    error: 0,
    message: '',
    type: ''
}
export default function (state = initState, action) {

    switch (action.type) {
        case ASSET_LOAD_DEVICE_START:

            return {
                ...state,
                currentDeviceListLoading: true,
                error: 0,
                type: action.type,
            }
        case ASSET_LOAD_DEVICE_SUCCEED:
            return {
                ...state,
                currentDeviceListLoading: false,
                currentDeviceList: action.payload,
                error: 0,
                message: 'Device List Updated',
                type: action.type
            }
        case ASSET_LOAD_CAMERA_LIST_SUCCEED:
            return {
                ...state,
                cameraList2: action.payload,
                error: 0,
                message: 'loaded the camera list',
                type: action.type
            }
        case ASSET_LOAD_SENSOR_LIST_SUCCEED:
            return {
                ...state,
                sensorList2: action.payload,
                error: 0,
                message: 'loaded the sensor list',
                type: action.type
            }
        case ASSET_LOAD_ASSET_SUCCEED:
            let assetList = action.payload
            let cameraList = assetList.filter(asset=> asset.assetclassname.includes('Camera_Base'));
            return {
                ...state,
                assetList: action.payload,
                cameraList: cameraList,
                error: 0,
                message: 'Asset List Updated',
                type: action.type
            }
        case ASSET_LOAD_CLASS_SUCCEED:
            return {
                ...state,
                assetClassList: action.payload,
                error: 0,
                message: 'Asset Class List Updated',
                type: action.type
            }
        case ASSET_LOAD_ASSET_FAILED:
        case ASSET_LOAD_DEVICE_FAILED:
        case ASSET_LOAD_CLASS_FAILED:
            return {
                ...state,
                error: 1,
                message: action.payload,
                type: action.type
            }
        case ASSET_ADD_ASSET:
            return {
                ...state,
                assetList: assignNextIdToNewItemThenPushToList([...state.assetList], action.payload, 'assetid'),
                error: 0,
                message: 'Added asset to Asset List',
                type: action.type
            }
        case ASSET_ADD_ASSET_CLASS:
            return {
                ...state,
                assetClassList: assignNextIdToNewItemThenPushToList([...state.assetClassList], action.payload, 'id'),
                error: 0,
                message: 'Added Class to Asset Class List',
                type: action.type
            }
        case ASSET_UPDATE_ONE_ASSET:
            return {
                ...state,
                assetList: updateElemHavingId([...state.assetList], action.payload.assetid, action.payload.data, 'assetid'),
                error: 0,
                message: 'Updated One In Asset List',
                type: action.type
            }
        case ASSET_UPDATE_ONE_ASSET_CLASS:
            return {
                ...state,
                assetClassList: updateElemHavingId([...state.assetClassList], action.payload.id, action.payload.data, 'id'),
                error: 0,
                message: 'Updated One In Asset Class List',
                type: action.type
            }
        //////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////
        case ASSET_ADD_ATTR_ASSET_CLASS:
            return {
                ...state,
                assetClassList: addOrUpdateAttributeMeta([...state.assetClassList], action.payload.id, action.payload.data),
                error: 0,
                message: 'Added New Attribute in to an Asset Class',
                type: action.type
            }
        case ASSET_UPDATE_ATTR_ASSET_CLASS:
            return {
                ...state,
                assetClassList: addOrUpdateAttributeMeta([...state.assetClassList], action.payload.id, action.payload.data),
                error: 0,
                message: 'Updated Custom Attribute in an Asset Class',
                type: action.type
            }
        case ASSET_REMOVE_ATTR_ASSET_CLASS:
            return {
                ...state,
                assetClassList: removeAttributeHavingId([...state.assetClassList], action.payload.id, action.payload.attributemetaid)
                , error: 0,
                message: 'Deleted Custom Attribute in an Asset Class',
                type: action.type
            }
        case ASSET_ADD_ATTR_ASSET:
            return {
                ...state,
                assetList: addOrUpdateAttribute([...state.assetList], action.payload.id, action.payload.data),
                error: 0,
                message: 'Updated Custom Attribute in an Asset Class',
                type: action.type
            }
        case ASSET_UPDATE_ATTR_ASSET:
            return {
                ...state,
                assetList: addOrUpdateAttribute([...state.assetList], action.payload.id, action.payload.data),
                error: 0,
                message: 'Updated Custom Attribute in an Asset Class',
                type: action.type
            }
        case ASSET_DELETE_ATTR_ASSET:
            return {
                ...state,
                assetList: deleteAttribute([...state.assetList], action.payload.id, action.payload.attrid),
                error: 0,
                message: 'Updated Custom Attribute in an Asset Class',
                type: action.type
            }
        //////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////
        case ASSET_UPDATE_ASSET:
            return {
                ...state,
                assetList: action.payload,
                error: 0,
                message: 'Updated Asset List',
                type: action.type
            }
        case ASSET_UPDATE_ASSET_CLASS:
            return {
                ...state,
                assetClassList: action.payload,
                error: 0,
                message: 'Updated Asset Class List',
                type: action.type
            }
        case ASSET_REMOVE_ASSET:
            return {
                ...state,
                assetList: removeElemHavingId([...state.assetList], action.payload, 'assetid'),
                error: 0,
                message: 'Removed an asset from assetList',
                type: action.type
            }
        case ASSET_REMOVE_ASSET_CLASS:
            return {
                ...state,
                assetClassList: removeElemHavingId([...state.assetClassList], action.payload, 'id'),
                error: 0,
                message: 'Removed a Class from assetClassList',
                type: action.type
            }
        case ASSET_LOAD_ICON_SUCCEED:
            return {
                ...state,
                iconList: action.payload,
                error: 0,
                message: 'Icon List Loaded',
                type: action.type
            }
        case ASSET_LOAD_ICON_FAILED:
            return {
                ...state,
                error: 1,
                message: 'Icon List Failed',
                type: action.type
            }
        case GET_CALCULATE_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                calculatedAttributes: action.payload
            }
        case GET_CALCULATE_ATTRIBUTE_FAILED:
            return {
                ...state,
                calculatedAttributes: []
            }
        case GET_CALCULATE_RESULT_SUCCESS:
            return {
                ...state,
                calculatedResult: action.payload
            }
        case GET_CALCULATE_RESULT_FAILED:
            return {
                ...state,
                calculatedResult: []
            }
        case GET_LATEST_CALCULATE_RESULT_SUCCESS:
            return {
                ...state,
                latestCalculateResult: action.payload
            }
        case GET_LATEST_CALCULATE_RESULT_FAILED:
            return {
                ...state,
                latestCalculateResult: {}
            }
        case ASSET_DELETE_ICON:
            let newIconList = state.iconList.map(icon=>icon.iconid !== action.payload)
            return {
                ...state,
                iconList: newIconList,
                error: 0,
                message: 'Deleted Icon',
                type: action.type
            }

        default:
            return { ...state };
    }
}

// The following functions make a shallow copy of the state, only changing refs of the differences
//  between prev and new on each layer.
//
//  Updating works similarily when updating innerAttribute val, shallow copies are made and only the refs of diff changed
function addOrUpdateAttribute(list, id, attr) {
    return updateInnerItemData(
        list, id, 'assetid',
        attr, 'attributes', 'id')
}
function deleteAttribute(list, id, attrId) {
    return removeInnerItemWithId(
        list, id, 'assetid',
        attrId, 'attributes', 'id')
}
function addOrUpdateAttributeMeta(list, id, attr) {
    return updateInnerItemData(
        list, id, 'id',
        attr, 'attributeMetas', 'attributemetaid')
}
function removeAttributeHavingId(list, id, attrId) {
    return removeInnerItemWithId(
        list, id, 'id',
        attrId, 'attributeMetas', 'attributemetaid')
}

// The first 3 params are for finding the item in list (top layer) with id 'id'
//  Next 3 are for finding and updating/removing the inner item with key 'innerItemField', and with id key 'innerItemIdField'
//  'data' is of form { id: ..., data: { ... } }
function updateInnerItemData(list, id, idField, data, innerItemField, innerItemIdField) {
    let index = list.findIndex(l => l[idField] == id)
    let innerItem = { [innerItemField]: updateElemHavingId([...list[index][innerItemField]], data[innerItemIdField], data.data, innerItemIdField) }
    return updateElemHavingId(list, id, innerItem, idField)
}
function removeInnerItemWithId(list, id, idField, innerId, innerItemField, innerItemIdField) {
    let index = list.findIndex(l => l[idField] == id)
    let innerItem = { [innerItemField]: removeElemHavingId([...list[index][innerItemField]], innerId, innerItemIdField) }
    return updateElemHavingId(list, id, innerItem, idField)
}

// Unused, but can be used for a far less efficient but simpler 'lazy cloning'
const deepClone = (items) => items.map(item => Array.isArray(item) ? deepClone(item) : item);

// Applies an action on a list 'list'
//  1. By adding an item 'item'
//  2. By modifying an item with id 'id' with 'item' data
//  3. By deleting the item with id 'id'
//
// 'idField' refers to the id key
function assignNextIdToNewItemThenPushToList(list, item, idField) {
    let nextIdFromGreatest = list.reduceRight((max, a) => Math.max(max, a[idField]), 0) + 1
    item = { [idField]: nextIdFromGreatest, ...item }
    list.push(item)
    return list
}
function updateElemHavingId(list, id, item, idField) {
    let index = list.findIndex(l => l[idField] == id)
    if (index == -1)
        return assignNextIdToNewItemThenPushToList(list, item, idField)
    list[index] = { ...list[index], ...item }
    return list
}
function removeElemHavingId(list, id, idField) {
    let index = list.findIndex(l => l[idField] == id)
    list.splice(index, 1)
    return list
}