/* eslint-disable */
import React from 'react';
import axios from 'axios';
import {VIDEO_ROOT} from './appConstants';

export const UserContext = React.createContext();
export const AxiosNVR = axios.create({
    baseURL: VIDEO_ROOT,
    timeout: 1000,
    // headers: {
    //     // 'Authorization': 'Basic YWRtaW46MTIzNDU2',
    //     // 'Content-Type': 'application/xml; charset="UTF-8"',
    //     // 'Access-Control-Allow-Origin': 'http://192.168.2.55',
    //     // 'Access-Control-Allow-Origin': '*',
    //     // 'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    //     // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    //     // 'Access-Control-Allow-Methods': 'POST, GET',
    //     // 'Access-Control-Allow-Headers': 'X-Auth, Content-Type, Accept',
    //     // 'Access-Control-Allow-Credentials': 'true'
    //     // 'Access-Control-Max-Age': '86400'
    // }
});