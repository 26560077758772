/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { loadCarCountRecords, loadCarCountRecordsByBuildingAndDate } from '../../../../redux/actions/surveilenceActions'
import { postAssetAttribute, loadAssets } from '../../../../redux/actions/assetActions';
import {
    Row, Col, Label, Button, TabContent, TabPane,
    Nav, NavItem, NavLink, Card, CardBody, Modal, ModalHeader,
    ModalBody, ModalFooter, Form, FormGroup, Collapse, Drop,
    DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import CarCountInfo from './CarCountInfo';
import CarCountGraph from './CarCountGraph';
import IndividualCamChart from './IndividualCamChart';
import './SmartBuilding.css'
import { EditIcon } from 'mdi-react';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { LabelTextInput } from '../cameraList/CameraCreateModal';
import { Client } from '@stomp/stompjs';
import { WEB_SUB_SOCKET } from '../../../../constants/appConstants';
import { getJwttoken } from '../../../../factories/utils';
import { sendSMS } from '../../../../factories/utils';



export const renderSpinner = () => {
    return <Spinner
        className="spinner"
        as="span"
        animation="border"
        variant="success"
        // size="sm"
        // role="status"
        // aria-hidden="true"
        style={{ display: 'inline-block', margin: 'auto' }}
    />;
}
const CarCountBoard = ({ location, carCountRecords, loadCarCount, postAssetAttribute, loadAssets, nvrIP, nvrChannelList, httpPort, ccNVR }) => {
    const [LastUpdateTime, setLastUpdateTime] = useState(null)
    const [FilteredDate, setFilteredDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
    const [CurrentCamLastHourData, setCurrentCamLastHourData] = useState(null)
    const [CurrentLocationCamData, setCurrentLocationCamData] = useState(null)
    const [SelectedCamName, setSelectedCamName] = useState(null)
    const [CameraNameFilter, setCameraNameFilter] = useState([null])
    const [hourlyData, sethourlyData] = useState(null);
    const [LimitModal, setLimitModal] = useState(false)
    const [CarLimit, _setCarLimit] = useState(null)
    const [CarLimitInput, setCarLimitInput] = useState(null)
    const [alertReceivers, setalertReceivers] = useState([])
    const [stompClient, setstompClient] = useState(null)
    const [locationName, setlocationName] = useState('')
    const [stayCount, _setstayCount] = useState(null)
    const [alreadySend, setalreadySend] = useState(false)
    // const [displayNVR, setdisplayNVR] = useState(false)
    // const [CamNum, setCamNum] = useState(null)
    const CarLimitRef = useRef(CarLimit);
    const stayCountRef = useRef(stayCount);
    const setCarLimit = (data) => {
        CarLimitRef.current = data;
        _setCarLimit(data);
    }
    const setstayCount = (data) => {
        stayCountRef.current = data;
        _setstayCount(data);
    }



    useEffect(() => {
        // console.log('LOAD Car COUNT RECORDS.....')
        if (location) {
            // console.log("Car Count location,",location.assetname)
            let capacityLimit = location.assetattributes.find(el => el.attmetaname == 'capacity')
            if (capacityLimit) {
                // console.log('CAR CAPACITY LIMIT: ',capacityLimit)
                setCarLimit(parseInt(capacityLimit.value))
                setCarLimitInput(parseInt(capacityLimit.value))
            }
            let lName
            if (location.assetname.includes("F1") || location.assetname.includes("F2")) {
                if (location.assetname.includes("F1")) {
                    lName = location.assetname.replace("F1", "").trim();
                } else {
                    lName = location.assetname.replace("F2", "").trim();
                }
                setlocationName(lName)
            } else {
                setlocationName(location.assetname)
            }
        }
    }, [location])

    useEffect(() => {
        // console.log('LOAD CAR COUNT RECORDS.....', locationName, FilteredDate.toString())
        if(locationName && locationName.length > 0){
            loadCarCount(locationName, FilteredDate.toString())
        }
        setSelectedCamName(null);
    }, [locationName, FilteredDate])

    // useEffect(() => {
    //     loadCarCount();
    //     connectSocket();
    //     const displayNVRPlayerTimeout = setTimeout(() => {
    //         setdisplayNVR(true)
    //     }, 4000)
    //     return () => clearTimeout(displayNVRPlayerTimeout)
    // }, [])

    useEffect(() => {
        // console.log('Car LIMIT: ', CarLimit)
        // console.log('STAY COUNT: ', stayCount)
        if ((CarLimit !== null) && (stayCount !== null) && (stayCount >= CarLimit)) {
            // console.log('SHOULD SEND SMS', CarLimit, stayCount)
            // sendSMS(['6475511693'], `Warning: A capacity limit of ${CarLimit} has been reached at the ${plazaLocation}`)
            // sendSMS(["416-371-6616", "416-402-9449", "416-402-4558", "647293-4022"], `“You have reached the store capacity of 3 – action is required`)
            // sendSMS(alertReceivers, `You have reached the store capacity of ${CarLimit}– action is required`)
            // setCarLimit(null)
        }
        setalreadySend(true);
    }, [CarLimit, stayCount])

    useEffect(() => {
        if (stompClient) {
            // console.log('Car Count SOCKET CONNECTED', stompClient);
            stompClient.onConnect = connect_callback;
            stompClient.onDisconnect = disconnect_callback;
            stompClient.activate();
        }
    }, [stompClient])

    useEffect(() => {
        // console.log('Car COUNT RECORDS ===', carCountRecords)
        // if(location) console.log(location.assetname);
        if (carCountRecords && carCountRecords.length > 0) {
            let dataByDate = carCountRecords.find(i => i.date === FilteredDate.toString());
            let localDataByDate = dataByDate?.data?.length > 0 ? dataByDate.data.find(i => i.building.includes(locationName)) : null;
            //Get last row of any cam's data to get last updated time
            let cameraNames = []
            if (localDataByDate && localDataByDate.data.length > 0) {
                //loop through all cams to get last row
                let localData = [...localDataByDate.data]
                localData.map(camData => {
                    cameraNames.push(camData.camName)
                    camData.data.map(d => {
                        d.time = moment(d.timestamp).local().format('MM-DD-YYYY HH:00')
                        d.timeLabel = moment(d.timestamp).local().format('HH:00')
                    })
                })
                setCameraNameFilter(cameraNames)
                if(!SelectedCamName){
                    setSelectedCamName(cameraNames[0])
                }
                let selectCamData = SelectedCamName ? localData.find(i => i.camName == SelectedCamName) : localData[0]
                setCurrentLocationCamData(selectCamData);
                // console.log('SELECTED CAM DATA', selectCamData, SelectedCamName)
                if(selectCamData?.data?.length > 0){
                    //set LastUpdateTime state
                    let lastRow = selectCamData.data[selectCamData.data.length - 1]
                    setCurrentCamLastHourData(lastRow)
                    setLastUpdateTime(moment(lastRow.timestamp).local().format('YYYY-MM-DD HH:mm'))
                }
            }else{
                setCurrentLocationCamData(null)
                setCurrentCamLastHourData(null)
                setLastUpdateTime(null)
            }
        }
    }, [carCountRecords, locationName, SelectedCamName]);


    const connectSocket = () => {
        if (stompClient !== null && stompClient !== undefined) {
            stompClient.deactivate();
            //stompClient = null;
        }

        let query = '?q=' + getJwttoken();
        ///////////////////////////////////////////////////
        setstompClient(new Client({
            // brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query,
            brokerURL: `${WEB_SUB_SOCKET}` + query,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        }));


        //////////////////////////////////////////////////
        //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
        //stompClient = Stomp.over(socket_URL); 
        //stompClient.reconnectDelay=5000; 
        //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;

    }

    const connect_callback = () => {
        //console.log('connected to websoket');
        // called back after the stompClient is connected and authenticated to the STOMP server
        // stompClient.subscribe('/topic/alarmLinePassing', callbackDeviceMessage);
        stompClient.subscribe('/topic/CarCountMessage', callbackDeviceMessage);
    }
    const disconnect_callback = () => {
        stompClient.deactivate();
        // called back after the stompClient is connected and authenticated to the STOMP server
        //console.log('client disconnected')

    }
    const callbackDeviceMessage = (message) => {
        // console.log('CARC DETECTED--------------------------', message)
        // //console.log(message.body);
        // console.log('SHOULD SEND SMS', CarLimitRef.current, stayCountRef.current)
        if ((CarLimitRef.current !== null) && (stayCountRef.current !== null) && (stayCountRef.current == 2)) {
            // console.log('SHOULD SEND SMS')
            // sendSMS(['6475511693'], `Warning: A capacity limit of ${CarLimit} has been reached at the ${plazaLocation}`)
            // sendSMS(["416-371-6616", "416-402-9449", "416-402-4558", "647293-4022"], `You have reached the store capacity of 3 – action is required`)
            sendSMS(alertReceivers, `You are currently at building capacity – action required`)
            // setCarLimit(null)
        }
        loadCarCount(locationName, FilteredDate.toString());
    }

    const handleDateFilter = (date) =>{
        setFilteredDate(date)
    }


    const toggleCountLimit = () => {
        setLimitModal(!LimitModal)
    }

    // const handleCamNumChange = (num)=>{
    //     setCamNum(num)
    // }


    const handleAddAlertReceiver = (phone) => {
        // console.log("=======ITEM ADDED", phone);
        let tempArr = alertReceivers;
        tempArr.push(phone);
        setalertReceivers(tempArr)
    }

    const handleDeleteReceiver = (phone) => {
        // console.log("ITEM DELETED",phone);
        let tempAlertReceivers = alertReceivers;
        if (tempAlertReceivers) {
            const index = tempAlertReceivers.indexOf(phone);
            if (index > -1) {
                tempAlertReceivers.splice(index, 1)
            }
            setalertReceivers(tempAlertReceivers)
        }
    }

    const handleCarLimitChange = e => {
        setCarLimitInput(e.target.value);
    }

    const handleStayCountChange = value => {
        setstayCount(value);
    }

    const handleLimitSubmit = () => {
        setCarLimit(CarLimitInput);
        if (location) {
            let attribute = location.assetattributes.find(el => el.attmetaname == 'capacity')
            if (attribute) {
                let param = {
                    assetid: attribute.assetid,
                    attmetaid: attribute.attmetaid,
                    value: CarLimitInput
                }

                // console.log('PARAM', param)

                updateCapacity(param)

            }
        }
        setLimitModal(false);
    }

    const handleNameFilterChange = camName => {
        // console.log('Name filter clicked', location)
        setSelectedCamName(camName)
    }


    const updateCapacity = (data) => {
        //console.log('UPDATE CAPACITY')

        postAssetAttribute(data).then(
            res => { loadAssets() }
        );
    }

    // if (CurrentLocationData && CurrentLocationData.length && CurrentLocationCamData){
    return (
        <>
            <Card>
                <CardBody>

                    {CurrentLocationCamData &&
                        <>
                            <Row>
                                <Col md={6}>
                                    <span style={{ marginTop: "50px" }}>
                                        <span style={{ fontWeight: "bold", fontSize: "1.5em", display: "inline" }}>
                                            Last Updated At:
                                        </span>
                                        <p style={{ fontSize: "1.2rem", display: "inline" }}>{CurrentLocationCamData ? LastUpdateTime ? ' ' + LastUpdateTime : 'N/A' : 'N/A'}</p>
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <span style={{ marginTop: "60px" }}>
                                        <span style={{ fontWeight: "bold", fontSize: "1.2em", display: "inline" }}>
                                            Camera:
                                        </span>

                                        <UncontrolledDropdown group style={{ marginTop: "10px" }}>
                                            <DropdownToggle
                                                caret
                                                color="none"
                                            >{SelectedCamName}</DropdownToggle>
                                            <DropdownMenu>
                                                {CameraNameFilter.map((locationName, index) => {
                                                    return (<DropdownItem key={index} onClick={() => handleNameFilterChange(locationName)}>{locationName}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </span>
                                </Col>
                            </Row>
                        </>
                    }
                    {CurrentCamLastHourData && <Row className="box-container-ai-stat">
                        <CarCountInfo
                            camCurrentData={CurrentCamLastHourData}
                            // handleStayCountChange={handleStayCountChange}
                        // handleCamNumChange={handleCamNumChange}
                        // camNum={CamNum}
                        />
                    </Row>}
                    {!CurrentCamLastHourData && <Row>
                            <span className="noData"> There is no data for the specified date at this location</span>
                        </Row>
                    }
                    <Row className="box-container-graph">
                        {/* <Col md={4}>
                            <span className="capacity">
                                    Capacity Limit: 
                                    <span className="limit" style={{margin: '5px', padding: '10px'}}>{CarLimit}</span>
                                    <a type="button" className="limit-edit" onClick={toggleCountLimit}><EditIcon/></a>
                            </span>
                        </Col> */}
                        <CarCountGraph
                            camData={CurrentLocationCamData}
                            limit={CarLimit}
                            loadCarCount={loadCarCount}
                            locationName={locationName}
                            handleDateFilter={handleDateFilter}
                            selectedDateTime={FilteredDate}
                        // camNum={CamNum}
                        />
                    </Row>
                    {CurrentCamLastHourData &&
                        <IndividualCamChart
                            camData={CurrentCamLastHourData}
                            nvrIP={nvrIP}
                            nvrChannelList={nvrChannelList}
                            httpPort={httpPort}
                            ccNVR={ccNVR}
                        />
                    }
                </CardBody>
            </Card>

            <Modal toggle={toggleCountLimit} isOpen={LimitModal}>
                <ModalHeader toggle={toggleCountLimit} tag="h3">Set Building Capacity Limit</ModalHeader>
                <ModalBody>
                    <LabelTextInput tag="h5" forIdName="carLimit" label="Number of Car Allowed" value={CarLimitInput} type="text" onChange={handleCarLimitChange} />
                    <FormGroup row>
                        <Col md={3}>
                            <Label tag="h5" htmlFor={"alertReceiver"}>Alert Receiver(s)</Label>
                        </Col>
                        <Col md={9}>
                            <MultipleValueTextInput
                                name="receivers-input"
                                values={alertReceivers}
                                onItemAdded={(item, allItems) => handleAddAlertReceiver(item)}
                                onItemDeleted={(item, allItems) => handleDeleteReceiver(item)}
                            />
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleLimitSubmit}>Set Limit</Button>
                </ModalFooter>
            </Modal>
        </>
    )

}


const mapStateToProps = (state) => ({
    carCountRecords: state.surveillance.carCountRecords

})

const mapDispatchToProps = (dispatch) => {
    return {
        loadCarCount: (building, date) => dispatch(loadCarCountRecordsByBuildingAndDate(building, date)),
        postAssetAttribute: (data) => dispatch(postAssetAttribute(data)),
        loadAssets: () => dispatch(loadAssets()),
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CarCountBoard)

